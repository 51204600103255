<template>
  <div class="contain">
    <div class="info">
      <div class="x_title">
        我的收藏
        <div class="detail" :class="type == 1?'detailActive':''" @click="changeType(1)">产品</div>
        <div class="detail" :class="type == 2?'detailActive':''" @click="changeType(2)">解决方案</div>
        <div class="detail" :class="type == 3?'detailActive':''" @click="changeType(3)">新闻动态</div>
        <div class="detail" :class="type == 4?'detailActive':''" @click="changeType(4)">下载中心</div>
      </div>
      <div class="x_table">
        <!-- 产品 -->
        <div class="cp" v-show="type == 1">
          <div class="thead">
            <div class="one"><span>博威合金</span></div>
            <div class="two">
              <div class="top"><span>牌号</span></div>
              <div class="bottom">
                <div class="detail"><span>美标</span></div>
                <div class="detail"><span>欧标</span></div>
                <div class="detail"><span>日标</span></div>
                <div class="detail"><span>国标</span></div>
              </div>
            </div>
            <!-- <div class="three"><span>导电率</span></div>
            <div class="three"><span>抗拉强度</span></div> -->
            <div class="four"><span>物性表下载</span></div>
            <div class="five"><span>操作</span></div>
          </div>
          <div class="tbody">
            <div class="detail" v-for="(item,index) in list" :key="index">
              <div class="one">
                <div class="oneInfo">
                  <div class="left"><img src="../../assets/images/x_pro0.png" alt=""></div>
                  <div class="right">
                    <a href="##" class="top"><img src="../../assets/images/x_go.png" alt=""><span>{{item.title}}</span></a>
                    <div class="bottom">{{item.type}}</div>
                  </div>
                </div>
              </div>
              <div class="two">
                <div class="twoDetail"><span>{{item.americanStandard}}</span></div>
                <div class="twoDetail"><span>{{item.europeanStandard}}</span></div>
                <div class="twoDetail"><span>{{item.japaneseStandard}}</span></div>
                <div class="twoDetail"><span>{{item.nationalStandard}}</span></div>
              </div>
              <!-- <div class="three"><span>3%IACS</span></div>
              <div class="three"><span>1300MPa</span></div> -->
              <div class="four"><a :href="item.fileUrl"><img src="../../assets/images/x_icon10.png" alt="">点击下载</a></div>
              <div class="five" @click="delsc(item.id)"><span>取消收藏</span></div>
            </div>
          </div>
          <div class="x_bottom">
            <div class="left"></div>
            <div class="right">
              <el-pagination background layout="prev, pager, next , jumper" :total="total" @current-change="handleCurrentChange">
              </el-pagination>
            </div>
          </div>
        </div>
        <!-- 解决方案 -->
        <div class="jjfa" v-show="type == 2">
          <div class="x_thead">
            <div class="detail f3"><span>标题</span></div>
            <div class="detail f1"></div>
            <!-- <div class="detail f2">分类</div> -->
            <div class="detail f2"><span>关联产品</span></div>
            <div class="detail f6"><span>内容</span></div>
            <div class="detail f1"></div>
            <div class="detail f2"><span>操作</span></div>
            <div class="line"></div>
          </div>
          <div class="x_tbody" v-loading="loading">
            <div class="x_td" v-for="(item, index) in list" :key="index">
              <div class="detail f3"><span>{{item.title}}</span></div>
              <div class="detail f1"></div>
              <!-- <div class="detail f2"><span>{{item.classift}}</span></div> -->
              <div class="detail f2"><span></span></div>
              <div class="detail f6"><span></span></div>
              <div class="detail f1"></div>
              <div class="detail f2"><div class="deBtn"  @click="delsc(item.id)">取消收藏</div></div>
            </div>
          </div>
          <div class="x_bottom">
            <div class="left"></div>
            <div class="right">
              <el-pagination background layout="prev, pager, next , jumper" :total="total" @current-change="handleCurrentChange">
              </el-pagination>
            </div>
          </div>
        </div>
        <!-- 新闻动态 -->
        <div class="xwdt" v-show="type == 3">
          <div class="x_thead">
            <div class="detail f6">标题</div>
            <div class="detail f1"></div>
            <!-- <div class="detail f4">内容</div> -->
            <div class="detail f1"></div>
            <div class="detail f1">操作</div>
            <div class="line"></div>
          </div>
          <div class="x_tbody">
            <div class="x_td" v-for="(item, index) in list" :key="index">
              <div class="detail f6"><span>{{item.title}}</span></div>
              <div class="detail f1"></div>
              <!-- <div class="detail f4"><span></span></div> -->
              
              <div class="detail f1"></div>
              <div class="detail f1"><div class="deBtn"  @click="delsc(item.id)">取消收藏</div></div>
            </div>
          </div>
          <div class="x_bottom">
            <div class="left"></div>
            <div class="right">
              <el-pagination background layout="prev, pager, next , jumper" :total="total" @current-change="handleCurrentChange">
              </el-pagination>
            </div>
          </div>
        </div>
        <!-- 下载中心 -->
        <div class="xxzx" v-show="type == 4">
          <div class="x_thead">
            <div class="detail f2"><span>文件名</span></div>
            <div class="detail f1"></div>
            <div class="detail f6"><span>分类</span></div>
            <div class="detail f1"></div>
            <div class="detail f2"><span>操作</span></div>
            <div class="line"></div>
          </div>
          <div class="x_tbody">
            <div class="x_td" v-for="(item, index) in list" :key="index">
              <div class="detail f2"><span>{{item.title}}</span></div>
              <div class="detail f1"></div>
              <div class="detail f6"><span>{{item.type}}</span></div>
              <div class="detail f1"></div>
              <div class="detail f2">
                <div class="deBtn"  @click="delsc(item.id)">取消收藏</div>
                <a :href="item.fileUrl" class="deBtn">立即下载</a>
              </div>
            </div>
          </div>
          <div class="x_bottom">
            <div class="left"></div>
            <div class="right">
              <el-pagination background layout="prev, pager, next , jumper" :total="total" @current-change="handleCurrentChange">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <span>确定取消收藏么？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="delqr">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  //   name: 'Index',
  components: {},
  data() {
    return {
      loading: true,
      type:1,// 1 产品 2 解决方案 3 新闻动态 4 下载中心
      form:{
        pageNum: 1,
        pageSize: 10,
        // // customerNumber:localStorage.getItem('customerNumber')
      },
      list:'',
      total: 0,
      id:'',
      dialogVisible: false
    };
  },
  created() {
    this.changeType(4)
  },
  methods: {
    //取消收藏
    delsc(id){
      this.id = id
      this.dialogVisible = true
    },

    delqr(){
      this.$post(this.apiUrl + 'customer/customerPage/cancelCollection',{id:this.id}).then((response) => {
        if(response.code == 200){
          this.getlist()
          this.$message({
              message: '取消收藏成功！',
              type: 'success'
          });
          this.dialogVisible = false
        }else{
          this.$message({
              message: response.msg,
              type: 'warning'
          });
        }
      })
    },

    getlist(){
      this.loading = true
      let type = this.type
      if(type == '1'){
        this.$post(this.apiUrl + 'customer/customerPage/productCollection',this.form).then((response) => {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
        })
      } else if(type == '2'){
        this.$post(this.apiUrl + 'customer/customerPage/solutionCollection',this.form).then((response) => {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
        })
      } else if(type == '3'){
        this.$post(this.apiUrl + 'customer/customerPage/newsCollection',this.form).then((response) => {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
        })
      } else if(type == '4'){
        this.$post(this.apiUrl + 'customer/customerPage/downloadCollection',this.form).then((response) => {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
        })
      }
    },
    //改变分类
    changeType(type){
      this.list = []
      this.type = type;
      this.form.pageNum = 1
      this.getlist()
    },

    handleCurrentChange(val){
        this.form.pageNum = val
        this.getlist()
    },
  },
};
</script>

<style lang="less" scoped>
.contain {
  // height: 100%;
  padding: 30px 30px 50px 30px;
  background: #f2f2f7;
}
.contain .info {
  background-color: #ffffff;
  box-shadow: 0px 3px 7px 0px rgba(21, 21, 41, 0.1);
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.x_title {
  height: 72px;
  padding: 0 16px;
  font-size: 18px;
  color: #495057;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-bottom: 1px solid #e6e6e6;
  flex-shrink: 0;
}

.x_title .detail {
  width: 100px;
  height: 36px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #495057;
  cursor: pointer;
  margin-left: 10px;
}
.x_title .detail:first-of-type {
  margin-left: 39px;
}
.x_title .detailActive {
  background: #ED6C00;
  box-shadow: 0px 3px 5px 0px rgba(237, 109, 0, 0.1);
  color: #fff;
}
.x_table{
  padding: 20px;
  box-sizing: border-box;
}
// 产品
.cp,.jjfa,.xwdt,.xxzx{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.cp .thead{
  height: 100px;
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  font-size: 16px;
  color: #3A3A3C;
  display: flex;
  flex-shrink: 0;
}
.cp .thead .one{
  flex: 3;
  border-right: 1px solid #ebebeb;
}
.cp .thead span{
  display: flex;
  align-items: center;
  padding-left: 18px;
  height: 100%;
}
.cp .thead .two{
  flex: 4;
  display: flex;
  flex-direction: column;
}
.cp .thead .two .top{
  flex: 1;
  border-right: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}
.cp .thead .two .bottom{
  flex: 1;
  display: flex;
}
.cp .thead .two .bottom .detail{
  flex: 1;
  border-right: 1px solid #ebebeb;
}
.cp .thead .three,.cp .thead .four{
  flex: 1;
  border-right: 1px solid #ebebeb;
}
.cp .thead .five{
  flex: 1;
}

.cp .tbody{
  flex: 1;
  overflow-y: auto;
}
.cp .tbody .detail{
  display: flex;
  border: 1px solid #ebebeb;
  border-top: 0;
}
.cp .tbody .detail .one{
  flex: 3;
  border-right: 1px solid #ebebeb;
}
.cp .tbody .detail .one .oneInfo{
  display: flex;
  align-items: center;
  padding: 15px;
}
.cp .tbody .detail .one .left{
  width: 91px;
  height: 91px;
  flex-shrink: 0;
  margin-right: 20px;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cp .tbody .detail .one .left img{
  width: 100%;
  height: 100%;
  object-fit: contain;  
}
.cp .tbody .detail .one .right{
  flex: 1;
  width: 10px;
}
.cp .tbody .detail .one .right .top{
  display: flex;
  align-items: center;
  text-decoration: none;
}
.cp .tbody .detail .one .right .top img{
  margin-right: 8px;
}
.cp .tbody .detail .one .right .top span{
  flex: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp:1;
  overflow: hidden;
  word-break: break-all;
  font-size: 18px;
  color: #ED6C00;
  font-weight: bold;
}
.cp .tbody .detail .one .right .bottom{
  font-size: 14px;
  color: #aaa;
  text-align: justify;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp:2;
  overflow: hidden;
  word-break: break-all;
}
.cp .tbody .detail .two{
  flex: 4;
  display: flex;
}
.cp .tbody .detail .two .twoDetail{
  flex: 1;
  border-right: 1px solid #ebebeb;
}
.cp .tbody .detail .two .twoDetail span,.cp .tbody .detail .three span{
  display: flex;
  align-items: center;
  padding-left: 14px;
  font-size: 16px;
  color: #636366;
  height: 100%;
}
.cp .tbody .detail .three{
  flex: 1;
  border-right: 1px solid #ebebeb;
}
.cp .tbody .detail .four{
  flex: 1;
  border-right: 1px solid #ebebeb;
  display: flex;
  align-content: center;
}
.cp .tbody .detail .four a{
  font-size: 14px;
  color: #ED6C00;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding-left: 14px;
}
.cp .tbody .detail .four a img{
  margin-right: 5px;
}
.cp .tbody .detail .five{
  flex: 1;
  display: flex;
  align-items: center;
}
.cp .tbody .detail .five span{
  display: flex;
  align-items: center;
  padding-left: 14px;
  font-size: 14px;
  color: #ED6C00;
  font-weight: bold;
  cursor: pointer;
}
// 解决方案 新闻动态 下载中心
.x_thead {
  display: flex;
  align-items: center;
  height: 45px;
  background: #f3f6f9;
}
.x_thead .detail {
  font-size: 14px;
  color: #878a99;
  padding-left: 26px;
  display: flex;
  align-items: center;
  height: 100%;
}
.x_thead .line {
  width: 8px;
  height: 100%;
}
.f1 {
  flex: 1;
}
.f2 {
  flex: 2;
}
.f3 {
  flex: 3;
}
.f4 {
  flex: 4;
}
.f5 {
  flex: 5;
}
.f6 {
  flex: 6;
}
.x_tbody {
  flex: 1;
  overflow-y: auto;
}
.x_tbody .x_td {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
}
.x_tbody .x_td .detail {
  font-size: 14px;
  color: #212529;
  display: flex;
  padding-left: 26px;
  align-items: center;
  text-align: justify;
  height: 53px;
}
.x_tbody .x_td .detail span{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp:1;
  overflow: hidden;
  word-break: break-all;
}
.x_tbody .x_td .detail .deBtn {
  width: 60px;
  height: 22px;
  background-color: #fff1e5;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ED6C00;
  cursor: pointer;
  margin-right: 10px;
  text-decoration: none;
}
.x_tbody .x_td .detail .deBtn img {
  margin-right: 6px;
}



.x_bottom {
  height: 74px;
  display: flex;
  align-items: center;
  padding: 0 21px 14px;
  justify-content: space-between;
}
.x_bottom .left {
  font-size: 14px;
  color: #828593;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ED6C00;
}
/deep/.el-pagination.is-background .el-pager li:hover {
  color: #ED6C00!important;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #fff!important;
}
//通用滚动条样式
::-webkit-scrollbar {
  width: 8px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: rgba(237, 109, 0, 0.3);
}

// 高度为整屏
// .x_table{
//   flex: 1;
//   height: 10px;
// }
</style>
