<template>
  <div class="contain">
    <div class="info">
      <div class="x_title">
        订单查询
        <div class="detail detailActive">普通订单</div>
        <p data-v-619f20c8="" style="color: rgb(130, 133, 147); font-size: 16px; margin-left: auto; font-weight: normal;">统计时间范围：2022-01-01 - {{nowtime}}</p>
        <!-- <div class="detail">试样订单</div> -->
      </div>
      <div class="x_screen">
        <div class="left">
          <div class="date">
            <el-date-picker
              v-model="checkTime"
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy.MM.dd"
              value-format="yyyy-MM-dd"
              @change="changeTime"
            >
            </el-date-picker>
          </div>
          <div class="others">
          <el-select
              v-model="form.zsybt"
              clearable
              placeholder="请选择事业部"
            >
              <el-option
                v-for="item in sstwoSelect"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            </div>
          <div class="others">
            <el-select
              v-model="form.invoicingStatus"
              placeholder="请选择开票状态"
              @change="changeOne"
              clearable
            >
              <el-option
                v-for="item in oneSelect"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="others">
            <el-select
              v-model="form.isCollection"
              placeholder="请选择发货状态"
              @change="changeTwo"
              clearable
            >
              <el-option
                v-for="item in twoSelect"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="others" style="width: 250px">
            <el-input
            placeholder="请输入产品关键词或订单号搜索"
            prefix-icon="el-icon-search"
            v-model="keywords"
          >
          </el-input>
          </div>

          
        </div>
        <!-- <div class="right">
          
        </div> -->
        <button class="searchbtn" style="margin-left: auto" @click="beginSearch">查询</button>
        <button class="searchbtn" @click="showload">导出</button>
      </div>
      <div class="x_thead">
        <div class="detail f1"><span>采购单号(客户)</span></div>
        <div class="detail f1"><span>订单号(博威)</span></div>
        <div class="detail f1"><span>物料号(客户)</span></div>
        <div class="detail f1"><span>事业部</span></div>
        <div class="detail f2"><span>产品名称(博威)</span></div>
        <div class="detail f1"><span>数量</span></div>
        <div class="detail f1"><span>订单状态</span></div>
        <div class="detail f1"><span>开票状态</span></div>
        <div class="detail f1"><span>订单时间</span></div>
        <div class="detail f1"><span>承诺发货时间</span></div>
        <div class="detail f2"><span>操作</span></div>
        <div class="line"></div>
      </div>
      <div class="x_tbody" v-loading="loading">
        <!-- <div class="x_th" v-for="(titem, tindex) in list" :key="tindex" :class="tindex == activeindex ? 'x_thActive':''"> -->
        <div class="x_th" v-for="(titem, tindex) in list" :key="tindex" >
          <div class="x_td">
            <div class="detail f1"><span>{{titem.bstnk}}</span></div>
            <div class="detail f1"><span>{{titem.vbelnDeliver}}</span></div>
            <div class="detail f1"><span>{{titem.kdmat}}</span></div>
            <div class="detail f1"><span>{{titem.zsybt}}</span></div>
            <div class="detail f2">
              <span>{{titem.arktx}}</span>
            </div>
            <div class="detail f1"><span>{{titem.kwmeng}}</span></div>
            <div class="detail f1">
              <span>
                <div class="deBtn" v-if="titem.dispatchStatus">{{titem.dispatchStatus}}</div>
              </span>
            </div>
            <div class="detail f1"><span>{{titem.invoicingStatus}}</span></div>
            <div class="detail f1"><span>{{titem.erdatCrm}}</span></div>
            <div class="detail f1"><span>{{titem.edatu}}</span></div>
            <div class="detail f2">
              <span style="display:flex">
                <div class="deBtn" @click="gotoNav(titem.zvbelns)">订单详情</div>
              <!-- <div class="deBtn" @click="openXj(titem.zvbelns)">
                <img src="../../assets/images/x_icon0.png" alt="" />询价
              </div> -->
              <div class="deBtn zk" @click="changeStatus(tindex)" v-if="titem.children && titem.children.length > 0" :style="titem.zktype == '1'?'display:none;cursor: default;':''">
                <img src="../../assets/images/x_down.png" alt="" />展开
              </div>
              <div class="deBtn sq" @click="changeStatus(tindex)" v-if="titem.children && titem.children.length > 0" :style="titem.zktype != '1'?'display:none;cursor: default;':''">
                <img src="../../assets/images/x_up.png" alt="" />收起
              </div>
              </span>
            </div>
          </div>
          <div class="x_td" v-for="(sitem, sindex) in titem.children" :key="sindex" :style="titem.zktype != '1'? 'display: none' : 'display: flex'">
            <div class="detail f1"></div>
            <div class="detail f1"></div>
            <div class="detail f1"><span>{{sitem.kdmat}}</span></div>
            <div class="detail f1"></div>
            <div class="detail f2">
              <span>{{sitem.arktx}}</span>
            </div>
            <div class="detail f1"><span>{{sitem.kwmeng}}</span></div>
            <div class="detail f1">
              <span>
                <div class="deBtn" v-if="titem.dispatchStatus">{{sitem.dispatchStatus}}</div>
              </span>
            </div>
            <div class="detail f1"><span>{{sitem.invoicingStatus}}</span></div>
            <div class="detail f1"><span>{{sitem.erdatCrm}}</span></div>
            <div class="detail f1"><span>{{sitem.edatu}}</span></div>
            <div class="detail f2"></div>
          </div>
        </div>
      </div>
      <div class="x_bottom">
        <div class="left"><span style="color:#ED6C00">共{{total}}条</span>，所有数据仅供参考，一切以双方财务对账为准</div>
        <div class="right">
          <el-pagination background layout="prev, pager, next , jumper" :total="total" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
      <!-- 询价弹窗 -->
      <transition name="el-fade-in-linear">
        <div class="xjPop" v-show="isOpenXj">
          <div class="popInfo">
            <div class="top">
              <span>询价单</span>
              <img src="../../assets/images/x_close.png" alt="" @click="closeXj">
            </div>
            <div class="middle">
              <div class="one">
                <div class="left">询价单</div>
                <div class="right" @click="addXj">新增询价</div>
              </div>
              <div class="two">
                <div class="deOne">商品名称</div>
                <div class="deTwo">数量</div>
                <div class="deTwo">单位</div>
                <div class="deThree">备注</div>
                <div class="deFour"></div>
              </div> 
              <div class="three">
                <div class="detail" v-for="(item,index) in orders" :key="index">
                  <div class="deOne">
                    <el-input v-model="item.productName" placeholder="请输入商品名称"></el-input>
                  </div>
                  <div class="deTwo">
                    <el-input v-model="item.productNum" placeholder="请输入数量"></el-input>
                  </div>
                  <div class="deTwo">
                    <el-select v-model="item.productUnit" placeholder="请选择">
                      <el-option
                        v-for="sitem in unitList"
                        :key="sitem.id"
                        :label="sitem.name"
                        :value="sitem.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div class="deThree">
                    <el-input v-model="item.productRemark" placeholder="请填写备注，如：型号、性能、用途等"></el-input>
                  </div>
                  <div class="deFour">
                    <div class="fourBtn" @click="delXj(index)"><img src="../../assets/images/x_del.png" alt="">删除</div>
                  </div>
                </div>
              </div>
              <div class="four">联系方式</div>
              <div class="five">
                <div class="deOne">联系人</div>
                <div class="deTwo">手机号</div>
                <div class="deThree">公司名称</div>
              </div>
              <div class="six">
                <div class="deOne">
                  <el-input v-model="xjform.username" placeholder="请填写联系人"></el-input>
                </div>
                <div class="deTwo">
                  <el-input v-model="xjform.phone" placeholder="请填写手机号"></el-input>
                </div>
                <div class="deThree">
                  <el-input v-model="xjform.company" placeholder="请填写公司名称"></el-input>
                </div>
              </div>
              <div class="seven">
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  :action="upurl + 'customer/customerPage/CustomerUpload'"
                  :headers="{Authorization:Authorization}"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  :on-success="yyzzUpload"
                  >
                  <el-button slot="trigger" size="small" type="primary" class="x_upLoad">添加附件</el-button>
                </el-upload>
              </div>
            </div>
            <div class="bottom">
              <div class="left">
                提交询价后，博威合金会1~2个工作日内与您联系，请注意接听，您也可以直接拨打电话咨询
                <img src="../../assets/images/x_phone.png" alt=""><span>400 9262 798</span>
              </div>
              <div class="right">
                <div class="cancel" @click="closeXj">取消</div>
                <div class="submit" @click="submitxj">提交询价</div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      
    </div>
    <div class="popdc" @click="hidedown" v-if="is_dc">
      <div class="popdcview" @click.stop="" v-loading="loading1">
          <p>选择要导出的数据</p>
          <div>
            <p @click="download(1)">全部导出</p>
            <p @click="download(2)">当前页导出</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
export default {
  // name: 'Index',
  components: {},
  data() {
    return {
      is_dc: false,
      loading: true,
      loading1: false,
      checkTime: [], //时间范围
      oneSelect: [
        {
          id: "A",
          name: "未开票",
        },
        {
          id: "B",
          name: "部分开票",
        },{
          id: "C",
          name: "完全开票",
        },
      ],
      oneValue: "",
      twoSelect: [
        {
          id: "A",
          name: "未发货",
        },
        {
          id: "B",
          name: "部分发货",
        },{
          id: "C",
          name: "全部发货",
        },
      ],
      twoValue: "",

      keywords: "", //搜索关键词

      isOpenXj:false,//是否打开询价弹窗

      activeindex:'999',
      unitList:[
        {
          id:'1',
          name:"千克",
        },
        {
          id:'2',
          name:"吨",
        },
      ],//单位列表

      orders:[
        {
          productName:"",//商品名称
          productNum:"1",//数量
          productUnit:"1",//选中单位
          productRemark:"",//备注
        },
      ],//询价单列表
      imageList:[],
      xjform:{
        username:"",//联系人
        phone:"",//手机号
        company:"",//公司名称
        productVos:'',
        fileUrlL:''
      },
      fileList:[],//附件列表
      total:0,
      info:'',
      list:[],
      form:{
        beginDate: "",
        endDate: "",
        keywords: "",
        pageNum: 1,
        invoicingStatus:'',
        isCollection:'',
        pageSize: 10,
        zsybt:''
        // customerNumber:localStorage.getItem('customerNumber')
      },
      isFirstEnter: false,
      upurl:'',
      Authorization:'',
      sstwoSelect:[],
      nowtime:''
      
    };
  },
  beforeRouteEnter(to, from, next) {
    if(from.name=='DdcxDetail'){
      to.meta.isBack=true;
    }
    next();
  },
  activated() {
      this.form.zsybt = ''
      this.form.isCollection = ''
      if(!this.$route.meta.isBack){
        //alert(this.$route.query.zsybt)
        let zsybt = this.$route.query.zsybt
        let fhtyp = this.$route.query.fhtyp
        if(zsybt){
          this.form.zsybt = zsybt
        }
        if(fhtyp){
          this.form.isCollection = fhtyp
        }
        this.getlist()
        // this.$post(this.apiUrl + 'customer/customerPage/getConditionList').then((response1) => {
        //   let list = response1.data.zsybt
        //   let str
        //   for(let i in list){
        //     if(list[i].value == '棒材事业部'){
        //       str = '宁波博威合金材料股份有限公司棒材事业部'
        //     }else if(list[i].value == '线材事业部'){
        //       str = '宁波博威合金材料股份有限公司线材事业部'
        //     }else if(list[i].value == '板带事业部'){
        //       str = '宁波博威合金板带有限公司'
        //     }
        //     this.sstwoSelect.push({'label':str,'value':list[i].value})
        //     this.getlist()
        //   }
        //   this.sstwoSelect.unshift({
        //       'label':'全部',
        //       'value': ""
        //   })
        // })
        
        this.upurl = this.apiUrl
        this.Authorization = 'Bearer ' + localStorage.getItem('token')
        this.$route.meta.isBack=false
        // 恢复成默认的false，避免isBack一直是true，导致每次都获取新数据
        this.isFirstEnter=false;
      }
  },
  created() {
    // let zsybt = this.$route.query.zsybt
    // if(zsybt){
    //   this.form.zsybt = zsybt
    // }
    this.nowtime = this.yest(Date.now())
    this.upurl = this.apiUrl
    this.Authorization = 'Bearer ' + localStorage.getItem('token')
    //this.getlist()
    this.isFirstEnter=true;
    this.$post(this.apiUrl + 'customer/customerPage/getConditionList').then((response1) => {
      let list = response1.data.zsybt
      let str
      for(let i in list){
        if(list[i].value == '棒材事业部'){
          str = '宁波博威合金材料股份有限公司棒材事业部'
        }else if(list[i].value == '线材事业部'){
          str = '宁波博威合金材料股份有限公司线材事业部'
        }else if(list[i].value == '板带事业部'){
          str = '宁波博威合金板带有限公司'
        }
        this.sstwoSelect.push({'label':str,'value':list[i].value})
      }
      this.sstwoSelect.unshift({
          'label':'全部',
          'value': ""
      })
    })
    // this.$post(this.apiUrl + 'customer/customerPage/addInquirySheet',this.xjform).then((response) => {

    // })

    // this.$get('http://192.168.1.78:8080/customer/customerPage/getCookies?callback=12235').then((response) => {

    // })

    // http://192.168.1.78:8081/customer/customerPage/getCookies?name=123&callback=1222
  },
  methods: {
    yest (now) {
        let year = new Date(now).getFullYear();
        let month = new Date(now).getMonth() + 1;
        let date = new Date(now).getDate() - 1;
        if (month < 10) month = "0" + month;
        if (date < 10) date = "0" + date;
        return year + "-" + month + "-" + date;
      },
    showload(){
        this.is_dc = true
      },

      hidedown(){
        this.is_dc = false
      },

    
      download (type) {
        let pnum = this.form.pageNum
        if(type == 1){
          this.form.pageSize = 20000
          this.form.pageNum = 1
        }
        this.loading1 = true
        this.$exexl(this.apiUrl + 'customer/customerPage/dwsSapOrderSearchExport',this.form).then((response) => {
          this.form.pageSize = 10
          if(type == 1){
            this.form.pageSize = 10
            this.form.pageNum = pnum
          }
          const blob = new Blob([response])
          const downloadElement = document.createElement('a')
          const href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = '订单.xlsx' // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
          this.loading1 = false
          this.is_dc = false
        })
      },
    getlist(){
        this.loading = true
        this.activeindex = '999'
        this.$post(this.apiUrl + 'customer/customerPage/dwsSapOrderSearch',this.form).then((response) => {
          let list = response.data.records
          for(let i in list){
            list[i].zktype = 1
          }
          this.list = list
          console.log(list)
          this.total = response.data.total
          this.loading = false
        })
    },
    handleCurrentChange(val){
        this.form.pageNum = val
        this.getlist()
    },
    //开始搜索
    beginSearch() {
      console.log(this.checkTime);
      if(this.checkTime == null){
        this.form.beginDate = ''
        this.form.endDate = ''
      }else{
        this.form.beginDate = this.checkTime[0]
        this.form.endDate = this.checkTime[1]
      }
      this.form.keywords = this.keywords
      this.form.pageNum = 1
      this.getlist()
    },
    // 改变时间
    changeTime() {
      let checkTime = this.checkTime;
      // console.log(checkTime);
    },
    //改变是否开票
    changeOne() {
      console.log(this.oneValue);
    },
    //改变是否收款
    changeTwo() {
      console.log(this.twoValue);
    },
    //前往订单详情
    gotoNav(id){
      console.log(id)
      let that = this;
      that.$router.push({
        path: '/DdcxDetail',
        name: 'DdcxDetail',
        query: {
          id,
        },
      },()=>{},()=>{});
    },
    //展开
    changeStatus(tindex){
      let list = this.list
      if(list[tindex].zktype == '1'){
        list[tindex].zktype = '0'
      }else{
        list[tindex].zktype = '1'
      }
      this.list = list
    },
    //打开询价弹窗
    openXj(id){
      console.log(id);
      this.$post(this.apiUrl + 'customer/customerPage/sheetProductDetails',{zvbelns:id}).then((response) => {
          // this.list = response.data.records
          // this.total = response.data.total
          // this.loading = false
        })
      let that = this;
      that.isOpenXj = true;
    },
    //删除询价产品
    delXj(index){
      let that = this;
      let orders = that.orders;
      orders.splice(index,1);
    },
    //新增询价
    addXj(){
      let that = this;
      let orders = that.orders;
      let str = {
        productName:"",//商品名称
        productNum:"10",//数量
        productUnit:"1",//选中单位
        productRemark:"",//备注
      };
      orders.push(str)
    },
    //上传附件
    handleRemove(file, fileList) {
        this.imageList= fileList
        let listxx = []
        this.imageList.forEach(item => {
            listxx.push(item.response.url)
        })
        this.xjform.fileUrl = listxx
    },

    handlePreview(file) {
      console.log(file);
    },
    yyzzUpload(res, file, fileList) {
      if(res.code !== 200){
        return this.$message.error(res.msg)
      }
      
      this.imageList = fileList
      console.log(this.imageList)
      let formImgList = []
      this.imageList.forEach(item => {
        formImgList.push(item.response.url)
      })
      this.xjform.fileUrl = formImgList
    },
    //关闭询价
    closeXj(){
      this.isOpenXj = false;
    },

    submitxj(){
      if(this.xjform.fileUrl){
        this.xjform.fileUrl = this.xjform.fileUrl.toString()
      }
      this.xjform.productVos = JSON.stringify( this.orders );
      this.$post(this.apiUrl + 'customer/customerPage/addInquirySheet',this.xjform).then((response) => {
        if(response.code == 200){
          this.$message({
              message: '提交成功！',
              type: 'success'
          });
          this.isOpenXj = false;
        }else{
          this.$message({
              message: response.msg,
              type: 'error'
          });
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.contain {
  padding: 30px 30px 50px 30px;
  background: #f2f2f7;
}
.contain .info{
  background-color: #ffffff;
	box-shadow: 0px 3px 7px 0px	rgba(21, 21, 41, 0.1);
	border-radius: 4px;
}
.x_title {
  height: 72px;
  padding: 0 16px;
  font-size: 18px;
  color: #495057;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-bottom: 1px solid #e6e6e6;
}
.x_title .detail {
  width: 100px;
  height: 36px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #495057;
  cursor: pointer;
  margin-left: 10px;
}
.x_title .detail:first-of-type {
  margin-left: 39px;
}
.x_title .detailActive {
  background: #ED6C00;
  box-shadow: 0px 3px 5px 0px rgba(237, 109, 0, 0.1);
  color: #fff;
}
.x_screen {
  height: 73px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  padding: 0 16px;
  border-bottom: 1px solid #e6e6e6;
}
.x_screen .left {
  display: flex;
  align-items: center;
}

.x_screen .left .others {
  width: 148px;
  margin-left: 20px;
}

.x_screen .right {
  width: 500px;
  margin-left: 20px;
}
.x_thead {
  display: flex;
  align-items: center;
  height: 45px;
  background: #f3f6f9;
}
.x_thead .detail {
  font-size: 14px;
  color: #878a99;
  padding-left: 26px;
  display: flex;
  align-items: center;
  height: 100%;
  white-space: nowrap;
}
.x_thead .line {
  width: 8px;
  height: 100%;
}
.f1 {
  flex: 1;
}
.f2 {
  flex: 2;
}
.f3 {
  flex: 3;
}
.f4 {
  flex: 4;
}
.x_tbody {
  flex: 1;
  overflow-y: auto;
  min-height: 630px;
}
.x_tbody .x_td {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
}
.x_tbody .x_td .detail {
  font-size: 14px;
  color: #212529;
  display: flex;
  padding: 20px 0 20px 26px;
  align-items: center;
  min-height: 53px;
  text-align: justify;
  width: min-content;
}
.x_tbody .x_td .detail .deBtn {
  width: 60px;
  height: 22px;
  background-color: #fff1e5;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ED6C00;
  cursor: pointer;
  margin-right: 10px;
}
.x_tbody .x_td .detail .deBtn img {
  margin-right: 6px;
}
.x_tbody .x_td .detail .zk{
  display: flex;
}
.x_tbody .x_td .detail .sq{
  display: flex;
}
.x_bottom {
  height: 74px;
  display: flex;
  align-items: center;
  padding: 0 21px 14px;
  border-top: 1px solid #e6e6e6;
  justify-content: space-between;
}
.x_bottom .left {
  font-size: 14px;
  color: #828593;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ED6C00;
}
/deep/.el-pagination.is-background .el-pager li:hover {
  color: #ED6C00!important;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #fff!important;
}
//通用滚动条样式
::-webkit-scrollbar {
  width: 8px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: rgba(237, 109, 0, 0.3);
}


.x_tbody .x_th{
  transition: height .5s;
  overflow: hidden;
}
.x_tbody .x_thActive{
  background: #fefaf6;
	border: 1px solid  #ED6C00;
  height: auto;
}
.x_tbody .x_thActive .detail .zk{
  display: none;
}
.x_tbody .x_thActive .detail .sq{
  display: flex;
}


// 询价弹窗
.xjPop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 130;
}
.xjPop .popInfo{
  width: 1100px;
  max-height: 640px;
  background-color: #ffffff;
	border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.xjPop .popInfo .top{
  height: 56px;
	background-color: #fae5d9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  flex-shrink: 0;
}
.xjPop .popInfo .top span{
  font-size: 14px;
  color: #495057;
}
.xjPop .popInfo .top img{
  cursor: pointer;
}
.xjPop .popInfo .middle{
  flex: 1;
  overflow-y: auto;
  padding: 20px 25px 0 30px;
}
.xjPop .popInfo .middle .one{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.xjPop .popInfo .middle .one .left{
  font-size: 18px;
  color: #495057;
}

.xjPop .popInfo .middle .one .right{
  width: 100px;
	height: 36px;
	background-color: #ED6C00;
	box-shadow: 0px 3px 5px 0px 	rgba(237, 109, 0, 0.1);
	border-radius: 3px;
  font-size: 18px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.xjPop .popInfo .middle .two{
  height: 40px;
	background-color: #f4f4f4;
  display: flex;
}
.xjPop .popInfo .middle .two .deOne{
  width: 300px;
  margin-right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #495057;
  padding-left: 14px;
}
.xjPop .popInfo .middle .two .deTwo{
  width: 100px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #495057;
}
.xjPop .popInfo .middle .two .deThree{
  width: 400px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #495057;
}
.xjPop .popInfo .middle .two .deFour{
  width: 100px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #495057;
}
.xjPop .popInfo .middle .three{
  padding: 20px 0 30px;
  border-bottom: 1px solid #e6e6e6;
}
.xjPop .popInfo .middle .three .detail{
  display: flex;
  margin-bottom: 14px;
}
.xjPop .popInfo .middle .three .detail:last-of-type{
  margin-bottom: 0;
}

.xjPop .popInfo .middle .three .detail input{
  width: 100%;
  font-size: 14px;
  color: #3A3A3C;
}

.xjPop .popInfo .middle .three .detail .deOne{
  width: 300px;
  margin-right: 10px;
}
.xjPop .popInfo .middle .three .detail .deTwo{
  width: 100px;
  margin-right: 10px;
}
.xjPop .popInfo .middle .three .detail .deThree{
  width: 400px;
  margin-right: 10px;
}
.xjPop .popInfo .middle .three .detail .deFour{
  width: 100px;
}
.xjPop .popInfo .middle .three .detail .deFour .fourBtn{
  width: 100%;
	height: 40px;
	background-color: #aaaaaa;
	border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}
.xjPop .popInfo .middle .three .detail .deFour .fourBtn img{
  margin-right: 8px;
}
.xjPop .popInfo .middle .four{
  font-size: 18px;
  color: #495057;
  line-height: 1;
  margin: 30px 0 23px;
}
.xjPop .popInfo .middle .five{
  display: flex;
  height: 40px;
	background-color: #f4f4f4;
}
.xjPop .popInfo .middle .five .deOne{
  width: 300px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #495057;
  padding-left: 16px;
}
.xjPop .popInfo .middle .five .deTwo{
  width: 210px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #495057;
}
.xjPop .popInfo .middle .five .deThree{
  width: 515px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #495057;
}
.xjPop .popInfo .middle .six{
  display: flex;
  margin: 20px 0;
}
.xjPop .popInfo .middle .six input{
  width: 100%;
  font-size: 14px;
  color: #3A3A3C;
}
.xjPop .popInfo .middle .six .deOne{
  width: 300px;
  margin-right: 10px;
}
.xjPop .popInfo .middle .six .deTwo{
  width: 210px;
  margin-right: 10px;
}
.xjPop .popInfo .middle .six .deThree{
  width: 515px;
}
.xjPop .popInfo .middle .seven{
  padding-bottom: 30px;
}
.xjPop .popInfo .middle .seven .upload-demo{
  display: flex;
}
.xjPop .popInfo .middle .seven .x_upLoad{
  font-size: 14px;
  color: #fff;
  background-color: #ED6C00;
  border: 0;
}
/deep/ .el-upload{
  margin-right: 30px;
}
/deep/ .el-upload-list__item:first-child{
  margin-top: 0;
}
/deep/.el-upload-list__item .el-icon-close{
  top: 10px;
}
/deep/.el-upload-list__item-name{
  height: 32px;
  line-height: 32px;
}

.xjPop .popInfo .bottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0 35px;
  width: 1040px;
  margin: auto;
  border-top: 1px solid #e6e6e6;
}
.xjPop .popInfo .bottom .left{
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #3A3A3C;
}
.xjPop .popInfo .bottom .left span{
  color: #ED6C00;
}
.xjPop .popInfo .bottom .left img{
  margin: 0 5px 0 15px;
}
.xjPop .popInfo .bottom .right{
  display: flex;
  align-items: center;
}
.xjPop .popInfo .bottom .right .cancel{
  width: 60px;
	height: 36px;
	background-color: #f3f6f9;
	box-shadow: 0px 3px 5px 0px 	rgba(237, 109, 0, 0.1);
	border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #353637;
  cursor: pointer;
}
.xjPop .popInfo .bottom .right .submit{
  width: 100px;
	height: 36px;
	background-color: #ED6C00;
	box-shadow: 0px 3px 5px 0px 	rgba(237, 109, 0, 0.1);
	border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
}

.searchbtn{
    width: 100px;
    height: 40px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    background-color: #ED6C00;
    box-shadow: 0px 3px 5px 0px rgb(237 109 0 / 10%);
    color: #fff;
    border: 0;
    margin-left: 20px;
    padding: 0 10px;
    white-space: nowrap;
}
.popdc{
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.4);
  z-index: 111;

  .popdcview{
    width: 300px;
    // height: 200px;
    padding: 20px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

    &>p{
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      color: #ED6C00;
    }

    &>div{
      display: flex;
      margin-top: 20px;
      justify-content: center;

      p{
        width: 100px;
        height: 40px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 16px;
        background-color: #ED6C00;
        box-shadow: 0px 3px 5px 0px rgb(237 109 0 / 10%);
        color: #fff;
        border: 0;
      }

      p:nth-child(2){
        margin-left: 20px;
      }
    }
  }
}

// 高度为整屏
// .contain {
//   height: 100%;
// }
// .contain .info{
//   height: 100%;
//   display: flex;
//   flex-direction: column;
// }
</style>
