<template>
  <div class="contain">
    <div class="info">
      <div class="x_title">备货库存查询
        <p data-v-619f20c8="" style="color: rgb(130, 133, 147); font-size: 16px; margin-left: auto; font-weight: normal;">统计时间范围：2022-01-01 - {{nowtime}}</p>
      </div>
      <div class="x_screen">
          <el-select
              v-model="form.zsybt"
              clearable
              placeholder="请选择事业部"
            >
              <el-option
                v-for="item in sstwoSelect"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- <el-input
            placeholder="请输入物料名搜索"
            prefix-icon="el-icon-search"
            v-model="form.maktx"
          >
          </el-input>
          <el-input
            placeholder="请输入牌号搜索"
            prefix-icon="el-icon-search"
            v-model="form.zkhph"
          >
          </el-input> -->
          <el-input
            placeholder="请输入关键词搜索"
            prefix-icon="el-icon-search"
            v-model="form.keywords"
          >
          </el-input>
          <button class="searchbtn" @click="getlist">查询</button>
          <button class="searchbtn" @click="showload">导出</button>
          </div>
      <div class="x_thead">
        <div class="detail f2"><span>物料名</span></div>
        <div class="detail f1"><span>物料号(客户)</span></div>
        <div class="detail f1"><span>事业部</span></div>
        <!-- <div class="detail f1">产品牌号</div> -->
        <div class="detail f1"><span>当前可用库存</span></div>
        <!-- <div class="detail f1">工厂编码</div> -->
        <div class="detail f1"><span>备库订单数量</span></div>
        <div class="detail f1"><span>已核销数量</span></div>
        <div class="detail f1"><span>未核销数量</span></div>
        <div class="detail f1"><span>入库时间</span></div>
        <div class="detail f1"><span>出库时间</span></div>
        <div class="detail f1"><span>操作</span></div>
        <div class="line"></div>
      </div>
      <div class="x_tbody"  v-loading="loading">
        <div class="x_td" v-for="(item, index) in list" :key="index">
          <div class="detail f2">{{item.maktx}}</div>
          <div class="detail f1">{{item.kdmat}}</div>
          <div class="detail f1">{{item.zsybt}}</div>
          
          <!-- <div class="detail f1">{{item.zkhph}}</div> -->
          <div class="detail f1">{{item.kalab}}</div>
          <!-- <div class="detail f1">{{item.werks}}</div> -->
          <div class="detail f1">{{item.kwmeng}}</div>
          <div class="detail f1">{{item.lfimg}}</div>
          <div class="detail f1">{{item.nolfimg}}</div>
          <div class="detail f1">{{item.bldatDate}}</div>
          <div class="detail f1">{{item.wadatIst}}</div>
          <div class="detail f1">
            <div class="deBtn" @click="openDetail(item.matnr,item.posnr,item.vbeln,item.werks)" v-if="item.vbeln" >
              <img src="../../assets/images/x_icon1.png" alt="" />详情
            </div>
          </div>
        </div>
      </div>
      <div class="x_bottom">
        <div class="left"><span style="color:#ED6C00">共{{total}}条</span>，所有数据仅供参考，一切以双方财务对账为准</div>
        <div class="right">
          <el-pagination background layout="prev, pager, next , jumper" :total="total" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </div>
    <transition name="el-fade-in-linear">
        <div class="fpPop" v-show="isOpenDetail">
          <div class="popInfo">
            <div class="top">
              <span>库存详情</span>
              <img src="../../assets/images/x_close.png" alt="" @click="closeDetail">
            </div>
            <div class="bottom">
              <div class="one" v-if="info">
                <!-- <div class="detail"><span>发票性质：</span><span>纸质发票</span></div>
                <div class="detail"><span>发票状态：</span><span>已邮寄</span></div> -->
                <!-- <div class="detail"><span>发票类型：</span><span>{{info.fkart}}</span></div>
                <div class="detail"><span>发票抬头：</span><span>{{info.name1}}</span></div>
                <div class="detail"><span>收票人：</span><span>{{info.llianxiren}}</span></div>
                <div class="detail"><span>收票地址：</span><span>{{info.stras}}</span></div>
                <div class="detail"><span>发票号：</span><span>{{info.goldtaxNum}}</span></div>
                <div class="detail"><span>联系电话：</span><span>{{info.tflf1}}</span></div> -->
                <!-- <div class="detail"><span>物流公司：</span><span>EMS</span></div>
                <div class="detail"><span>物流单号：</span><span>1251344630897</span></div> -->
                <!-- <div class="detail bz"><span>备注：</span><span></div> -->
              </div>
              <div class="two">
                <div class="thead">
                  <div class="td f2">博威备库单号</div>
                  <div class="td f4">产品名称</div>
                  <div class="td f2">采购单号(客户)</div>
                  <div class="td f2">物料号(客户)</div>
                  <div class="td f2">事业部</div>
                   
                  
                  <div class="td f2">备库订单数量</div>
                  <div class="td f2">已核销数量</div>
                  <div class="td f2">未核销数量</div>
                  <div class="td f3">订单时间</div>
                  <!-- <div class="td f1">数量</div> -->
                  <div class="line"></div>
                </div>
                <div class="tbody">
                  <div class="tr" style="display:block" v-for="(item,index) in info" :key="index">
                    <div class="tr">
                      <div class="td f2">{{item.zvbelns}}</div>
                      <div class="td f4">{{item.arktx}}</div>
                      <div class="td f2">{{item.bstnk}}</div>
                      <div class="td f2">{{item.kdmat}}</div>
                      <div class="td f2">{{item.zsybt}}</div>
                        
                      
                      <div class="td f2">{{item.kwmeng}}</div>
                      <div class="td f2">{{item.menge}}</div>
                      <div class="td f2">{{item.lessMenge}}</div>
                      <div class="td f3">{{item.erdat}}</div>
                      <!-- <div class="td f1"></div> -->
                    </div>
                    <!-- <div v-if="item.vbelnDeliver == xvbelnDeliver">
                      <div class="tr" v-for="(item) in item.childrenList" :key="item.zvbelns">
                        <div class="td f2">{{item.vbelnDeliver}}</div>
                        <div class="td f3">{{item.arktx}}</div>
                        <div class="td f2">{{item.erdatCrm}}</div>
                      </div>
                    </div> -->
                  </div>
                  <div class="right zright">
                    <el-pagination :page-size="form.pageSize" background layout="prev, pager, next , jumper" :total="total1" @current-change="handleCurrentChange1">
                    </el-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    <div class="popdc" @click="hidedown" v-if="is_dc" >
      <div class="popdcview" @click.stop="" v-loading="loading1">
          <p>选择要导出的数据</p>
          <div>
            <p @click="download(1)">全部导出</p>
            <p @click="download(2)">当前页导出</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
export default {
  //   name: 'Index',
  components: {},
  data() {
    return {
      loading1: false,
      loading: true,
      info:'',
      isOpenDetail:'',
      form:{
          pageSize: 10,
          pageNum : 1,
          maktx: '',
          zkhph:'',
          werks:'',
          zsybt:'',
          keywords:''
          // customerNumber:localStorage.getItem('customerNumber')
      },
      deform:{
        matnr:'',
        pageSize: 5,
        pageNum : 1,
        posnr:'',
        vbeln:'',
        werks:'',
      },
      total1: 0,
      list:'',
      total: 0,
      sstwoSelect:[],
      is_dc: false,
      nowtime:''
    };
  },
  created() {
    this.nowtime = this.yest(Date.now())
    this.$post(this.apiUrl + 'customer/customerPage/getConditionList').then((response1) => {
      //this.sstwoSelect = response1.data.zsybt
      let list = response1.data.zsybt
      let str
      for(let i in list){
        if(list[i].value == '棒材事业部'){
          str = '宁波博威合金材料股份有限公司棒材事业部'
        }else if(list[i].value == '线材事业部'){
          str = '宁波博威合金材料股份有限公司线材事业部'
        }else if(list[i].value == '板带事业部'){
          str = '宁波博威合金板带有限公司'
        }
        this.sstwoSelect.push({'label':str,'value':list[i].value})
      }
      this.sstwoSelect.unshift({
          'label':'全部',
          'value': ""
      })
      //this.oneSelect = response1.data.wgbez
    })
    this.getlist()
  },
  methods: {
      yest (now) {
        let year = new Date(now).getFullYear();
        let month = new Date(now).getMonth() + 1;
        let date = new Date(now).getDate() - 1;
        if (month < 10) month = "0" + month;
        if (date < 10) date = "0" + date;
        return year + "-" + month + "-" + date;
      },
      showload(){
        this.is_dc = true
      },

      hidedown(){
        this.is_dc = false
      },

    
      download (type) {
        let pnum = this.form.pageNum
        if(type == 1){
          this.form.pageSize = 20000
          this.form.pageNum = 1
        }
        this.loading1 = true
        this.$exexl(this.apiUrl + 'customer/customerPage/dwdSapInstockExport',this.form).then((response) => {
          this.form.pageSize = 10
          if(type == 1){
            this.form.pageSize = 10
            this.form.pageNum = pnum
          }
          const blob = new Blob([response])
          const downloadElement = document.createElement('a')
          const href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = '库存.xlsx' // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
          this.loading1 = false
          this.is_dc = false
        })
      },
    getlist(){
        this.loading = true
        this.$post(this.apiUrl + 'customer/customerPage/dwdSapInstock',this.form).then((response) => {
            this.total = response.data.total
            this.list  = response.data.records
            this.loading = false
        })
    },
    handleCurrentChange(val){
        this.deform.pageNum = val
        this.getlist()
    },
    handleCurrentChange1(val){
        this.form.pageNum = val
        this.$post(this.apiUrl + 'customer/customerPage/dwdSapInstockDetails',this.deform).then((response) => {
          this.info = response.data.records
          this.total1 = response.data.total
       })
    },
    //打开详情
    openDetail(matnr,posnr,vbeln,werks){
      //console.log(id);
      let that = this
      this.deform.matnr = matnr
      this.deform.posnr = posnr
      this.deform.vbeln = vbeln
      this.deform.werks = werks
      this.$post(this.apiUrl + 'customer/customerPage/dwdSapInstockDetails',this.deform).then((response) => {
        that.info = response.data.records
        that.total1 = response.data.total
      })
      this.isOpenDetail = true;
    },
    //关闭发票详情
    closeDetail(){
      this.isOpenDetail = false;
    },
  },
};
</script>

<style lang="less" scoped>
.contain {
  // height: 100%;
  padding: 30px 30px 50px 30px;
  background: #f2f2f7;
  min-height: 100%;
}
.contain .info {
  background-color: #ffffff;
  box-shadow: 0px 3px 7px 0px rgba(21, 21, 41, 0.1);
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.x_title {
  height: 72px;
  padding: 0 16px;
  font-size: 18px;
  color: #495057;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-bottom: 1px solid #e6e6e6;
}
.x_thead {
  display: flex;
  align-items: center;
  height: 45px;
  background: #f3f6f9;
}
.x_thead .detail {
  font-size: 14px;
  color: #878a99;
  padding-left: 26px;
  display: flex;
  align-items: center;
  height: 100%;
}
.x_thead .line {
  width: 8px;
  height: 100%;
}
.f1 {
  flex: 1;
}
.f2 {
  flex: 2;
}
.f3 {
  flex: 3;
}
.f4 {
  flex: 4;
}
.x_tbody {
  flex: 1;
  overflow-y: auto;
}
.x_tbody .x_td {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
}
.x_tbody .x_td:last-of-type{
  border-bottom: 0;
}
.x_tbody .x_td .detail {
  font-size: 14px;
  color: #212529;
  padding: 20px 0 20px 26px;
  display: flex;
  align-items: center;
  min-height: 53px;
  text-align: justify;
}
.x_tbody .x_td .detail .deBtn {
  width: 60px;
  height: 22px;
  background-color: #fff1e5;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ED6C00;
  cursor: pointer;
}
.x_tbody .x_td .detail .deBtn img {
  margin-right: 6px;
}
.x_bottom {
  height: 74px;
  display: flex;
  align-items: center;
  padding: 0 21px 14px;
  border-top: 1px solid #e6e6e6;
  justify-content: space-between;
}
.x_bottom .left {
  font-size: 14px;
  color: #828593;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ED6C00;
}
/deep/.el-pagination.is-background .el-pager li:hover {
  color: #ED6C00!important;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #fff!important;
}
//通用滚动条样式
::-webkit-scrollbar {
  width: 8px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: rgba(237, 109, 0, 0.3);
}
.x_screen {
  height: 73px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  padding: 0 16px;
  border-bottom: 1px solid #e6e6e6;
}
.x_screen .left {
  display: flex;
  align-items: center;
}

.x_screen .left .others {
  width: 120px;
  margin-left: 20px;
}

.x_screen .right {
  width: 500px;
  margin-left: 20px;
}
.searchbtn{
    width: 100px;
    height: 40px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    background-color: #ED6C00;
    box-shadow: 0px 3px 5px 0px rgb(237 109 0 / 10%);
    color: #fff;
    border: 0;
    margin-left: 20px;
}

/deep/ .el-input{
  width: auto;
  margin-left: 20px;
}
.popdc{
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.4);
  z-index: 111;

  .popdcview{
    width: 300px;
    // height: 200px;
    padding: 20px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

    &>p{
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      color: #ED6C00;
    }

    &>div{
      display: flex;
      margin-top: 20px;
      justify-content: center;

      p{
        width: 100px;
        height: 40px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 16px;
        background-color: #ED6C00;
        box-shadow: 0px 3px 5px 0px rgb(237 109 0 / 10%);
        color: #fff;
        border: 0;
      }

      p:nth-child(2){
        margin-left: 20px;
      }
    }
  }
}
.x_screen /deep/ .el-input:nth-child(1){
  margin-left: 0;
}

// 发票详情弹窗
.fpPop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 130;
}
.fpPop .popInfo{
  width: 1100px;
  max-height: 700px;
  background-color: #ffffff;
	border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.fpPop .popInfo .top{
  height: 56px;
	background-color: #fae5d9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  flex-shrink: 0;
}
.fpPop .popInfo .top span{
  font-size: 14px;
  color: #495057;
}
.fpPop .popInfo .top img{
  cursor: pointer;
}
.fpPop .popInfo .bottom{
  flex: 1;
  overflow-y: auto;
  padding: 30px;
}
.fpPop .popInfo .bottom .one{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 10px;
}
.fpPop .popInfo .bottom .one .detail{
  width: 49%;
  display: flex;
  font-size: 14px;
  color: #495057;
  margin-top: 20px;
}
.fpPop .popInfo .bottom .one .detail.bz{
  width: 100%;
}
.fpPop .popInfo .bottom .one .detail span:first-child{
  flex-shrink: 0;
}
.fpPop .popInfo .bottom .two{
  margin-top: 0px;
}
.fpPop .popInfo .bottom .two .thead{
	background-color: #f3f6f9;
  display: flex;
}
.fpPop .popInfo .bottom .two .thead .td{
  display: flex;
  align-items: center;
  padding-left: 25px;
  font-size: 14px;
  color: #878a99;
  height: 46px;
}
.fpPop .popInfo .bottom .two .thead .line{
  width: 8px;
  height: 46px;
}
.fpPop .popInfo .bottom .two .tbody .tr{
  display: flex;
  border: 1px solid #eeeeee;
  border-top: 0;
  //height: 340px;
}
.fpPop .popInfo .bottom .two .tbody .tr .td{
  font-size: 14px;
  color: #495057;
  padding: 12px 0 12px 25px;
  text-align: justify;
}

.searchbtn{
    width: 100px;
    height: 40px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    background-color: #ED6C00;
    box-shadow: 0px 3px 5px 0px rgb(237 109 0 / 10%);
    color: #fff;
    border: 0;
    margin-left: 20px;
}

.popdc{
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.4);
  z-index: 111;

  .popdcview{
    width: 300px;
    // height: 200px;
    padding: 20px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

    &>p{
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      color: #ED6C00;
    }

    &>div{
      display: flex;
      margin-top: 20px;
      justify-content: center;

      p{
        width: 100px;
        height: 40px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 16px;
        background-color: #ED6C00;
        box-shadow: 0px 3px 5px 0px rgb(237 109 0 / 10%);
        color: #fff;
        border: 0;
      }

      p:nth-child(2){
        margin-left: 20px;
      }
    }
  }
}
.x_tbody .x_td .detail .deBtn {
  padding: 0 8px;
  height: 22px;
  background-color: #fff1e5;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ED6C00;
  cursor: pointer;
}
.x_tbody .x_td .detail .deBtn img {
  margin-right: 6px;
}
.zright{
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
