<template>
  <div class="contain">
    <div class="info">
      <div class="x_title">发票查询
        <p data-v-619f20c8="" style="color: rgb(130, 133, 147); font-size: 16px; margin-left: auto; font-weight: normal;">统计时间范围：2022-01-01 - {{nowtime}}</p>
      </div>
      <div class="x_screen">
        <div class="left">
          <div class="date">
            <el-date-picker
              v-model="checkTime"
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy.MM.dd"
              value-format="yyyyMMdd"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="right">
          <el-input
            placeholder="请输入发票号、单号进行查询"
            prefix-icon="el-icon-search"
            v-model="keywords"
          >
          </el-input>
        </div>
        <button class="searchbtn" @click="beginSearch">查询</button>
        <button class="searchbtn" @click="showload">导出</button>
      </div>
      <div class="x_thead">
        <div class="detail f2"><span>开票时间</span></div>
        <div class="detail f2"><span>发票号</span></div>
        <div class="detail f2"><span>订单号</span></div>
        <div class="detail f2"><span>采购单号(客户)</span></div>
        <div class="detail f2"><span>开票类型/开票方</span></div>
        <div class="detail f2"><span>发票抬头</span></div>
        <div class="detail f2"><span>发票总额</span></div>
        <div class="detail f2"><span>操作</span></div>
        <div class="line"></div>
      </div>
      <div class="x_tbody" v-loading="loading">
        <div class="x_td" v-for="(item,index) in list" :key="index">
          <div class="detail f2"><span>{{item.fkdat}}</span></div>
          <div class="detail f2"><span>{{item.goldtaxNum}}</span></div>
          <div class="detail f2"><el-tooltip class="item" effect="dark" :content="item.vbelnDelivers" placement="top"><el-button class="textsl">{{item.vbelnDelivers}}</el-button></el-tooltip></div>
          <div class="detail f2"><span>{{item.bstkd}}</span></div>
          <div class="detail f2"><span>{{item.butxt}}</span></div>
          <div class="detail f2"><span>{{item.name1}}</span></div>
          <div class="detail f2"><span>{{item.kzwie}}</span></div>
          <div class="detail f2">
            <div class="deBtn" @click="openDetail(item.vbeln)" v-if="item.vbeln" >
              <img src="../../assets/images/x_icon1.png" alt="" />发票查询
            </div>
          </div>
        </div>
      </div>
      <div class="x_bottom">
        <div class="left"><span style="color:#ED6C00">共{{total}}条</span>，所有数据仅供参考，一切以双方财务对账为准</div>
        <div class="right">
          <el-pagination background layout="prev, pager, next , jumper" :total="total" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
      <!-- 发票详情 -->
      <transition name="el-fade-in-linear">
        <div class="fpPop" v-show="isOpenDetail">
          <div class="popInfo">
            <div class="top">
              <span>发票详情</span>
              <img src="../../assets/images/x_close.png" alt="" @click="closeDetail">
            </div>
            <div class="bottom">
              <div class="one" v-if="info">
                <!-- <div class="detail"><span>发票性质：</span><span>纸质发票</span></div>
                <div class="detail"><span>发票状态：</span><span>已邮寄</span></div> -->
                <div class="detail"><span>发票类型：</span><span>{{info.fkart}}</span></div>
                <div class="detail"><span>发票抬头：</span><span>{{info.name1}}</span></div>
                <div class="detail"><span>收票人：</span><span>{{info.llianxiren}}</span></div>
                <div class="detail"><span>收票地址：</span><span>{{info.stras}}</span></div>
                <div class="detail"><span>发票号：</span><span>{{info.goldtaxNum}}</span></div>
                <div class="detail"><span>联系电话：</span><span>{{info.tflf1}}</span></div>
                <!-- <div class="detail"><span>物流公司：</span><span>EMS</span></div>
                <div class="detail"><span>物流单号：</span><span>1251344630897</span></div> -->
                <!-- <div class="detail bz"><span>备注：</span><span></div> -->
              </div>
              <div class="two">
                <div class="thead">
                  <div class="td f2"><span>开票订单</span></div>
                  <div class="td f2"><span>采购单号(客户)</span></div>
                  <div class="td f2"><span>物料号(客户)</span></div>
                  <div class="td f3"><span>物料名称</span></div>
                  <div class="td f2"><span>下单时间</span></div>
                  <div class="line"></div>
                </div>
                <div class="tbody">
                  <div class="tr" :class="item.vbelnDeliver == xvbelnDeliver ? 'x_thActive':''" style="display:block" v-for="(item,index) in info.dwdSapInvoiceOrderSearchDtos" :key="index">
                    <div class="tr">
                      <div class="td f2" @click="gotoNav(item.zvbelns)">{{item.vbelnDeliver}}</div>
                      <div class="td f2">{{item.bstkd}}</div>
                      <div class="td f2">{{item.kdmat}}</div>
                      <div class="td f3">{{item.arktx}}</div>
                      <div class="td f2" style="display:flex">{{item.erdatCrm}} <span v-if="item.vbelnDeliver != xvbelnDeliver && item.childrenList.length > 0" class="deBtn1" @click="showchild(item.vbelnDeliver)">展开</span><span @click="hidechild" v-if="item.vbelnDeliver == xvbelnDeliver && item.childrenList.length > 0" class="deBtn1">收起</span></div>
                    </div>
                    <div v-if="item.vbelnDeliver == xvbelnDeliver">
                      <div class="tr" v-for="(item) in item.childrenList" :key="item.zvbelns">
                        <div class="td f2">{{item.vbelnDeliver}}</div>
                        <div class="td f2">{{item.bstkd}}</div>
                        <div class="td f2">{{item.kdmat}}</div>
                        <div class="td f3">{{item.arktx}}</div>
                        <div class="td f2">{{item.erdatCrm}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="popdc" @click="hidedown" v-if="is_dc">
      <div class="popdcview" @click.stop="" v-loading="loading1">
          <p>选择要导出的数据</p>
          <div>
            <p @click="download(1)">全部导出</p>
            <p @click="download(2)">当前页导出</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
export default {
  //   name: 'Index',
  components: {},
  data() {
    return {
      loading: true,
      loading1:false,
      checkTime: [], //时间范围
      keywords: "", //搜索关键词
      isOpenDetail:false,//是否打开发票详情
      form:{
        beginDate: "",
        endDate: "",
        keywords: "",
        pageNum: 1,
        pageSize: 10,
        // customerNumber:localStorage.getItem('customerNumber')
      },
      total:0,
      info:'',
      list:[],
      xvbelnDeliver:'',

      is_dc: false,
      nowtime:''
    };
  },
  created() {
    this.nowtime = this.yest(Date.now())
    // customer/customerPage/dwdSapInvoiceSearch
    // this.$post(this.apiUrl + 'customer/customerPage/dwdSapInvoiceSearch',this.form).then((response) => {
    //   this.list = response.data
    // })
    this.getlist()
  },
  methods: {
    yest (now) {
        let year = new Date(now).getFullYear();
        let month = new Date(now).getMonth() + 1;
        let date = new Date(now).getDate() - 1;
        if (month < 10) month = "0" + month;
        if (date < 10) date = "0" + date;
        return year + "-" + month + "-" + date;
      },
    showload(){
        this.is_dc = true
      },

      hidedown(){
        this.is_dc = false
      },

    
      download (type) {
        let pnum = this.form.pageNum
        if(type == 1){
          this.form.pageSize = 20000
          this.form.pageNum = 1
        }
        this.loading1 = true
        this.$exexl(this.apiUrl + 'customer/customerPage/dwdSapInvoiceSearchExport',this.form).then((response) => {
          this.form.pageSize = 10
          if(type == 1){
            this.form.pageSize = 10
            this.form.pageNum = pnum
          }
          this.loading1 = false
          const blob = new Blob([response])
          const downloadElement = document.createElement('a')
          const href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = '发票.xlsx' // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
          this.is_dc = false
        })
      },
    getlist(){
        this.loading = true
        this.$post(this.apiUrl + 'customer/customerPage/dwdSapInvoiceSearch',this.form).then((response) => {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
        })
    },
    handleCurrentChange(val){
        this.form.pageNum = val
        this.getlist()
    },
    // 改变时间
    changeTime() {
      let checkTime = this.checkTime;
      console.log(checkTime);
    },
    //开始搜索
    beginSearch() {
      // console.log(this.keywords);
      if(this.checkTime == null){
        this.form.beginDate = ''
        this.form.endDate = ''
      }else{
        this.form.beginDate = this.checkTime[0]
        this.form.endDate = this.checkTime[1]
      }
      // this.form.beginDate = this.checkTime[0]
      // this.form.endDate = this.checkTime[1]
      this.form.keywords = this.keywords
      this.form.pageNum = 1
      this.getlist()
    },
    //打开发票详情
    openDetail(id){
      //console.log(id);
      let that = this
      this.$post(this.apiUrl + 'customer/customerPage/dwdSapInvoiceSearchDetails',{goldtaxNum:id}).then((response) => {
        that.info = response.data
      })
      this.isOpenDetail = true;
    },
    //关闭发票详情
    closeDetail(){
      this.isOpenDetail = false;
    },

    showchild(id){
      this.xvbelnDeliver = id
    },

    hidechild(){
      this.xvbelnDeliver = ''
    },

    gotoNav(id){
      console.log(id)
      let that = this;
      that.$router.push({
        path: '/DdcxDetail',
        name: 'DdcxDetail',
        query: {
          id,
        },
      },()=>{},()=>{});
    },
  },
};
</script>

<style lang="less" scoped>
.contain {
  // height: 100%;
  padding: 30px 30px 50px 30px;
  background: #f2f2f7;
}
.contain .info {
  background-color: #ffffff;
  box-shadow: 0px 3px 7px 0px rgba(21, 21, 41, 0.1);
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.x_title {
  height: 72px;
  padding: 0 16px;
  font-size: 18px;
  color: #495057;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-bottom: 1px solid #e6e6e6;
}
.x_screen {
  height: 73px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  padding: 0 16px;
  border-bottom: 1px solid #e6e6e6;
}
.x_screen .left {
  display: flex;
  align-items: center;
}

.x_screen .left .others {
  width: 120px;
  margin-left: 20px;
}

.x_screen .right {
  width: 500px;
  margin-left: 20px;
}

.x_thead {
  display: flex;
  align-items: center;
  height: 45px;
  background: #f3f6f9;
}
.x_thead .detail {
  font-size: 14px;
  color: #878a99;
  padding-left: 26px;
  display: flex;
  align-items: center;
  height: 100%;
}
.x_thead .line {
  width: 8px;
  height: 100%;
}
.f1 {
  flex: 1;
}
.f2 {
  flex: 2;
}
.f3 {
  flex: 3;
}
.f4 {
  flex: 4;
}
.x_tbody {
  flex: 1;
  overflow-y: auto;
}
.x_tbody .x_td {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
}
.x_tbody .x_td:last-of-type{
  border-bottom: 0;
}
.x_tbody .x_td .detail {
  font-size: 14px;
  color: #212529;
  display: flex;
  padding: 20px 0 20px 26px;
  align-items: center;
  min-height: 53px;
  text-align: justify;
}
.x_tbody .x_td .detail .deBtn {
  padding: 0 8px;
  height: 22px;
  background-color: #fff1e5;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ED6C00;
  cursor: pointer;
}
.x_tbody .x_td .detail .deBtn img {
  margin-right: 6px;
}
.x_bottom {
  height: 74px;
  display: flex;
  align-items: center;
  padding: 0 21px 14px;
  border-top: 1px solid #e6e6e6;
  justify-content: space-between;
}
.x_bottom .left {
  font-size: 14px;
  color: #828593;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ED6C00;
}
/deep/.el-pagination.is-background .el-pager li:hover {
  color: #ED6C00!important;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #fff!important;
}
//通用滚动条样式
::-webkit-scrollbar {
  width: 8px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: rgba(237, 109, 0, 0.3);
  cursor: pointer;
}

// 发票详情弹窗
.fpPop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 130;
}
.fpPop .popInfo{
  width: 800px;
  max-height: 500px;
  background-color: #ffffff;
	border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.fpPop .popInfo .top{
  height: 56px;
	background-color: #fae5d9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  flex-shrink: 0;
}
.fpPop .popInfo .top span{
  font-size: 14px;
  color: #495057;
}
.fpPop .popInfo .top img{
  cursor: pointer;
}
.fpPop .popInfo .bottom{
  flex: 1;
  overflow-y: auto;
  padding: 20px 30px 58px;
}
.fpPop .popInfo .bottom .one{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 10px;
}
.fpPop .popInfo .bottom .one .detail{
  width: 49%;
  display: flex;
  font-size: 14px;
  color: #495057;
  margin-top: 20px;
}
.fpPop .popInfo .bottom .one .detail.bz{
  width: 100%;
}
.fpPop .popInfo .bottom .one .detail span:first-child{
  flex-shrink: 0;
}
.fpPop .popInfo .bottom .two{
  margin-top: 40px;
}
.fpPop .popInfo .bottom .two .thead{
	background-color: #f3f6f9;
  display: flex;
}
.fpPop .popInfo .bottom .two .thead .td{
  display: flex;
  align-items: center;
  padding-left: 25px;
  font-size: 14px;
  color: #878a99;
  height: 46px;
}
.fpPop .popInfo .bottom .two .thead .line{
  width: 8px;
  height: 46px;
}
.fpPop .popInfo .bottom .two .tbody .tr{
  display: flex;
  border: 1px solid #eeeeee;
  border-top: 0;
}
.fpPop .popInfo .bottom .two .tbody .tr .td{
  font-size: 14px;
  color: #495057;
  padding: 12px 0 12px 25px;
  //text-align: justify;
}

.searchbtn{
    width: 100px;
    height: 40px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    background-color: #ED6C00;
    box-shadow: 0px 3px 5px 0px rgb(237 109 0 / 10%);
    color: #fff;
    border: 0;
    margin-left: 20px;
}

.popdc{
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.4);
  z-index: 111;

  .popdcview{
    width: 300px;
    // height: 200px;
    padding: 20px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

    &>p{
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      color: #ED6C00;
    }

    &>div{
      display: flex;
      margin-top: 20px;
      justify-content: center;

      p{
        width: 100px;
        height: 40px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 16px;
        background-color: #ED6C00;
        box-shadow: 0px 3px 5px 0px rgb(237 109 0 / 10%);
        color: #fff;
        border: 0;
      }

      p:nth-child(2){
        margin-left: 20px;
      }
    }
  }
}

.textsl{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 135px;
    border: 0;
    font-size: 14px;
    color: #212529;
    padding-left: 0;
    padding-right: 0;
    text-align: left;
}

.detail /deep/ .el-button:hover{
  color: #ED6C00;
  background-color: transparent
}
.x_thActive {
    background: #fefaf6;
    border: 1px solid #ED6C00!important;
    height: auto;
}
.deBtn1 {
    width: 50px;
    height: 22px;
    background-color: #fff1e5;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #ED6C00;
    cursor: pointer;
    margin-left: 10px;
}
</style>
