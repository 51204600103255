<template>
  <div class="contain">
    <div class="info">
      <div class="x_title">浏览记录</div>
      <div class="x_body">
        <div class="x_body_info">
          <el-timeline>
            <el-timeline-item
              v-for="(titem, tindex) in list"
              :key="tindex"
              color="#ED6C00"
              :timestamp="tindex"
              placement="top">
              <div class="detail">
                <!-- 商品 -->
                <div class="pro" @click="godetail(item.id,item.type)" v-for="(item,index) in titem" :key="index" :style="item.type == '1'?'':'display:none'">
                  <div class="left"><img :src="item.img" alt=""></div>
                  <div class="right">
                    <a href="##" class="top"><img src="../../assets/images/x_go.png" alt="">{{item.title}}</a>
                    <!-- <div class="bottom">本合金属于CuCrZr系合金，通过冷加工和沉淀强化处理获得优良的综合性能本合金属于CuCrZr系合金，通过冷加工和沉淀强化处理获得优良的综合性能本合金属于CuCrZr系合金，通过冷加工和沉淀强化处理获得优良的综合性能</div> -->
                  </div> 
                </div>
                <!-- 新闻 -->
                <div class="news" @click="godetail(item.id,item.type)" v-for="(item,index) in titem" :key="index" :style="item.type != '1'?'':'display:none'">
                  <div class="left"><img :src="item.img" alt=""></div>
                  <div class="right">
                    <div class="title">{{item.title}}</div>
                    <!-- <div class="label"><div class="laDetail">海洋工程</div></div>
                    <div class="content">近年来，能源作为战略储备物资，备受各国关注近年来</div> -->
                  </div>
                </div>
              </div>
            </el-timeline-item>
          </el-timeline>
          <div class="right1">
            <el-pagination background layout="prev, pager, next , jumper" :total="total" @current-change="handleCurrentChange">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //   name: 'Index',
  components: {},
  data() {
    return {
      list:[],
      history: [
        {
          time: '2018-04-15'
        }, {
          time: '2018-04-13'
        }, {
          time: '2018-04-11'
        },
      ],
      loading:false,//是否加载
      total:0,
      form:{
        pageNum: 1,
        pageSize: 10
      }
    };
  },
  created() {

    
    this.$post(this.apiUrl + 'customer/customerPage/webLog',this.form).then((response) => {
        this.list =  response.data.mapList
        this.total = response.data.total
    });
  },
  methods: {
    godetail(id,type){
      if(type == '1'){
        let routeData = this.$router.resolve({
          path: "/product_two",
          query:{
              id:id
          }
        });
        window.open(routeData.href, '_blank');
      }else if(type == '2'){
        let routeData = this.$router.resolve({
          path: "/solutions_xq",
          query:{
              id:id
          }
        });
        window.open(routeData.href, '_blank');
      }else if(type == '3'){
        let routeData = this.$router.resolve({
          path: "/news_date",
          query:{
              id:id,
              type:'1'
          }
        });
        window.open(routeData.href, '_blank');
      }
      
    },
    handleCurrentChange(val){
        // alert(val)
        this.form.pageNum = val
        this.$post(this.apiUrl + 'customer/customerPage/webLog',this.form).then((response) => {
          this.list =  response.data.mapList
          this.total = response.data.total
      });
    },
    //加载更多
    loadMore(){
      let that = this;
      let newList = [
        {
          time: '2018-04-10'
        }, {
          time: '2018-04-08'
        }, {
          time: '2018-04-02'
        },
      ];
      console.log(newList)
      let history = that.history;
      history = history.concat(newList);
      that.loading = true;
      setTimeout(function(){
        that.loading = false;
        that.history = history;
      },2000)
    },
  },
};
</script>

<style lang="less" scoped>
.contain {
  // height: 100%;
  padding: 30px 30px 50px 30px;
  background: #f2f2f7;
}
.contain .info {
  background-color: #ffffff;
  box-shadow: 0px 3px 7px 0px rgba(21, 21, 41, 0.1);
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.x_title {
  height: 72px;
  padding: 0 16px;
  font-size: 18px;
  color: #495057;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-bottom: 1px solid #e6e6e6;
}

//通用滚动条样式
::-webkit-scrollbar {
  width: 8px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: rgba(237, 109, 0, 0.3);
}

.x_body{
  flex: 1;
  height: 10px;
  
}
.x_body_info{
  height: 100%;
  overflow-y: auto;
  padding: 20px 40px 0 20px;
}
/deep/.el-timeline-item__timestamp{
  font-size: 16px;
  color: #495057;
  font-weight: bold;
  padding-top: 0!important;
}

// 产品
.pro{
  height: 120px;
  border: 1px solid #eee;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.pro .left{
  width: 180px;
	height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pro .left img{
  max-width: 95%;
  max-height: 95%;
}
.pro .right{
  flex: 1;
  width: 10px;
  margin-left: 30px;
}
.pro .right .top{
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #ED6C00;
  font-weight: bold;
  text-decoration: none;
}
.pro .right .top img{
  margin-right: 8px;
}
.pro .right .bottom{
  font-size: 14px;
  color: #aaa;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp:1;
  overflow: hidden;
  word-break: break-all;
  padding-right: 40px;
  margin-top: 10px;
}

//新闻
.news{
  height: 120px;
  border: 1px solid #eee;
  margin-top: 20px;
  display: flex;
}
.news .left{
  width: 180px;
	height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.news .left img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.news .right{
  flex: 1;
  width: 10px;
  margin-left: 30px;
}
.news .right .title{
  font-size: 16px;
  color: #3A3A3C;
  margin-top: 15px;
}
.news .right .label{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 7px;
}
.news .right .label .laDetail{
  padding: 0 8px;
  height: 22px;
	background-color: #ED6C00;
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
}
.news .right .content{
  font-size: 14px;
  color: #aaa;
  margin-top: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp:1;
  overflow: hidden;
  word-break: break-all;
  padding-right: 40px;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ED6C00;
}
/deep/.el-pagination.is-background .el-pager li:hover {
  color: #ED6C00!important;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #fff!important;
}

.right1{
  padding: 30px 0;
  text-align: right;
}

</style>
