<template>
    <div class="zhead" v-if="navigationList">
      <div class="zheadtop">
        <div class="zheadlang">
          <img src="../assets/images/head_lang.png" alt="">
          <p>{{lang != 'en'?'中文':'English'}}</p>
          <img src="../assets/images/head_down.png" alt="">
          <el-select v-model="lang" placeholder="" @change="changelang">
            <el-option
              v-for="item in langlist"
              :key="item.label"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <p class="zheadmypp"><router-link to="/our_brands">{{$t('home.OurBrand')}}</router-link></p>
        <div class="zheadlang" style="margin-left:auto" @click="gohzlogin">
          <img src="../assets/images/head_hz.png" alt="">
          <p>{{$t('home.PartnerLogin')}}</p>
          <!-- <img src="../assets/images/head_down.png" alt=""> -->
          <!-- <el-select v-model="loginbor" placeholder="请选择">
            <el-option
              v-for="item in loginborlist"
              :key="item.label"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select> -->
        </div>
        <div class="zheadlang" style="margin-left:25px">
          <img src="../assets/images/head_admin.png" alt="">
          <p>{{$t('home.EmployeeLogin')}}</p>
          <img src="../assets/images/head_down.png" alt="">
          <el-select v-model="loginadmin" placeholder="" @change="gourl">
            <el-option
              v-for="item in loginadminlist"
              :key="item.label + 1 "
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <!-- <div class="zheadlogin" v-if="!token1">
          <router-link to="/logingw">登录</router-link>
          <p>|</p>
          <router-link to="/logingw_re">注册</router-link>
        </div> -->
        <div class="zheadlogin" v-if="token1" @click="goout">
          {{$t('home.Logout')}}
        </div>
      </div>
      <div class="zheadmid" v-if="navigationList">
        <router-link class="zheadmida" to="/"><img src="../assets/images/zlogo.png" alt=""></router-link>
        <div><router-link to="/">{{navigationList[0].title}}</router-link></div>
        <div>
          <a @click="pro_router(navigationList[6].children[0].id,navigationList[6].children[0].isChain,navigationList[6].children[0].title,navigationList[6].children[0].link)">{{navigationList[6].title}}</a>
          <div class="zheadmidshow">
            <a class="zheadmidshowleft">
              <p>{{navigationList[6].title}}<img src="../assets/images/headright.png" alt=""></p>
              <img :src="cpimg" alt="">
            </a>
            <div class="zheadmidshowright">
              <div class="zheadmidshowright1">
                <a class="zheadmidshowrighta" @mouseenter="showcpimg(item.imgIndex)" @click="pro_router(item.id,item.isChain,item.title,item.link)" v-for="(item) in navigationList[6].children" :key="item.title">{{item.title}}</a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="navigationList">
          <router-link :to="{path:'/solutions_xq',query:{id:navigationList[7].children[0].id,type:navigationList[7].children[0].type}}">{{navigationList[7].title}}</router-link>
          <div class="zheadmidshow">
            <a class="zheadmidshowleft">
              <p>{{navigationList[7].title}}<img src="../assets/images/headright.png" alt=""></p>
              <img :src="jjimg" alt="">
            </a>
            <div class="zheadmidshowright">
              <div class="zheadmidshowright1 zheadmidshowrightso">
                <router-link @mouseenter.native="showjjimg(item.img)" :to="{path:'/solutions_xq',query:{id:item.id,type:item.type}}" v-for="(item) in navigationList[7].children" :key="item.title">{{item.title}}</router-link>
              </div>
            </div>
          </div>
        </div>
        <div>
          <!-- <a>服务与支持</a> -->
          <router-link :to="navigationList[1].children[0].link">{{navigationList[1].title}}</router-link>
          <div class="zheadmidshow">
            <div class="zheadmidshowleft">
              <p>{{navigationList[1].title}}<img src="../assets/images/headright.png" alt=""></p>
              <img :src="fwimg" alt="">
            </div>
            <div class="zheadmidshowright">
              <div class="zheadmidshowright1 zheadmidshowright1_2">
                <router-link @mouseenter.native="showfwimg(item.img)" :to="item.link" class="zheadmidshowrighta" v-for="(item) in navigationList[1].children" :key="item.title">{{item.title}}
                  <div class="zheadmidshowright1_3">
                    <h3>{{item.title}}</h3>
                    <p>{{item.cnDescribe}}</p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div>
          <!-- <a>走进博威</a> -->
          <router-link :to="navigationList[2].children[0].link">{{navigationList[2].title}}</router-link>
          <div class="zheadmidshow">
            <a class="zheadmidshowleft">
              <p>{{navigationList[2].title}}<img src="../assets/images/headright.png" alt=""></p>
              <img :src="zjimg" alt="">
            </a>
            <div class="zheadmidshowright">
              <div class="zheadmidshowright1">
                <router-link :to="item.link" @mouseenter.native="showzjimg(item.img)" class="zheadmidshowrighta" v-for="(item) in navigationList[2].children" :key="item.title">{{item.title}}</router-link>
              </div>
            </div>
          </div>
        </div>
        <div>
          <!-- <a>博威研发</a> -->
          <router-link :to="navigationList[4].children[0].link">{{navigationList[4].title}}</router-link>
          <div class="zheadmidshow">
            <a class="zheadmidshowleft">
              <p>{{navigationList[4].title}}<img src="../assets/images/headright.png" alt=""></p>
              <img :src="yfimg" alt="">
            </a>
            <div class="zheadmidshowright">
              <div class="zheadmidshowright1">
                <router-link :to="item.link" @mouseenter.native="showyfimg(item.img)" class="zheadmidshowrighta" v-for="(item) in navigationList[4].children" :key="item.title">{{item.title}}</router-link>
              </div>
            </div>
          </div>
        </div>
        <div>
          <!-- <a>媒体中心</a> -->
          <router-link :to="navigationList[3].children[0].link">{{navigationList[3].title}}</router-link>
          <div class="zheadmidshow">
            <a class="zheadmidshowleft">
              <p>{{navigationList[3].title}}<img src="../assets/images/headright.png" alt=""></p>
              <img :src="mtimg" alt="">
            </a>
            <div class="zheadmidshowright">
              <div class="zheadmidshowright1">
                <router-link :to="item.link" @mouseenter.native="showmtimg(item.img)" class="zheadmidshowrighta" v-for="(item) in navigationList[3].children" :key="item.title">{{item.title}}</router-link>
              </div>
            </div>
          </div>
        </div>
        <div style="margin:0 20px;display:flex;" class="btsearch" @click="onshowsearch">
          <img src="../assets/images/search_icon.png" alt="">
        </div>
      </div>

      <div class="pagetoppop">
        <div class="pagetop pagetopmain w100 pagetopon">
            <div class="pagetopb w100 pagetopbon" style="height: 70px">
                <div class="guanbia" style="display: block;"></div>
                <div class="h_search h_searchon">
                    <div class="">
                        <input type="text" v-model="keyword" class="h_searchtxt" :placeholder="$t('home.findnote')" @keydown.enter="saveKeyWord">
                        <i class="ikeyClick" style="float: left;margin-top: 10px;padding-left: 10px;cursor: pointer;"></i>
                    </div>
                    <div class="h_searchdesc">
                        <h4>{{$t('home.Quickfind')}}</h4>
                        <ul>
                            <li class="n1" @click="gosearurl('1','精密板带')"><a>{{$t('home.Strip')}}</a></li>
                            <li class="n2" @click="gosearurl('2','精密棒材')"><a>{{$t('home.Bar')}}</a></li>
                            <li class="n3" @click="gosearurl('3','精密线材')"><a>{{$t('home.Wire')}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>
<script>
import $ from 'jquery'
    export default {
        name: 'HomeView', 
        data(){
            return{
                keyword:'',
                lang:'en',
                langlist:[
                    {
                    value: 'cn',
                    label: '中文'
                    }, {
                    value: 'en',
                    label: 'English'
                    }
                ],
                loginbor:'1',
                loginborlist:[
                    {
                    value: '1',
                    label: '营销门户'
                    }, {
                    value: '2',
                    label: '研发生态圈'
                    }, {
                    value: '3',
                    label: '供应链生态圈'
                    }
                ],
                loginadmin:'1',
                loginadminlist:[
                    {
                      value: 'http://oa.pwholdings.cn/login/Login.jsp?logintype=1',
                      label: 'OA'
                    }, {
                      value: '',
                      label: 'SAP'
                    }, {
                      value: 'http://hr.pwholdings.cn/',
                      label: 'HR'
                    }, {
                      value: 'http://crm.pwalloy.com:8090/BowayCRM/main.aspx#704556124',
                      label: 'CRM'
                    }, {
                      value: 'https://bw-scm-1.going-link.com',
                      label: 'SRM'
                    }, {
                      value: 'http://10.60.4.200:8080/tms/index/home.shtml',
                      label: 'TMS'
                    }, {
                      value: 'https://www.bowayctw.com/',
                      label: 'CTW'
                    }, {
                      value: 'http://kms.bowayalloy.com:8080/sys/portal/page.jsp',
                      label: 'KMS'
                    }, {
                      value: 'http://10.40.3.231:8080/Modules/Blocks.LayoutModule/dist/index.html#/authentionmodule/login?redirect=%2Flayout%2Fsysmgrbussenssmodule%2Fhomepage%2Findex',
                      label: 'MES'
                    }, {
                      value: '',
                      label: 'LIMS'
                    }, {
                      value: '',
                      label: 'PLM'
                    }
                ],
                navigationList:[],
                solutionPage1:[],
                solutionPage2:[],

                product_list:'',
                token1:'',


                cpimg: '',
                jjimg:'',
                fwimg:'',
                zjimg:'',
                mtimg:'',
                yfimg:'',
                
                metaList: [
                  //SEO优化的meta数组
                  {
                    name: "description",
                    content: "123123",
                  },
                  {
                    name: "keywords",
                    content: "123123",
                  },
                  {
                    property: "release_date",
                    content: "132123",
                  },
                ],
            }
        },
        // metaInfo: {
        //   title: ``,
        //   meta: [
        //     {
        //       // set meta
        //       name: "description",
        //       content:"123",
        //     },
        //     {
        //       name: "keywords",
        //       content: "123",
        //     },
        //     {
        //       property: "release_date",
        //       content: "312",
        //     },
        //   ],
        // },

        

        created(){
          this.token1 = localStorage.getItem('token')
          this.lang = localStorage.getItem('lang')
          this.$get(this.apiUrl + 'home/homePage/navigationListNew').then((response) => {
              this.navigationList =  response.data.portalNavigationListDtos;
              this.cpimg = this.navigationList[6].img
              this.jjimg = this.navigationList[7].img
              this.fwimg = this.navigationList[1].img
              this.zjimg = this.navigationList[2].img
              this.mtimg = this.navigationList[3].img
              this.yfimg = this.navigationList[4].img
          });

        //   this.$get(this.apiUrl + 'home/solutionPage/index').then((response) => {
        //       //this.solutionPage1 =  response.data.portalOurIndustries;
        //       this.solutionPage2 =  response.data.portalApplicationAreas;
        //   });

        //   this.$get(this.apiUrl + 'home/productPage/productIndex').then((response) => {
        //     this.product_list =  response.data.portalProductFamilies;
        // });
        if(localStorage.getItem('token') == "''"||localStorage.getItem('token') == '' || localStorage.getItem('token') == null || localStorage.getItem('token') == undefined){
                 this.token1 = false
                //  alert(1)
              }else{
                this.token1 = true
              }

        },

        mounted(){
          // setTimeout(() => {
          //   this.pageName = `${this.pipiload.hname}`;
          //   this.metaList[0].content = `${this.descriptionDate}`; // description
          //   this.metaList[1].content = `${this.pipiload.hname}`; //name="keywords"
          //   this.metaList[2].content = `${this.release_date}`;
          // }, 2000);
          $('.guanbia').click(function() {
            $('.nav_bot').fadeIn(300);
            $('.guanbia').fadeOut();
            $('.pagetopb').removeClass('pagetopbon');
            $('.h_search').removeClass('h_searchon');
            $('.hsbg').removeClass('hsbgon');
            $('.pagetop').fadeOut(300);
            $('.pagetoppop').fadeOut(400);
          });
        },

        watch:{
          '$route'(val, from) {
            // if(from.path == '/login' || from.path == '/logingw'){
              // this.token1 = localStorage.getItem('token')
              //alert(localStorage.getItem('token'))
              if(localStorage.getItem('token') == "''"||localStorage.getItem('token') == '' || localStorage.getItem('token') == null || localStorage.getItem('token') == undefined){
                 this.token1 = false
                //  alert(1)
              }else{
                this.token1 = true
              }
            // }
          },
        },
        methods:{
        //   metaInfo() {
        //   return {
        //     title: this.pageName,
        //     meta: this.metaList,
        //   };
        // },

          changelang(e){
            //console.log(e)
            if(e == 'en'){
              window.location.replace('http://www.bowayalloy.com/en');
            }else{
              window.location.replace('http://www.bowayalloy.com');
            }
            //localStorage.setItem('lang',e)
            //location.reload()
          },

          
          onshowsearch(){
            $('.pagetoppop').fadeIn(200);
            $('.pagetop').fadeIn(300);
            $('.nav_bot').fadeOut(300);
            $('.guanbia').fadeIn();
            $('.pagetopb').addClass('pagetopbon');
            $('.h_search').addClass('h_searchon');
            $('.hsbg').addClass('hsbgon');
            $('.guanbia').click(function() {
              $('.nav_bot').fadeIn(300);
              $('.guanbia').fadeOut();
              $('.pagetopb').removeClass('pagetopbon');
              $('.h_search').removeClass('h_searchon');
              $('.hsbg').removeClass('hsbgon');
              $('.pagetop').fadeOut(300);
              $('.pagetoppop').fadeOut(400);
            });
          },

          gohzlogin(){
            this.$router.push({
              path:'/login_hzhb'
            })
          },

          goout(){
            this.$post(this.apiUrl + 'customer/customerPage/customerLogout').then((response) => {
              this.$router.push({
                path:'/'
              })
              this.token1 = ''
              localStorage.setItem('token','')
              $.ajax({
                type: "get",
                async: false,
                url:"https://www.bowayctw.com/o/osapp/crosSite",
                dataType: "jsonp",
                jsonp: "callback",
                jsonpCallback: "jsonpscall",//自定义的jsonp回调函数名称，请求成功后会执行后台返回的jsonpscall方法
                success: function (jsons) {
                    console.log("成功");
                },
                error: function () {
                    console.log("失败");
                }
              });
              document.cookie = "boway-cookie=";
              this.clearCookie()
            });
          },

          		//清空cookie
          clearCookie() {
            let cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
              let cookie = cookies[i];
              let eqPos = cookie.indexOf("=");
              let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
              document.cookie =
                name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
            }
            if (cookies.length > 0) {
              for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i];
                let eqPos = cookie.indexOf("=");
                let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                let domain = location.host.substr(location.host.indexOf("."));
                document.cookie =
                  name +
                  "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=" +
                  domain;
              }
            }
          },


          gourl(value){
            this.loginadmin = 'xxxx'
            if(value != ''){
              window.open(value,'_blank');
            }
          },

          pro_router(id,isChain,title,link){
            if(isChain == 1){
                // window.location.href = link
                window.open(link, '_blank');
            }else{
                this.$router.push({
                    path:'/product_one',
                    query:{
                        id:id,
                        //title:title,
                    }
                })
                
            }
       },

       gosearurl(id,title){
          $('.pagetoppop').hide();
          this.$router.push({
              path:'/product_one',
              query:{
                  id:id,
                  title:title,
              }
          })
       },

       saveKeyWord(){
        $('.pagetoppop').hide();
        let keyword = this.keyword
        this.$router.push({
              path:'/searchview',
              query:{
                  keyword:keyword,
              }
          })
       },



       showcpimg(img){
        this.cpimg = img
       },
      showjjimg(img){
        this.jjimg = img
       },

       showfwimg(img){
        this.fwimg = img
       },

       showzjimg(img){
        this.zjimg = img
       },

       showyfimg(img){
        this.yfimg = img
       },

       showmtimg(img){
        this.mtimg = img
       },

              


      },
    }
</script>

<style lang="less" scoped>
    .zhead{
      height: 90px;
      width: 100%;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 101;
      background: rgba(255,255,255);

      .zheadtop{
        width: 100%;
        background: #ED6C00;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 40px;
        z-index: 100;

        .zheadlang{
          display: flex;
          align-items: center;
          position: relative;
          cursor: pointer;
        
          &>p{
            margin-left: 10px;
            margin-right: 7px;
            color: #fff;
            font-size: 14px;
            line-height: 20px;
          }
        }

        .el-select{
          position: absolute;
          left: 0;
          top: -10px;
          opacity: 0;
        }

        .zheadmypp{
          color: #fff;
          font-size: 14px;
          margin-left: 35px;

          a{
            color: #fff;
            text-decoration: none;
          }
        }
      }
      .zheadlogin{
        margin-left: 31px;
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 14px;

        p{
          margin: 0 10px;
          color: #fff;
        }

        a{
          color: #fff;
          text-decoration: none;
        }
      }
    }

    .zhead:hover{
      background: #fff;
    }

    .zheadmid{
      height: 50px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      padding-right: 30px;
      // max-width: 1720px;
      // margin: 0 auto;
      // z-index: 100;

      a.zheadmida{
        display: block;
        position:absolute;
        left: 50px;
        top: 50%;
        transform: translate(0,-50%);
        padding: 0;
        img{
          display: block;
          height: 20px;
        }
      }

      &>div{
        //position: relative;
        &>a{
          padding: 0 36px;
          font-size: 16px;
          color: #000000;
          font-weight: normal;
          height:50px;
          display: block;
          line-height: 50px;
          text-decoration: none;
        }

        .zheadmidshow{
          // height: 300px;
          width: 100%;
          position: absolute;
          top: 49px;
          // top: 134px;
          left: 0;
          background: #f7f7f7;
          // opacity: 0;
          z-index: -1;
          transition: 0.5s;
          display: flex;
          height: 0;
          overflow: hidden;
          align-items: center;
          // display: none;

          .zheadmidshowleft{
            width: 420px;
            height: 210px;
            // background: #373737;
            border-right: 1px solid #ddd;
            padding: 20px 60px;
            display: block;
            box-sizing: border-box;
            text-decoration: none;

            &>img{
              width: 300px;
                height: 120px;
                object-fit: cover;
            }

            p{
              height: 22px;
              line-height: 22px;
              display: flex;
              color: #333;
              font-size: 16px;
              padding-bottom: 15px;
              align-items: center;
              // text-decoration: none;

              img{
                margin-left: auto;
                display: none;
              }
            }
          }

          .zheadmidshowright{
            width: calc(100vw - 420px);
            height: 270px;
            display: flex;
            align-items: center;
            .zheadmidshowright1{
              padding: 40px;
              display: flex;
              font-size: 0px;
              flex-wrap: wrap;
              // height: 100%;
              // align-items: center;

              &>a{
                font-size: 16px;
                font-weight: normal;
                line-height: 36px;
                color: #333;
                text-decoration: none;
                display: block;
                padding: 0 50px;
              }
            }

            .zheadmidshowright1 a:hover{
              color: #ED6C00;
            }

            .zheadmidshowrightso{
              align-items: center;
            }

            .zheadmidshowrightso a{
              min-width: 200px;
              padding: 0;
              text-align: left;
            }

            .zheadmidshowright1_2{
              width: 780px;
              padding-left: 80px;
              padding-bottom: 0;
              padding-top: 0;
               &>a{
                padding: 0;
                width: 220px;
                display: block;
               }
            }

            .zheadmidshowright1_2 a:hover{
                color: #ED6C00;
              .zheadmidshowright1_3{
                display: block;
              }
            }

            .zheadmidshowright1_3{
              width: calc(100vw - 1180px);
              height: 270px;
              background: #ffffff;
              position: fixed;
              top: 89px;
              right: 0;
              display: none;
              padding: 40px;

              h3{
                color: #333;
                font-size: 18px;
                line-height: 50px;
              }

              p{
                color: #999;
                font-size: 14px;
                line-height: 30px;
                font-weight: normal;
                width: 240px;
              }
            }

            .zheadmidshowright2{
              display: flex;
              padding: 40px 80px;

              .zheadmidshowright_one{
                width: 400px;

                h3{
                  font-size: 18px;
                  line-height: 50px;
                  color: #333;
                  margin-bottom: 10px;
                }

                div{
                  display: flex;
                  flex-wrap: wrap;

                  a{
                    font-size: 14px;
                    line-height: 30px;
                    color: #666666;
                    display: block;
                    width: 120px;
                    text-decoration: none;
                  }
                }
              }

              .zheadmidshowright_one1{
                margin-left: 20px;
                width: 550px;

                div{
                  a{
                    width: 130px;
                  }
                }
              }
            }
          }
        }
      }

      &>div:hover{

        &>a{
            color: #ED6C00;
        }
        .zheadmidshow{
          // opacity: 1;
          top: 49px;
          height: 270px;
          // display: block;
        }
      }

    }

  .el-select-dropdown__item.selected{
    color: #ED6C00;
    background: #fff;
    text-align: center;
  }
  .el-select-dropdown__item{
    text-align: center;
  }

  /* sousuo */
.h_search {
	width: 690px;
	margin: 0 auto;
	top: 0;
	position: absolute;
	left: 50%;
	margin-left: -345px;
	z-index: 131;
	padding-top: 15px;
	opacity: 0;
	transition: all 0.3s ease-in;
}

.h_searchtxt {
	line-height: 44px;
	font-size: 14px;
	padding: 0 45px;
	background: url(../assets/images/icon2.png) 10px center no-repeat;
	color: #fff;
}

.h_searchdesc {
	padding: 18px 10px;
	background: #fff;
	margin-top: 18px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.h_searchdesc h4 {
	font-size: 12px;
	color: #666;
	padding-left: 35px;
}

.h_searchdesc li {
	line-height: 32px;
	margin: 5px 0;
	padding: 0 45px;
}

.h_searchdesc li a {
	color: #333;
  text-decoration: none;
}

.h_searchdesc li:hover {
	background: #f2f2f2;
}

.h_searchdesc li:hover a {
	color: #222;
}

.navm {
	position: relative;
	padding: 0 11%;
}

.guanbia {
	background: url(../assets/images/guanbia.png) center no-repeat;
	background-size: cover;
	width: 20px;
	height: 20px;
	position: absolute;
	right: 50%;
	margin-right: -600px;
	top: 28px;
	z-index:666;
	display: none;
}

.h_searchtxt {
	line-height: 44px;
	font-size: 14px;
	padding: 0 45px;
	background: url(../assets/images/icon2.png) 10px center no-repeat;
	color: #fff;
}

.h_searchtxt,
.h_searchdesc li,
.h_searchdesc h4 {
	opacity: 0;
	-webkit-opacity: 0;
	transform: translateX(100px);
	transition: all 0.3s ease;
}

.h_searchdesc li {
	transform: translateX(20px);
}

.h_searchon .h_searchtxt,
.h_searchon .h_searchdesc li,
.h_searchon .h_searchdesc h4 {
	opacity: 1;
	-webkit-opacity: 1;
	transform: translateX(0);
	-webkit-transform: translateX(0);
}



.hsbg {
	background: #000;
	opacity: 0;
	-webkit-opacity: 0;
	visibility: hidden;
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100%;
	z-index: 10;
	transition: all 0.5s ease-in;
}

.h_searchtxt {
	transition-delay: 0.3s;
  outline: none;
  border: 0;
}

ol, ul {
    list-style: none;
}

.h_searchdesc {
	padding: 18px 10px;
	background: #fff;
	margin-top: 11px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.h_searchdesc li.n1 {
	transition-delay: 0.3s;
}

.h_searchdesc li.n2 {
	transition-delay: 0.4s;
}

.h_searchdesc li.n3 {
	transition-delay: 0.5s;
}

.h_searchdesc li.n4 {
	transition-delay: 0.6s;
}

.h_search {
	visibility: hidden;
}

.h_searchon {
	visibility: visible;
	opacity: 1;
}
.pagetopa{ background:#23252e; padding:4px 0;}
.pagetopa .btlauge,.pagetopa .btsearch{ margin:0; padding-bottom:0; width:auto;}
.pagetopa .btlauge span,.pagetopa .btsearch span,.vr360 a{ padding-bottom:0; margin:0;line-height:20px; height:20px;}
.pagetopa .btlauge span ,.pagetopa .btsearch  span,.vr360 a{ vertical-align:top;}
// .vr360 a{ font-size:12px; padding-left:30px;  background:url(../assets/images/vr.png) left center no-repeat; display:inline-block; background-size:22px auto; color:#fff;}
.pagetopa .btsearch em{ background-size:auto 17px;}
// .pagetopa .btlauge,.pagetopa .vr360{ padding-right:20px; margin-right:20px; background:url(../assets/images/xjta.png) right center no-repeat;}
.pagetopa .btlauge span{ top:0;}
.pagetop{ position:fixed; left:0; top:0; z-index:131;}
// .pagetopb{ background:#fff;}
.pagetopbnav{ text-align:right;}
.pagetopbnav li{ display:inline-block; font-size:0; line-height:70px;height:70px; padding:0; margin-left:40px;}
.pagetopbnav a{ color:#333; font-size:16px;}
.pagetopbnav{ width:800px;}
.pagetopblogo{ padding-top:17px;}
.pagetop{ transition:all 0.3s ease;}
.pagetopon{ top:0px; box-shadow:0 3px 10px rgba(0,0,0,0.1);}
.pagetopbon{ background:#000;}
 .pagetopbon  .w12{ opacity:0;}
.pagetopb{ position:relative;}
.pagetopbnav li>a:before,.pagetopbnav li>a:after{ opacity:0; width:0; position:absolute; content:''; bottom:0; height:3px; background:#ED6C00;}
.pagetopbnav li>a:before{ left:50%;}
.pagetopbnav li>a:after{ right:50%;}
.pagetopbnav li.on>a:before,.pagetopbnav li.on>a:after{ width:20px; opacity:1;}
.pagetopbnav li.on>a{ color:#ED6C00;}
.pagetopbnav a,.pagetopbnav li.on>a:before,.pagetopbnav li.on>a:after{transition:all 0.3s ease;}

.pagetopbnav li>a{ vertical-align:top; position:relative; display:block;}
.w100 {
    width: 100%;
}
.pagetoppop{
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.7);
  z-index: 131px;
  display: none;
}
</style>