<template>
    <div class="y_right_1" :class="lang == 'en'?'enclass':''">
      <div>
        <a @click="goconcact" class="y_right_2">
          <div class="y_right_3">
              <img src="../assets/images/icon_86.png" alt="">
          </div>
          <p>在线咨询</p>
      </a>
      <a class="y_right_2" href="https://www.bowayctw.com/web/frontend/alloyadvisor" target="_blank">
          <div class="y_right_3">
              <img src="../assets/images/icon_87.png" alt="">
          </div>
          <p>产品选型</p>
      </a>
      <div class="y_right_2 wechatimga" style="position:relative">
          <div class="y_right_3">
              <img src="../assets/images/icon_88.png" alt="">
          </div>
          <p>微信咨询</p>
          <div class="wechatimg">
            <img src="../assets/images/bowaigzh.jpg">
          </div>
      </div>
      <router-link :to="{path:'/Wdsc'}" class="y_right_2">
          <div class="y_right_3">
              <img src="../assets/images/icon_89.png" alt="">
          </div>
          <p>收藏</p>
      </router-link>
      <router-link :to="{path:'/fw_download'}" class="y_right_2">
          <div class="y_right_3">
              <img src="../assets/images/icon_90.png" alt="">
          </div>
          <p>下载</p>
      </router-link>
      </div>

      <div class="y_right_2 y_right_4">
          <img src="../assets/images/icon_91.png" alt="">
          <el-backtop></el-backtop>   
      </div>
  </div>
</template>

<!-- <script>
    import $ from 'jquery'
    $(function(){
        var $copy_bottom_right = $('.dbgs'),
        $translate = $copy_bottom_right.find('.dbgssub');
        $copy_bottom_right.hover(function() {
            $translate.stop().slideDown(500);
        }, function() {
            $translate.stop().slideUp(500);
        })
    })
</script> -->

<script>
import $ from 'jquery'


export default {
        name: 'foot', 
        data(){
            return{
                lang:'',
                id:'',
                path:''
            }
        },
        created(){
           this.lang = localStorage.getItem('lang')
           console.log(this.$route)
        },

        mounted(){
            
        },

        watch:{
            // '$route'(val, from) {//监听到路由（参数）改变
            //     console.log(val,'----------')
            //     this.id = val.query.id
            //     this.path = val.name
            // }
        },

        methods:{
            goconcact(){
                let wenzhang_id = localStorage.getItem('wenzhang_id')
                //console.log(this.$route)
                if(this.$route.name == 'product_two'){
                    this.$post(this.apiUrl + 'home/SupportPage/consultBuriedPoint',{consultType:'1',productId:this.$route.query.id,type:'8'}).then((response) => {});
                }else if(this.$route.name == 'solutions_xq'){
                    this.$post(this.apiUrl + 'home/SupportPage/consultBuriedPoint',{consultType:'2',productId:wenzhang_id,type:'8'}).then((response) => {});
                }
                window.open('https://boway.sobot.com/chat/pc/v2/index.html?sysnum=5f20b374cbe746aaba63eccb0c1959ff&channelid=2','_blank');
            },
        }

        
}
</script>

<style lang="less" scoped>
    .y_right_1{
        width: 68px;
        position: fixed;
        top: 50%;
        right: 0px;
        transform: translate(0%,-50%);
        z-index: 102;

        &>div{
            box-shadow: 0 0 10px 1px #eee;
        }
        
        
    }
    .y_right_2{
        width: 100%;
        text-decoration: none;
        height: 70px;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        background: #fff;
        border-bottom: 1px solid #f0f0f0;
        p{
            width: 100%;
            text-align: center;
            margin-top: 5px;
            font-size: 12px;
            color: #333;
        }
    }
    .y_right_3{
        width: 21px;
        height: 21px;
        img{
            width: 100%;
            object-fit: cover;
            display: inline-block;
        }
    }
    .y_right_4{
        position: relative;
        .el-backtop{
            position: absolute;
            top: 0px !important;
            left: 0px !important;
            width: 100% !important;
            height: 100% !important;
            opacity: 0 !important;
        }
    }
    .y_right_4{
        align-items: center;
        margin-top: 20px;
        box-shadow: 0 0 10px 1px #eee;
        img{
            width: 26px;
            height: 28px;
        }
    }
    .y_left_1{
        position: fixed;
        top: 50%;
        left: 40px;
        width: 140px;
        transform: translate(0%,-50%);
        z-index: 10;
        border-radius: 5px;
        border:2px solid #ED6C00;
        overflow: hidden;
        background: #fff;
    }
    .y_left_2{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 40px;
        background: #ED6C00;
        padding: 0px 8px;
        margin-top: -1px;
        .y_left_3{
            width: 20px;
            height: 18px;
            margin-right: 8px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: inline-block;
            }
        }
        h2{
            font-size: 14px;
            color: #fff;
        }
    }
    .y_left_4{
        display: flex;
        // width: 100%;
        padding: 0px 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        border-bottom: 1px solid #eee;
    }
    .y_left_5{
        display: flex;
        justify-content: flex-start;
        height: 100%;
        align-items: center;
        h2{
            font-size: 12px;
            color: #ef7b19;
            margin-right: 5px;
        }
        p{
            font-size: 12px;
            color: #333;
        }
    }
    .y_left_6{
        display: none;
    }
    .y_left_6 img{
        width: 11px;
        height: 9px;
    }
    .y_left_4_active .y_left_6{
        display: block;
    }

    
.wechatimg{
    width: 122px;
    height: 122px;
    padding: 6px;
    background: #fff;
    position: absolute;
    top: -28px;
    left: -123px;
    transition: 0.5s all;
    opacity: 0;
    pointer-events: none;


    img{
        width: 110px!important;
        height: 110px!important;
        
    }
}

.wechatimga:hover .wechatimg{
    opacity: 1;
    pointer-events:all;
}

.enclass{
    .y_right_3{
        width: 25px!important;
        height: 25px!important;
    }
    .y_right_2 p{
        display: none;
    }
}
</style>