<template>
    <div class='y_fw_sales_1' v-if="introduceCompany">
        <div class='y_fw_sales_2'>
            <img :src="introduceCompany.introduceCompany.img" alt="">
            <div class='y_fw_sales_3'>
                <h2>公司介绍</h2>
                <span></span>
            </div>
        </div>
        <div class='y_fw_sales_4'>
            <div class='y_fw_sales_8'>
                <div class='y_fw_sales_5'>
                    <div class='y_fw_sales_6'>
                        <img src="../assets/images/icon_4.png" alt="">
                    </div>
                    <div class='y_fw_sales_7'>
                        <router-link to="/">{{$t('title.home')}}</router-link>
                        <img src="../assets/images/icon_5.png" alt="">
                        <router-link to="aboutbw">{{$t('title.Aboutus')}}</router-link>
                        <img src="../assets/images/icon_5.png" alt="">
                        <a href="">{{$t('title.CompanyIntroduction')}}</a>
                    </div>
                </div>
                <div class='y_aboutbw_1'>
                    <p class='y_swiper_active'>{{$t('title.CompanyIntroduction')}}</p>
                    <p>
                        <router-link to="/aboutbw_qywh">{{$t('title.CorporateCulture')}}</router-link>
                    </p>
                    <p>
                        <router-link to="/aboutbw_rlzy">{{$t('title.HR')}}</router-link>
                    </p>
                    <p>
                        <router-link to="/social_responsibility">{{$t('title.Responsibilities')}}</router-link>
                    </p>
                </div>
            </div>
        </div>
        <div class="honor">
            <div class='y_fw_sales_12 y_aboutbw_30'>
               <div class="textBox w12" >
                  <h3 class="textH3">
                    <span>HONOR</span>
                    <p>{{introduceCompany.corporateHonor.title}}</p>
                  </h3>
                </div>
            </div>
            <div class="honorcon">
                <div class="honormidhr"></div>
                
                <div class="honorswiper">
                    <div class="swiper-button-next" slot="pagination"><img src="../assets/images/honoricon2.png"></div>
                <div class="swiper-button-prev" slot="pagination"><img src="../assets/images/honoricon1.png"></div>
                    <swiper ref="mySwiper1" :options="swiperOption_two">
                        <swiper-slide v-for="(item,index) in list" :key="index">
                            <div>
                                <div class="swipertop">
                                    <div class="topleft">
                                        <img src="../assets/images/honoricon5.png" alt="">
                                        <div></div>
                                    </div>
                                    <div class="topright">
                                        <h3>{{item.showTime}}</h3>
                                        <div>
                                            <img :src="item.img" alt="">
                                        </div>
                                        <p>{{item.title}}</p>
                                    </div>
                                </div>
                                <div class="swiperbottom">
                                    <div class="topleft">
                                        <img src="../assets/images/honoricon5.png" alt="">
                                        <div></div>
                                    </div>
                                    <div class="topright">
                                        <h3>{{item.showTime}}</h3>
                                        <div>
                                            <img :src="item.img" alt="">
                                        </div>
                                        <p>{{item.title}}</p>
                                    </div>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import $ from 'jquery'
export default {
    name: 'fw_salesView', 
    // components: {
    //       swiper,
    //       swiperSlide
    // },
    data(){
        return {
            swiperOption_two: {
                slidesPerView: 3,
                spaceBetween: 0,
	            //centeredSlides: true,
	            //loop: true,
                //initialSlide :2,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            },

            introduceCompany:'',
            type:'',
            list:''
        }
    },
    computed: {
     
    },
    created(){
        this.$get(this.apiUrl + 'home/AboutPage/introduceCompany').then((response) => {
            this.introduceCompany =  response.data
            this.list = response.data.portalCorporateHonors
        });
    },
    mounted() {
        
    },
    methods: {
        
    }

}
</script>

<style lang="less" scoped>
    a{
        text-decoration:none;
    }
    .y_fw_sales_1{
        width: 100%;
    }
    .y_fw_sales_2{
        width: 100%;
        position: relative;
        height: 675px;
    }
    .y_fw_sales_2 img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .y_fw_sales_3{
        position: absolute;
        width: 100%;
        text-align: center;
        top: 40%;
        transform: translate(0%,-50%);
    }
    .y_fw_sales_3 h2{
        font-size: 60px;
        color: #fff;
        margin-bottom: 40px;
    }
    .y_fw_sales_3 span{
        width: 50px;
        height: 2px;
        background: #fff;
        display: inline-block;
    }
    .y_fw_sales_4{
        width: 100%;
        height: 80px;
        box-shadow: 0 0 20px 1px #eee;
    }
    .y_fw_sales_8{
        display: flex;
        justify-content: space-between;
        max-width: 1460px;
        padding: 0 20px;
        width: 100%;
        height: 100%;
        margin: 0px auto;
    }
    .y_fw_sales_5{
        display: flex;
        justify-content: flex-start;
        align-content: center;
    }
    .y_fw_sales_6{
        width: 80px;
        height: 100%;
        border-left: 1px solid #eee;
        border-right: 1px solid #eee;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .y_fw_sales_7{
        display: flex;
        height: 100%;
        justify-content: flex-start;
        align-items: center;
    }
    .y_fw_sales_7 a{
        font-size: 14px;
        color: #3A3A3C;
        padding: 0px 20px;
        // display: block;
    }
    .y_fw_sales_7 img{
        width: 6px;
        height: 12px;
        display: inline-block;
    }
    .y_fw_sales_9{
        max-width: 550px;
        width: 100%;
        height: 100%;
    }
    .y_fw_sales_9 .swiper-container{
        height: 100%;
        width: 100%;
    }
    .y_fw_sales_9 .swiper-slide{
        text-align: center;
   
    }
    .y_fw_sales_9 .swiper-container a{
        line-height: 80px;
        display: inline-block;
        font-size: 14px;
        color: #3A3A3C;
        position: relative;
    }
    
    .y_swiper_active{
        color: #ED6C00 !important;
    }
    .y_swiper_active::after{
        width: 100%;
        position: absolute;
        bottom: 0px;
        height: 3px;
        background: #ED6C00;
        content: '';
        left: 0px;
    }
    .y_fw_sales_9 .swiper-button-next{
        width: 10px;
        height: 18px;
        right: 0px;
        background: none;
        margin-top: -10px;
    }
    .y_fw_sales_9 .swiper-button-prev{
        width: 10px;
        height: 18px;
        left: 0px;
        background: none;
        margin-top: -10px;
    }
    .y_aboutbw_1{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .y_aboutbw_1 p{
        line-height: 80px;
        display: inline-block;
        font-size: 14px;
        color: #3A3A3C;
        position: relative;
        margin: 0px 35px;
    }
    .y_aboutbw_1 p a{
        color: #3A3A3C;
    }
    .honor{
        padding-top: 80px;
        padding-bottom: 140px;
    }
    .honorcon{
        position: relative;
    }

    .honorswiper{
        height: 900px;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        margin-top: 100px;
        position: relative;
    }
    .swipertop{
        height: 378px;
        position: relative;
        display: flex;
        .topleft{
            padding-left: 9px;
            width: 50px;
            position: relative;
            img{
                width: 19px;
                height: 19px;
                display: block;
                position: absolute;
                left: 0px;
                top:0;
            }
            div{
                height: 100%;
                width: 1px;
                background: #CFCFCF;
            }
        }
        .topright{
            width: 270px;
            h3{
                color: #ED6C00;
                font-size: 18px;
                display: block;
                line-height: 20px;
                margin-bottom: 20px;
            }
            div{
                width: 270px;
                height: 200px;
                img{
                    max-width: 100%;
                    max-height: 200px;
                }
            }
            p{
                line-height: 30px;
                margin-top: 15px;
            }
        }
    }
    .swiperbottom{
        height: 450px;
        position: relative;
        display: flex;
        .topleft{
            padding-left: 9px;
            width: 50px;
            position: relative;
            img{
                width: 19px;
                height: 19px;
                display: block;
                position: absolute;
                left: 0px;
                top:60px;
            }
            div{
                height: 60px;
                width: 1px;
                background: #CFCFCF;
                position: absolute;
                left: 9px;
                top: 0px;
            }
        }
        .topright{
            width: 270px;
            padding-top: 60px;
            h3{
                color: #ED6C00;
                font-size: 18px;
                display: block;
                line-height: 20px;
                margin-bottom: 20px;
            }
            div{
                width: 270px;
                height: 200px;
                img{
                    max-width: 100%;
                    max-height: 200px;
                }
            }
            p{
                line-height: 30px;
                margin-top: 15px;
            }
        }
    }
    
    .honormidhr{
        height: 13px;
        width: 100%;
        position: absolute;
        top: 368px;
        background-image: url(../assets/images/honoricon6.png);
        background-repeat: repeat;
    }

    .honorswiper .swiper-slide:nth-child(2n) .swipertop{
        // display: none;
        opacity: 0;
    }   
    .honorswiper .swiper-slide:nth-child(2n+1) .swiperbottom{
        opacity: 0;
    }
    .swiper-button-next{
        background: none;
        right: -80px;
    }
    .swiper-button-prev{
        background: none;
        left: -80px;
    }

    .swiper-button-prev, .swiper-button-next{
        margin-top: -98px;
    }
</style>
