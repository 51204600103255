<template>
  <div class="contain">
    <div class="title">
      <div class="left">订单详情</div>
      <div class="right">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Ddcx' }">订单查询</el-breadcrumb-item>
          <el-breadcrumb-item>订单详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="info">
      <div class="deInfo">
        <div class="one">
          <div class="detail" :class="step == 'A' || step == 'B' || step == 'C'?'detailActive':''">
            <div class="top">
              <div class="circle">1</div>
              <div class="line"></div>
            </div>
            <div class="bottom" style="margin-left:-3px;">未发货</div>
          </div>
          <div class="detail" :class="step == 'B' || step == 'C'?'detailActive':''">
            <div class="top">
              <div class="line"></div>
              <div class="circle">2</div>
              <div class="line"></div>
            </div>
            <div class="bottom" style="text-align:center;">部分发货</div>
          </div>
          <!-- <div class="detail" :class="step == 'C'?'detailActive':''">
            <div class="top">
              <div class="line"></div>
              <div class="circle">3</div>
              <div class="line"></div>
            </div>
            <div class="bottom" style="text-align:center;">全部收货</div>
          </div> -->
          <div class="detail" :class="step == 'C'?'detailActive':''">
            <div class="top">
              <div class="line"></div>
              <div class="circle">3</div>
            </div>
            <div class="bottom" style="text-align:right;margin-right:-3px;">全部发货</div>
          </div>
        </div>
        <div class="two">
          <div class="detail" :class="tab == 1?'detailActive':''" @click="changeTab(1)">订单信息</div>
          <!-- <div class="detail" :class="tab == 2?'detailActive':''" @click="changeTab(2)">交货单</div> -->
        </div>
        <div class="three" v-show="tab == 2">本订单包含<span>{{jhinfo.total}}</span>个交货单 <button class="searchbtn" @click="download">导出</button></div>
        <div class="four">
          <!-- 订单信息 -->
          <div class="ddxx" v-show="tab == 1">
            <div class="infomation">
              <div class="detail">单号：{{info.zvbelns}}</div>
              <div class="detail">订单时间：{{info.erdatCrm}}</div>
              <div class="detail">收货人：{{info.zshlxr}}</div>
              <div class="detail">手机：{{info.zshlxdh}}</div>
              <div class="detail">收货地址：{{info.zaddj}}</div>
            </div>
            <div class="table">
              <div class="x_thead">
                <div class="detail f3"><span>产品名称</span></div>
                <div class="detail f1"><span>物料号(客户)</span></div>
                <div class="detail f1"><span>总数</span></div>
                <div class="detail f1"><span>发货数量</span></div>
                <div class="detail f1"><span>剩余数量</span></div>
                <div class="detail f1"><span>发货状态</span></div>
                <div class="detail f1"><span>开票状态</span></div>
              </div>
              <div class="x_tbody">
                <div class="x_td" v-for="(item, index) in list" :key="index">
                  <div class="detail f3"><span>{{item.arktx}}</span></div>
                  <div class="detail f1"><span>{{item.kdmat}}</span></div>
                  <div class="detail f1"><span>{{item.kwmeng}}</span></div>
                  <div class="detail f1"><span>{{item.lfimg}}</span></div>
                  <div class="detail f1"><span>{{item.lesKwmeng}}</span></div>
                  <div class="detail f1"><span><div class="deBtn">{{item.dispatchStatus}}</div></span></div>
                  <div class="detail f1"><span>{{item.invoicingStatus}}</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="jhd" v-show="tab == 2">
            <div class="botDetail" v-for="(item,index) in jhinfo.list" :key="index">
              <div class="botTitle">
                <div class="left">交货单{{index + 1}}：{{item.vbelnDeliver}}</div>
                <!-- <div class="right" @click="changeZkSq(index)">
                  <img src="../../assets/images/x_down.png" alt="" v-show="isZk != index">
                  <img src="../../assets/images/x_up.png" alt="" v-show="isZk == index">
                  {{isZk == index?'收起':'展开'}}
                </div> -->
              </div>
              <el-collapse-transition>
                <div class="botInfo">
                  <div class="wlMes">
                    <img src="../../assets/images/x_wl.png" alt="">
                    <div class="right">
                      <div class="riMes">物流单号：{{item.listDtos.length > 0 ? item.listDtos[0].deliveryOrderNumber : '暂无'}}</div>
                      <div class="riMes">物流状态：{{item.listDtos.length > 0 ? item.listDtos[0].name : '暂无'}}</div>
                    </div>
                    <div class="right">
                      <div class="riMes">发货时间：{{item.listDtos.length > 0 ? item.listDtos[0].createTime : '暂无'}}</div>
                      <div class="riMes" style="color:#fff">占位</div>
                    </div>
                  </div>
                  <div class="wlInfo">
                    <el-timeline>
                      <el-timeline-item
                        v-for="(litem, index) in item.listDtos"
                        :key="index"
                        :timestamp="litem.createTime">
                        {{litem.address}}【{{litem.name}}】
                      </el-timeline-item>
                    </el-timeline>
                  </div>
                  <div class="tips">交货单包含<span>{{item.proTotal}}</span>件产品</div>
                  <div class="table">
                    <div class="x_thead">
                      <div class="detail f1"><span>采购单号(客户)</span></div>
                      <div class="detail f3"><span>产品名称</span></div>
                      <div class="detail f1"><span>物料号(客户)</span></div>
                      <div class="detail f1"><span>总数</span></div>
                      <div class="detail f1"><span>发货数量</span></div>
                      <div class="detail f1"><span>发货状态</span></div>
                      <div class="detail f1"><span>开票状态</span></div>
                      <div class="detail f1"><span>承诺发货时间</span></div>
                    </div>
                    <div class="x_tbody">
                      <div class="x_td" v-for="(titem, index) in item.proListDtos" :key="index">
                        <div class="detail f1"><span>{{item.bstnk}}</span></div>
                        <div class="detail f3"><span>{{titem.arktx}}</span></div>
                        <div class="detail f1"><span>{{item.kdmat}}</span></div>
                        <div class="detail f1"><span>{{titem.kwmeng}}</span></div>
                        <div class="detail f1"><span>{{titem.lfimg}}</span></div>
                        <div class="detail f1"><span><div class="deBtn">{{titem.dispatchStatus}}</div></span></div>
                        <div class="detail f1"><span>{{titem.invoicingStatus}}</span></div>
                        <div class="detail f1"><span>{{titem.edatu}}</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-collapse-transition>
            </div>
          </div>
        </div>
      </div>
      <div class="tips">所有数据仅供参考，一切以双方财务对账为准</div>
    </div>
  </div>
</template>

<script>
export default {
  // name: 'Index', 
  components: {

  },
  data(){
    return {
      step:1,// 1 已下单 2 已发货 3 已收货 4 已完成
      tab:1,// 1 订单信息 2 交货单
      isZk:'x',//是否展开
      loading1 : false,
      logistics: [
        {
          content: '【郑州市】郑州转运中心已收入',
          time: '2018-09-27 14:54:12'
        }, 
        {
          content: '【郑州市】已打包',
          time: '2018-09-26 14:54:12'
        }, 
        {
          content: '已揽收',
          time: '2018-09-25 14:54:12'
        },
      ],//物流信息

      list:'',
      info:'',
      jhinfo:''
    }
  },
  created(){
    // customer/customerPage/dwsSapOrderInfo
    this.$post(this.apiUrl + 'customer/customerPage/dwsSapOrderInfo',{zvbelns:this.$route.query.id}).then((response) => {
      this.list = response.data.dwsSapOrderInfoDtos
      this.info = response.data
      this.step = response.data.dispatchStatus
    })

    this.$post(this.apiUrl + 'customer/customerPage/dwsSapDeliveryNote',{zvbelns:this.$route.query.id}).then((response) => {
      this.jhinfo = response.data
    })

  },
  methods: {
    download (type) {
        //let pnum = this.form.pageNum
        // this.form.pageSize = 20000
        // this.form.pageNum = 1
        // this.loading1 = true
        this.$exexl(this.apiUrl + 'customer/customerPage/dwsSapDeliveryNoteExport',{zvbelns: this.$route.query.id}).then((response) => {
          // this.form.pageSize = 10
          // this.loading1 = false
          const blob = new Blob([response])
          const downloadElement = document.createElement('a')
          const href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = '交货单.xlsx' // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
          // this.is_dc = false
        })
      },
    //改变Tab
    changeTab(tab){
      this.tab = tab;
    },
    //展开收起
    changeZkSq(index){
      if(index == this.isZk){
        this.isZk = 'x'
      }else{
        this.isZk = index;
      }
      
    },
  }
}
</script>

<style lang="less" scoped>
  .contain{
    display: flex;
    flex-direction: column;
    // height: 100%;
  }
  .contain .title{
    flex-shrink: 0;
    height: 50px;
    background-color: #ffffff;
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.06);
    padding: 0 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .contain .title .left{
    font-size: 18px;
    color: #495057;
    font-weight: bold;
  }
  .contain .title .right{
    font-size: 14px;
    color: #495057;
    font-weight: bold;
  }
  /deep/.el-breadcrumb__inner.is-link:hover{
    color: #ED6C00!important;
  }
  .contain .info{
    flex: 1;
    background: #f2f2f7;
    padding: 30px;
    display: flex;
    flex-direction: column;
  }
  .contain .info .deInfo{
    background-color: #ffffff;
    box-shadow: 0px 3px 7px 0px rgba(21, 21, 41, 0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }
  .contain .info .deInfo .one{
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contain .info .deInfo .one .detail .top{
    display: flex;
    align-items: center;
  }
  .contain .info .deInfo .one .detail .top .circle{
    width: 40px;
    height: 40px;
    background: #dbdbdb;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #fff;
    border-radius: 50%;
  }
  .contain .info .deInfo .one .detail .top .line{
    width: 145px;
    height: 1px;
    background: #dbdbdb;
  }
  .contain .info .deInfo .one .detail .bottom{
    font-size: 16px;
    color: #999;
    margin: 10px 0 0 0;
    font-weight: bold;
  }
  .contain .info .deInfo .one .detailActive .top .circle{
    background: #ED6C00;
  }
  .contain .info .deInfo .one .detailActive .top .line{
    background: #ED6C00;
  }
  .contain .info .deInfo .one .detailActive .bottom{
    color: #ED6C00;
  }
  .contain .info .deInfo .two{
    height: 52px;
	  background-color: #fefaf6;
    display: flex;
    padding: 0 7px;
  }
  .contain .info .deInfo .two .detail{
    width: 120px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #fefaf6;
    font-size: 14px;
    color: #636366;
    cursor: pointer;
  }
  .contain .info .deInfo .two .detail:hover{
    color: #ED6C00;
  }
  .contain .info .deInfo .two .detailActive{
    color: #ED6C00;
    border-bottom: 1px solid #ED6C00;
  }

  //订单信息
  .ddxx{
    padding: 0 40px 40px;
  }
  .ddxx .infomation{
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 45px;
  }
  .ddxx .infomation .detail{
    width: 50%;
    margin-top: 25px;
    padding-right: 30px;
    font-size: 14px;
    color: #495057;
    //display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp:1;
    // overflow: hidden;
    // word-break: break-all;
  }
  //交货单
  .three{
    padding: 0 30px;
    font-size: 16px;
    color: #495057;
    height: 72px;
    display: flex;
    align-items: center;
  }
  .three span{
    color: #ED6C00;
  }
  .jhd .botDetail .botTitle{
    height: 45px;
	  background-color: #fff1e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    border-bottom: 1px solid #fce7d5;
  }
  .jhd .botDetail .botTitle .left{
    font-size: 14px;
    color: #ED6C00;
    font-weight: bold;
  }
  .jhd .botDetail .botTitle .right{
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #ED6C00;
    cursor: pointer;
  }
  .jhd .botDetail .botTitle .right img{
    margin-right: 7px;
  }
  .jhd .botDetail .botInfo{
    padding: 0 30px;
  }
  .jhd .botDetail .botInfo .wlMes{
    display: flex;
    align-items: center;
    padding: 32px 0 28px;
    border-bottom: 1px dashed #eee;
  }
  .jhd .botDetail .botInfo .wlMes .right{
    margin-left: 30px;
  }
  .jhd .botDetail .botInfo .wlMes .right .riMes{
    width: 390px;
    font-size: 14px;
    color: #495057;
    line-height: 24px;
  }
  .jhd .botDetail .botInfo .wlInfo{
    padding: 30px 0;
  }
  /deep/.el-timeline-item{
    min-height: 70px;
    box-sizing: border-box;
  }
  /deep/.el-timeline-item:first-of-type .el-timeline-item__node--normal{
    background: #ED6C00;
  }
  .jhd .botDetail .botInfo .tips{
    font-size: 16px;
    color: #495057;
  }
  .jhd .botDetail .botInfo .tips span{
    color: #ED6C00;
  }
  .jhd .botDetail .botInfo .table{
    margin-top: 40px;
  }
  .contain .info .tips{
    font-size: 14px;
    color: #828593;
    margin-top: 30px;
  }
  .x_thead {
    display: flex;
    align-items: center;
    height: 45px;
    background: #f3f6f9;
  }
  .x_thead .detail {
    font-size: 14px;
    color: #878a99;
    padding-left: 26px;
    display: flex;
    align-items: center;
    height: 100%;
  }
  .x_thead .line {
    width: 8px;
    height: 100%;
  }
  .f1 {
    flex: 1;
  }
  .f2 {
    flex: 2;
  }
  .f3 {
    flex: 3;
  }
  .f4 {
    flex: 4;
  }
  .x_tbody {
    flex: 1;
    overflow-y: auto;
  }
  .x_tbody .x_td {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
  }
  .x_tbody .x_td .detail {
    font-size: 14px;
    color: #212529;
    padding: 20px 0 20px 26px;
    display: flex;
    align-items: center;
    min-height: 53px;
    text-align: justify;
  }
  .x_tbody .x_td .detail .deBtn {
    width: 60px;
    height: 22px;
    background-color: #fff1e5;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #ED6C00;
    cursor: pointer;
  }
  //通用滚动条样式
  ::-webkit-scrollbar {
    width: 8px;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background: rgba(237, 109, 0, 0.3);
  }

  // 高度为整屏
  // .contain .info .deInfo{
  //   flex: 1;
  //   height: 10px;
  // }
  // .contain .info .deInfo .four{
  //   flex: 1;
  //   overflow-y: auto;
  // }
  .searchbtn{
    width: 100px;
    height: 40px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    background-color: #ED6C00;
    box-shadow: 0px 3px 5px 0px rgb(237 109 0 / 10%);
    color: #fff;
    border: 0;
    margin-left: auto;
}
</style>
