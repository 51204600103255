<template>
  <div id="app" :class="lang == 'en' ? 'enclass' : ''">
    <zhead
      ref="headerc"
      :style="
        isdown
          ? 'box-shadow: 0 3px 10px rgb(0 0 0 / 10%)!important;'
          : 'box-shadow: 0 3px 10px rgb(0 0 0 / 10%)!important;'
      "
    />

    <router-view />

    <zfoot v-if="is_foot" />

    <zright v-if="isdown" />
  </div>
</template>
<script>
import zfoot from "@/components/foot";
import zhead from "@/components/head";
import zright from "@/components/right";
export default {
  name: "app",
  components: {
    zfoot,
    zhead,
    zright,
  },

  data() {
    return {
      is_foot: true,
      isdown: false,
      lang: "",
      setting: {
        title: "博威合金",
        keywords: "博威合金",
        description: "博威合金",
      },
    };
  },

  watch: {
    $route(newValue) {
      // console.log(newValue.path)
      // alert(newValue.query.id)
      //console.log(newValue)
      //alert(newValue.path)
      let type = "";
      switch (newValue.path) {
        case "/":
          this.$post(this.apiUrl + "home/SupportPage/getSeoList", {
            tyep: "1",
          }).then((response) => {
            this.setting.title = response.data.title;
            this.setting.keywords = response.data.keywords;
            this.setting.description = response.data.description;
          });
          break;
        case "/product_two":
          this.$post(this.apiUrl + "home/SupportPage/getSeoList", {
            tyep: "2",
            detailId: newValue.query.id,
          }).then((response) => {
            this.setting.title = response.data.title;
            this.setting.keywords = response.data.keywords;
            this.setting.description = response.data.description;
          });
          break;
        case "/solutions_xq":
          if (newValue.query.wzid) {
            this.$post(this.apiUrl + "home/SupportPage/getSeoList", {
              tyep: "3",
              detailId: newValue.query.wzid,
            }).then((response) => {
              this.setting.title = response.data.title;
              this.setting.keywords = response.data.keywords;
              this.setting.description = response.data.description;
            });
          } else {
            this.$post(this.apiUrl + "home/solutionPage/details", {
              id: newValue.query.id,
            }).then((response1) => {
              let wzid = response1.data.portalIndustryArticles[0].id;
              this.$post(this.apiUrl + "home/SupportPage/getSeoList", {
                tyep: "3",
                detailId: wzid,
              }).then((response) => {
                this.setting.title = response.data.title;
                this.setting.keywords = response.data.keywords;
                this.setting.description = response.data.description;
              });
            });
          }
          break;
        case "/aboutbw":
          this.$post(this.apiUrl + "home/SupportPage/getSeoList", {
            tyep: "4",
          }).then((response) => {
            this.setting.title = response.data.title;
            this.setting.keywords = response.data.keywords;
            this.setting.description = response.data.description;
          });
          break;
        case "/aboutbw_qywh":
          this.$post(this.apiUrl + "home/SupportPage/getSeoList", {
            tyep: "5",
          }).then((response) => {
            this.setting.title = response.data.title;
            this.setting.keywords = response.data.keywords;
            this.setting.description = response.data.description;
          });
          break;
        case "/aboutbw_rlzy":
          this.$post(this.apiUrl + "home/SupportPage/getSeoList", {
            tyep: "6",
          }).then((response) => {
            this.setting.title = response.data.title;
            this.setting.keywords = response.data.keywords;
            this.setting.description = response.data.description;
          });
          break;
        case "/social_responsibility":
          this.$post(this.apiUrl + "home/SupportPage/getSeoList", {
            tyep: "7",
          }).then((response) => {
            this.setting.title = response.data.title;
            this.setting.keywords = response.data.keywords;
            this.setting.description = response.data.description;
          });
          break;
        case "/bw_rd":
          this.$post(this.apiUrl + "home/SupportPage/getSeoList", {
            tyep: "8",
          }).then((response) => {
            this.setting.title = response.data.title;
            this.setting.keywords = response.data.keywords;
            this.setting.description = response.data.description;
          });
          break;
        case "/news_date":
          this.$post(this.apiUrl + "home/SupportPage/getSeoList", {
            tyep: "9",
          }).then((response) => {
            this.setting.title = response.data.title;
            this.setting.keywords = response.data.keywords;
            this.setting.description = response.data.description;
          });
          break;
      }

      if (newValue.path == "/login" || newValue.path == "/registration") {
        this.is_foot = false;
      } else {
        this.is_foot = true;
      }
      //newValue.meta.routerParent
      if (
        newValue.path == "/" ||
        newValue.path == "/indexadmin" ||
        newValue.path == "/login" ||
        newValue.path == "/logingw_re" ||
        newValue.path == "/logingw" ||
        newValue.path == "/registration" ||
        newValue.path == "/forgotten_password" ||
        newValue.path == "/aboutbw_qywh" ||
        newValue.path == "/indexadmin"
      ) {
        this.is_foot = false;
      } else {
        this.is_foot = true;
      }
      if (newValue.matched[0].path == "/indexadmin") {
        this.is_foot = false;
      }
    },
  },

  metaInfo() {
    return {
      title: this.setting.title,
      meta: [
        { name: "keywords", content: this.setting.keywords },
        { name: "description", content: this.setting.description },
      ],
    };
  },
  computed: {},

  created() {
    setTimeout(() => {
      if (this._isMobile()) {
        console.log(this.$route.query);

        if (this.$route.path == "/") {
          window.location.replace("http://m.bowayalloy.com");
          return;
        }

        if (
          this.$route.path == "Ddcx-detail" ||
          this.$route.path == "/Bhkccx" ||
          this.$route.path == "/Ddcx" ||
          this.$route.path == "/Fpcx" ||
          this.$route.path == "/Gylgl" ||
          this.$route.path == "/Hzcpmlcx" ||
          this.$route.path == "/Index" ||
          this.$route.path == "/Lljl" ||
          this.$route.path == "/onlinefeed" ||
          this.$route.path == "/Shfw" ||
          this.$route.path == "/Wdsc" ||
          this.$route.path == "/Xzzx" ||
          this.$route.path == "/Yfstmh" ||
          this.$route.path == "/Zdfw" ||
          this.$route.path == "/Zhgl"
        ) {
          window.location.replace(
            "http://m.bowayalloy.com/#/pages/admin" + this.$route.path
          );
        } else {
          if (this.$route.query.id && this.$route.query.type) {
            window.location.replace(
              "http://m.bowayalloy.com/#/pages" +
                this.$route.path +
                "?id=" +
                this.$route.query.id +
                "&type=" +
                this.$route.query.type
            );
            return;
          } else if (this.$route.query.id) {
            window.location.replace(
              "http://m.bowayalloy.com/#/pages" +
                this.$route.path +
                "?id=" +
                this.$route.query.id
            );
            return;
          } else if (this.$route.query.type) {
            window.location.replace(
              "http://m.bowayalloy.com/#/pages" +
                this.$route.path +
                "?type=" +
                this.$route.query.type
            );
            return;
          }
          window.location.replace(
            "http://m.bowayalloy.com/#/pages" + this.$route.path
          );
        }
      } else {
        console.log(this.$route.query);
      }
    }, 200);
    this.Init();
    //this.lang = localStorage.getItem('lang')
    localStorage.setItem("lang", "cn");
    this.lang = "cn";
    //document.cookie = "boway-cookiex=123123123";
    if (
      this.$route.path == "/" ||
      this.$route.path == "/logingw" ||
      this.$route.path == "/logingw_re" ||
      this.$route.path == "/login" ||
      this.$route.path == "/registration" ||
      this.$route.path == "/forgotten_password" ||
      this.$route.path == "/aboutbw_qywh" ||
      this.$route.path == "/indexadmin"
    ) {
      this.is_foot = false;
    } else {
      this.is_foot = true;
    }

    // console.log(this.getCookie('boway-cookie'))
    if (this.getCookie("boway-cookie")) {
      localStorage.setItem("token", this.getCookie("boway-cookie"));
    }
    this.$post(this.apiUrl + "home/SupportPage/getSeoList", { tyep: "1" }).then(
      (response) => {
        this.setting.title = response.data.title;
        this.setting.keywords = response.data.keywords;
        this.setting.description = response.data.description;
      }
    );
    //this.getCookie('boway-cookie');
  },

  mounted() {
    console.log(this._isMobile());
    // setTimeout(() => {
    //   this.pageName = `${this.pipiload.hname}`;
    //   this.metaList[0].content = `${this.descriptionDate}`; // description
    //   this.metaList[1].content = `${this.pipiload.hname}`; //name="keywords"
    //   this.metaList[2].content = `${this.release_date}`;
    // }, 2000);

    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

    Init() {
      // this.$post(this.apiUrl + 'home/SupportPage/getSeoList',{tyep:'1'}).then((response) => {
      //     this.setting.title = response.data.title;
      //     this.setting.keywords = response.data.keywords;
      //     this.setting.description = response.data.description;
      // });
      // 模拟接口获取数据
    },
    // mouseWheel (e) {
    //   if (e.wheelDelta || e.detail) {
    //     if (e.wheelDelta > 0 || e.detail < 0) {     //当鼠标滚轮向上滚动时
    //       //console.log("向上")
    //       this.$refs.headerc.$el.style.cssText =
    //             'background-color:#000;!important';
    //     }
    //     if (e.wheelDelta < 0 || e.detail > 0) {     //当鼠标滚轮向下滚动时
    //       //console.log("向下")
    //     }
    //   }
    // },
    getCookie(cookieName) {
      let strCookie = document.cookie;
      let cookieList = strCookie.split("; ");
      for (let i = 0; i < cookieList.length; i++) {
        const arr = cookieList[i].split("=");
        if (cookieName === arr[0]) {
          console.log(arr[1]);
          return arr[1];
        }
      }
      return null;
      //  this.$get(this.apiUrl + 'thirdParty/getcookie').then((response) => {
      //     console.log(response);
      // });
    },

    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 50) {
        this.isdown = true;
      } else {
        this.isdown = false;
      }
    },
  },
};
</script>

<style lang="less">
@import "../node_modules/animate.css/animate.min.css";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

* {
  margin: 0;
  padding: 0;
  font-family: "SourceHanSansCN-Normal";
}

h2,
h3,
p {
  color: #636366;
}

a {
  color: #333;
  cursor: pointer;
}

input {
  outline: none;
}

div {
  box-sizing: border-box;
}

.y_fw_sales_1 {
  padding-top: 90px !important;
}

.y_swiper_active a {
  color: #ed6d00 !important;
}

.swiper-button-prev {
  outline: none;
}

.swiper-button-next {
  outline: none;
}

input {
  outline: none;
}

button {
  outline: none;
}

.changelink {
  transition: 1s all;
  border: 1px solid #ed6d00 !important;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  box-sizing: content-box;
  display: inline-block;
  color: #fff !important;
  border-radius: 5px;
  background: #ed6d00;
  width: 140px;
}

.changelink:hover {
  background: #fff !important;
  color: #3a3a3c !important;
  border-color: #ed6d00 !important;
}

.y_fw_sales_2 {
  width: 100%;
  position: relative;
  height: calc(458 / 1920 * 100vw) !important;
}

.y_fw_sales_3 {
  top: 50% !important;
}

.y_fw_sales_12 h3 {
  font-size: 24px !important;
}

.y_fw_sales_3 {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

@media screen and (max-width: 1500px) {
  .y_fw_sales_12 h2 {
    font-size: 32px !important;
  }
}

.w12 {
  width: 1200px;
  margin: auto;
}
.textH3 {
  text-align: center;
  margin-bottom: 35px;
  font-size: 26px;
}
.textH3 span {
  font-family: "DINCond-Medium";
  color: #ededed;
  text-transform: uppercase;
}
.textH3 p {
  line-height: 33px;
  margin-top: -22px;
  color: #ed6c00;
  font-weight: bold;
}

ol,
ul {
  list-style: none;
}

.y_fw_sales_8 {
  max-width: 1240px !important;
}

.el-select-dropdown__item.selected {
  color: #ed6c00 !important;
}

.enclass {
  .textH3 {
    span {
      display: none !important;
    }
    p {
      font-size: 26px !important;
      margin-top: 0 !important;
    }
  }

  .y_aboutbw_1 {
    display: none !important;
  }

  .y_fw_sales_9 {
    display: none !important;
  }
}

.enclass /deep/ .y_fw_sales_9 {
  display: none !important;
}

.text-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.y_news_date_5 .ql-align-center {
  text-align: center;
}
</style>
