<template>
  <div class="contain">
    <div class="info">
      <div class="x_title">
        <p style="line-height: 36px">下载中心</p>
        <div class="detail"  :class="form.type == '1' ? 'detailActive':''" @click="typechange(1)">产品手册</div>
        <div class="detail"  :class="form.type == '2' ? 'detailActive':''" @click="typechange(2)">质量证书</div>
        <!-- <div class="detail"  :class="form.type == '3' ? 'detailActive':''" @click="typechange(3)">白皮书</div>
        <div class="detail"  :class="form.type == '4' ? 'detailActive':''" @click="typechange(4)">环保证书</div> -->
        <div class="detail"  :class="form.type == '5' ? 'detailActive':''" @click="typechange(5)">物性表</div>
        <div class="two" v-if="form.type == 5">
          <p style="line-height: 36px;width: 72px;"></p>
          <div class="detail" v-for="(item,index) in phylist" :key="index"  :class="form1.id == item.id ? 'detailActive':''" @click="typechangephy(item.id)">{{item.title}}</div>
        </div>
      </div>
      <div class="x_list" v-loading="loading">
          <div class="detail" v-for="(item,index) in list" :key="index">
            <div class="left"><img src="../../assets/images/x_icon10.png" alt="">{{item.title}}</div>
            <a :href="item.fileUrl" class="right"><img src="../../assets/images/x_download.png" alt=""></a>
          </div>
      </div>
      <div class="x_bottom">
        <div class="left"></div>
        <div class="right">
          <el-pagination background layout="prev, pager, next , jumper" :page-size="form.pageSize" :total="total" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //   name: 'Index',
  components: {},
  data() {
    return {
      loading: true,
      form:{
          pageSize: 24,
          pageNum : 1,
          type : '1',
          // customerNumber:localStorage.getItem('customerNumber')
      },
      form1:{
          pageSize: 24,
          pageNum : 1,
          id : '',
      },
      list:'',
      total: 0
    };
  },
  created() {
    // customer/customerPage/userDownloadCenter
    this.$post(this.apiUrl + 'customer/customerPage/userDownloadClass',{id:1}).then((response) => {
        this.getlist()
    })
    this.$post(this.apiUrl + 'customer/customerPage/downloadCenterPhysicalList').then((response) => {
            // this.getlist()
            this.phylist = response.data
            this.form1.id = response.data[0].id
        })
    
  },
  methods: {
    getlist(){
        this.loading = true
        this.$post(this.apiUrl + 'customer/customerPage/userDownloadCenter',this.form).then((response) => {
            this.total = response.data.total
            this.list  = response.data.records
            this.loading = false
        })
    },
    typechange(type){
        this.list = []
        this.form.type = type
        this.form.pageNum = 1
        this.form1.pageNum = 1
        if(type == 5){
            this.getlistphy()
        }else{
            this.getlist()
        }
    },

    handleCurrentChange(val){
        // alert(val)
        if(this.form.type == 5){
          this.form1.pageNum = val
            this.getlistphy()
        }else{
          this.form.pageNum = val
            this.getlist()
            
        }
    },

    typechangephy(type){
        this.form1.id = type
        this.form1.pageNum = 1
        this.getlistphy()
    },

    getlistphy(){
        this.list = []
        this.$post(this.apiUrl + 'customer/customerPage/downloadCenterPhysicalDetails',this.form1).then((response) => {
            this.total = response.data.total
            this.list  = response.data.records
            //this.portalOtherBanner = response.data.portalOtherBanner
        })
    },
  },
};
</script>

<style lang="less" scoped>
.contain {
  min-height: 100%;
  padding: 30px 30px 50px 30px;
  background: #f2f2f7;
}
.contain .info {
  background-color: #ffffff;
  box-shadow: 0px 3px 7px 0px rgba(21, 21, 41, 0.1);
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.x_title {
  min-height: 72px;
  padding: 18px 16px;
  font-size: 18px;
  color: #495057;
  display: flex;
  flex-wrap: wrap;
  line-height: 36px;
  // align-items: center;
  font-weight: bold;
  border-bottom: 1px solid #e6e6e6;

  .two{
    width: 100%;
    display: flex;
    margin-top: 20px;

    &>p{
      font-size: 18px;
    }
  }
}

.x_title .detail {
  width: 100px;
  height: 36px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #495057;
  cursor: pointer;
  margin-left: 10px;
}
.x_title .detail:first-of-type {
  margin-left: 39px;
}
.x_title .detailActive {
  background: #ED6C00;
  box-shadow: 0px 3px 5px 0px rgba(237, 109, 0, 0.1);
  color: #fff;
}
.x_list{
  flex: 1;
  overflow-y: auto;
  display: flex;
  padding: 20px 0 20px 20px;
  flex-wrap: wrap;
  align-content:flex-start;
}

.x_list .detail{
  width: 24%;
	height: 80px;
	border: 1px dotted #dddddd;
  display: flex;
  align-items: center;
  margin: 0 1% 18px 0;
  padding: 0 18px;
}
.x_list .detail .left{
  flex: 1;
  margin-right: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #212529;
}
.x_list .detail .left img{
  margin-right: 22px;
}
.x_list .detail .right{
  cursor: pointer;
}

.x_bottom {
  height: 74px;
  display: flex;
  align-items: center;
  padding: 0 21px 14px;
  border-top: 1px solid #e6e6e6;
  justify-content: space-between;
}
.x_bottom .left {
  font-size: 14px;
  color: #828593;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ED6C00;
}
/deep/.el-pagination.is-background .el-pager li:hover {
  color: #ED6C00!important;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #fff!important;
}
//通用滚动条样式
::-webkit-scrollbar {
  width: 8px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: rgba(237, 109, 0, 0.3);
}


</style>
