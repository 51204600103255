<template>
  <div class="home">
    <!-- <zhead /> -->
    <div class="index" ref="videoElement111" :class="lang == 'en'?'enclass':''">
      <div class="y_video_1" :class="video_val != '1'?'':'y_video_1_active'">
            <div class="y_video_2">
                <div class="y_video_3">
                    <img src="../assets/images/icon_61.png" alt="" @click="video_hide()">
                </div>
                <div class="y_video_4" v-if="homePage.indexVideoDetails">
                    <video ref="myVideo" :src="homePage.indexVideoDetails.fileUrl" loop controls></video>
                </div>
            </div>
        </div>

        <div class="hbimg" v-if="ishbimg" @click="showhbimg">
          <img :src="xcimg" alt="">
        </div>
      <!-- <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide style="position:relative"> -->
          <div class="indexsw">
            <swiper :options="swiperOption1" ref="mySwiper">
              <swiper-slide v-for="(item,index) in bannerlist" :key="index">
                <img :src="item.img" style="width:100%;height:100%;object:cover" alt="" @click="linkalert(item.link)">
                <!-- <div class="swipernote">
                  <h3>{{item.title}}</h3>
                  <p>{{item.titleTwo}}</p>
                </div> -->
                <div class="swipervideo" v-if="item.video != null">
                  <!-- <video :src="item.video" muted loop autoplay></video> -->
                  <video ref="videoElement" poster="https://bowaymenhu.oss-accelerate.aliyuncs.com/videos/cn/c4ecb05e3352466e9aac85d9602797cb/c4ecb05e3352466e9aac85d9602797cb.jpg" class="videoElement" autoplay muted loop></video>
                  <!-- <video-player ref="videoPlayer" 
class="videoplayer"  :options="options">
</video-player> -->
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <!-- <div class="swiperpag" v-if="bannerlist.length > 1">
            <p :class="activeIndex1 + 1 == item ? 'active':''" v-for="(item,index) in bannerlist.length" :key="index">0{{item}}</p>
          </div> -->

          <!-- <img src="../assets/images/shubiao.png" class="shubiao" alt=""> -->
          <!-- <div class="shubiao1"></div> -->
        <!-- </swiper-slide>
        <swiper-slide> -->
          <div class="index_industry">
            <div class="industry" swiper-animate-effect="bounceInLeft" swiper-animate-duration="0.5s" swiper-animate-delay="0s">
              <!-- <div class="hr"></div>
              <p class="industryp1">OUR INDUSTRY</p>
              <h3 class="industryp2">{{homePage.ourIndustry.title}}</h3> -->
              <div class="textBox w12">
                <h3 class="textH3">
                  <span>OUR INDUSTRY</span>
                  <p>{{homePage.ourIndustry.title}}</p>
                </h3>
              </div>
              <div class="industrylist">
                <a @click="pro_router(item.isChain,item.id,item.link)" class="industryshow" v-for="(item,index) in ourIndustryList" :key="index">
                  <img :src="item.img" class="industryshowimg" alt="">
                  <div class="industrynote">
                    <div class="industrynoteh">
                      <div class="industrynoteh1">
                        <img :src="item.logo" alt="">
                      </div>
                      <h3>{{item.title}}</h3>
                    </div>
                    <div style="position:relative;" :class="zklist[index].type ? 'zkview':''">
                      <p>{{item.cnDescribe}}</p>
                      <p class="zkbtn" :class="zklist[index].type ? '':'zkbtn1'" @click.stop='changezk(index)'>{{zklist[index].type ? $t('home.open'):$t('home.stow')}}</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        <!-- </swiper-slide>
        <swiper-slide v-if="homePage.index"> -->
          <!-- <div class="index_video" @click="video_show">
            <video :src="homePage.index.fileUrl" muted loop autoplay></video>
            <div class="index_videopop">
              <img src="../assets/images/index_videoplay.png" alt="">
            </div>
          </div> -->
        <!-- </swiper-slide>
        <swiper-slide> -->
          <div class="index_applications">
            <div class="index_applicationscon">
              <!-- <div class="hr"></div>
              <p class="industryp1">APPLICATIONS</p>
              <h3 class="industryp2">{{homePage.productApplication.title}}</h3> -->
              <div class="textBox w12">
                  <h3 class="textH3">
                    <span>APPLICATIONS</span>
                    <p>{{homePage.productApplication.title}}</p>
                  </h3>
                </div>
              <div class="applicashowall">
                <swiper :options="swiperOptioncp" ref="mySwiper">
                  <swiper-slide v-for="(item,index) in applicationAreaList" :key="index">
                      <div class="applicashow">
                        <img :src="item.img" alt="">
                        <div class="applicashowtitle">
                          <p>{{item.title}}</p>
                          <img src="../assets/images/applicaright.png" alt="">
                        </div>
                        <div class="applicashownote" @click="showhbimg(item.logo)">
                          <h3>{{item.title}}</h3>
                          <div class="cpxqsc">
                            <p>{{item.cnDescribe}}</p>
                          </div>
                          <img src="../assets/images/applicaright.png" style="margin-top: 26px;" alt="">
                        </div>
                      </div>
                  </swiper-slide>
                </swiper>
                <div class="swiper-button-next swiper-button-next2" slot="pagination"><img src="../assets/images/rightbtn.png"></div>
                <div class="swiper-button-prev swiper-button-prev2" slot="pagination"><img src="../assets/images/leftbtn.png"></div>
              </div>
            </div>
          </div>
        <!-- </swiper-slide>
        <swiper-slide> -->
          <div class="index_applications index_metal">
            <div class="index_applicationscon">
              <div class="index_metaltop">
                <div class="index_metaltopleft">
                  <div class="hr" style="margin-bottom: 40px"></div>
                  <!-- <p class="industryp1">METAL PRICE</p>
                  <h3 class="industryp2">{{homePage.metalPrice.title}}</h3> -->
                  <div class="textBox w12" style="width:100%">
                    <h3 class="textH3">
                      <span style="color: #ededed;opacity: 0.4;margin-left: -36px;">METAL PRICE</span>
                      <p style="text-align:left;color:#fff;margin-top: -14px;">{{homePage.metalPrice.title}}</p>
                    </h3>
                  </div>
                </div>
                <div class="index_metaltopright">
                  <div class="index_metaltopright1" style="border-right:1px solid rgba(255,255,255,0.6);" v-if="pricelist.cjxh.length>0">
                    <h3>{{$t('home.Fromccmn')}}：</h3>
                    <p><span>1#{{$t('home.Cu')}}</span>{{pricelist.cjxh[0].p1}}{{$t('home.RMBton')}}</p>
                    <p><span>0#{{$t('home.Zn')}} </span>{{pricelist.cjxh[0].p2}} {{$t('home.RMBton')}}</p>
                    <p><span>1#{{$t('home.Ni')}}</span>{{pricelist.cjxh[0].p3}} {{$t('home.RMBton')}}</p>
                    <p><span>1#{{$t('home.Sn')}}</span>{{pricelist.cjxh[0].p4}} {{$t('home.RMBton')}}</p>
                    <p><span>1#{{$t('home.MnMn')}}</span>{{pricelist.cjxh[0].p5}} {{$t('home.RMBton')}}</p>
                  </div> 
                  <div class="index_metaltopright1" style="border-right:1px solid rgba(255,255,255,0.6);" v-if="pricelist.cjxh.length==0">
                    <h3>{{$t('home.Fromccmn')}}：</h3>
                    <p><span>{{$t('home.Notupdated')}}</span>0.00 {{$t('home.RMBton')}}</p>
                    <p><span>{{$t('home.Notupdated')}}</span>0.00 {{$t('home.RMBton')}}</p>
                    <p><span>{{$t('home.Notupdated')}}</span>0.00 {{$t('home.RMBton')}}</p>
                    <p><span>{{$t('home.Notupdated')}}</span>0.00 {{$t('home.RMBton')}}</p>
                    <p><span>{{$t('home.Notupdated')}}</span>0.00 {{$t('home.RMBton')}}</p>
                  </div>
                  <div class="index_metaltopright1" v-if="pricelist.lme.length>0">
                    <h3>LME：</h3>
                    <p><span>{{$t('home.London')}} {{$t('home.Cu')}}</span>{{pricelist.lme[0].p1}}{{$t('home.USDton')}}</p>
                    <p><span>{{$t('home.London')}} {{$t('home.Zn')}}</span>{{pricelist.lme[0].p2}} {{$t('home.USDton')}}</p>
                    <p><span>{{$t('home.London')}} {{$t('home.Ni')}}</span>{{pricelist.lme[0].p3}} {{$t('home.USDton')}}</p>
                    <p><span>{{$t('home.London')}} {{$t('home.Sn')}}</span>{{pricelist.lme[0].p4}} {{$t('home.USDton')}}</p>
                    <p><span>{{$t('home.London')}} {{$t('home.Al')}}</span>{{pricelist.lme[0].p5}} {{$t('home.USDton')}}</p>
                  </div>    
                  <div class="index_metaltopright1" v-if="pricelist.lme.length==0">
                    <h3>LME：</h3>
                    <p><span>未更新</span>0.00 {{$t('home.USDton')}}</p>
                    <p><span>未更新</span>0.00 {{$t('home.USDton')}}</p>
                    <p><span>未更新</span>0.00 {{$t('home.USDton')}}</p>
                    <p><span>未更新</span>0.00 {{$t('home.USDton')}}</p>
                    <p><span>未更新</span>0.00 {{$t('home.USDton')}}</p>
                  </div>
                </div>
              </div>

              <div class="index_metalnote">
                <div class="index_metalnoteleft">
                  <div class="index_metalnoteleft1">
                    <h3>{{$t('home.Fromccmn')}}：</h3>
                    <div class="index_metalnoteleft2">
                      <p @click="rechart('1','1')" :class="cid=='1'&& jstype == '1'?'active':''">{{$t('home.Cu')}}</p>
                      <p @click="rechart('1','2')" :class="cid=='1'&& jstype == '2'?'active':''">{{$t('home.Zn')}}</p>
                      <p @click="rechart('1','3')" :class="cid=='1'&& jstype == '3'?'active':''">{{$t('home.Ni')}}</p>
                      <p @click="rechart('1','4')" :class="cid=='1'&& jstype == '4'?'active':''">{{$t('home.Sn')}}</p>
                      <p @click="rechart('1','5')" :class="cid=='1'&& jstype == '5'?'active':''" style="width:90px;margin-right: 0">{{$t('home.MnMn')}}</p>
                    </div>
                  </div>
                  <div class="index_metalnoteleft1" style="margin-top: 20px">
                    <h3>LME：</h3>
                    <div class="index_metalnoteleft2">
                      <p @click="rechart('2','1')" :class="cid=='2'&& jstype == '1'?'active':''">{{$t('home.Cu')}}</p>
                      <p @click="rechart('2','2')" :class="cid=='2'&& jstype == '2'?'active':''">{{$t('home.Zn')}}</p>
                      <p @click="rechart('2','3')" :class="cid=='2'&& jstype == '3'?'active':''">{{$t('home.Ni')}}</p>
                      <p @click="rechart('2','4')" :class="cid=='2'&& jstype == '4'?'active':''">{{$t('home.Sn')}}</p>
                      <p @click="rechart('2','5')" :class="cid=='2'&& jstype == '5'?'active':''">{{$t('home.Al')}}</p>
                    </div>
                  </div>
                </div>
                <div class="index_metalnoteright" id="chart">
                  
                </div>
              </div>
              <p style="font-size: 14px;color: #fff;margin-top: 20px;display: block;opacity: 0.8;">{{$t('home.metalpricesnote')}}</p>
            </div>
          </div>
        <!-- </swiper-slide>
        <swiper-slide id="indexscroll" class="indexscroll cdl_scollerStyle1"> -->
          <div class="footscroll" ref="scrollview">
            <div class="index_applications index_investor">
              <div class="index_applicationscon">
                <!-- <div class="hr"></div>
                <p class="industryp1">INVESTOR RELATIONS</p>
                <h3 class="industryp2">{{homePage.investorRelations.title}}</h3> -->
                <div class="textBox w12">
                  <h3 class="textH3">
                    <span style="color:#ededed;opacity: 0.6;">INVESTOR RELATIONS</span>
                    <p style="color:#fff;">{{homePage.investorRelations.title}}</p>
                  </h3>
                </div>
                <div class="index_investorall">
                  <div class="index_investorleft">
                    <router-link :to="item.link + '?index='+index" class="index_investorshow" :style="{backgroundImage:'url(' + item.img+ ')'}" v-for="(item,index) in investmentNavigationList" :key="index">
                      <p style="color:#fff">{{item.title}}</p>
                      <img src="../assets/images/applicaright.png" alt="">
                      <img src="../assets/images/applicaright.png" alt="">
                    </router-link>
                    <!-- <router-link to="/interim_ann?type=2" class="index_investorshow index_investorshow1">
                      <p style="color:#fff">临时公告</p>
                      <img src="../assets/images/applicaright.png" alt="">
                      <img src="../assets/images/applicaright.png" alt="">
                    </router-link>
                    <router-link to="/interim_ann?type=3" class="index_investorshow index_investorshow2">
                      <p style="color:#fff">定期公告</p>
                      <img src="../assets/images/applicaright.png" alt="">
                      <img src="../assets/images/applicaright.png" alt="">
                    </router-link>
                    <router-link to="/investment_advice" class="index_investorshow index_investorshow3">
                      <p style="color:#fff">投资咨询</p>
                      <img src="../assets/images/applicaright.png" alt="">
                      <img src="../assets/images/applicaright.png" alt="">
                    </router-link> -->
                  </div>
                  <div class="index_investorright">
                    <p>{{$t('home.Realtime')}} <span>/{{$t('home.RMBY')}}</span></p>
                    <div id="gupiao"></div>
                  </div>
                </div>
              </div>
            </div>
            <zfoot />
          </div>
        <!-- </swiper-slide> -->
        <!-- <div class="swiper-pagination swiper-pagination2" slot="pagination"></div> -->
      <!-- </swiper> -->
    </div>

    <!-- <div class="leftdh">
      <div class="leftdhall">
        <div class="leftdha">
          <div class="leftdhaimg">
            <img src="../assets/images/left_icon1.png" alt="">
          </div>
          <p>在线咨询</p>
        </div>
        <div class="leftdha">
          <div class="leftdhaimg">
            <img src="../assets/images/left_icon2.png" alt="">
          </div>
          <p>产品选型</p>
        </div>
        <div class="leftdha">
          <div class="leftdhaimg">
            <img src="../assets/images/left_icon3.png" alt="">
          </div>
          <p>微信咨询</p>
        </div>
        <div class="leftdha">
          <div class="leftdhaimg">
            <img src="../assets/images/left_icon4.png" alt="">
          </div>
          <p>收藏</p>
        </div>
        <div class="leftdha">
          <div class="leftdhaimg">
            <img src="../assets/images/left_icon5.png" alt="">
          </div>
          <p>下载</p>
        </div>
      </div>
      <div class="leftdhtop">
        <img src="../assets/images/left_icon6.png" alt="">
      </div>
    </div> -->

  </div>
</template>
<script>
    // function jsonpscall(name, value) {
    //   document.cookie = name + "=" + value + ";path=/;";
    // }
</script>
<script>
// @ is an alias to /src
import '../../node_modules/animate.css/animate.min.css';
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import zfoot from "@/components/foot"
import * as echarts from 'echarts';
import * as swiperAni from '../assets/js/animate.js'
import $ from 'jquery'
// import animate from 'animate.css';
// import zhead from "@/components/head"
// import func from 'vue-editor-bridge';
// import * as swiperAni from "../assets/js/animate.js"
import { jsonp } from 'vue-jsonp'
// import VideoPlayer from '@/components/VideoPlayer';
import hlsjs from 'hls.js'

export default {
  name: 'HomeView', 
  components: {
    swiper,
    swiperSlide,
    zfoot,
    // VideoPlayer
    // zhead
  },
  data(){
    const that = this
    return {
      bannerlist:[],
      applicalist:[],
      investmentNavigationList:[],
      swiperOption1:{
        slidesPerView: 1,
        speed:1500,
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false
        // },
        // pagination: {
        //   el: ".swiper-pagination1",
        //   clickable: true //允许分页点击跳转
        // },
        on:{
          slideChange:function(){
            // console.log(this.activeIndex)
            that.activeIndex1 = this.activeIndex
            // alert(that.activeIndex1)
          }
        }
      },

      swiperOptioncp:{
        slidesPerView: 5,
        spaceBetween: 20,
        // loop: true,
        // initialSlide :1,
        // centeredSlides: true,
        navigation: {
            nextEl: ".swiper-button-next2",
            prevEl: ".swiper-button-prev2",
        },
      },

      options:{
        autoplay: true,
        controls: false,
        sources: [
            {
                src: "https://www.runoob.com/try/demo_source/movie.mp4",
                type: "application/x-mpegURL"
            }
        ],
      },

      activeIndex1:'0',

      swiperOption: {
        direction: 'vertical',
        slidesPerView: 1,
        // spaceBetween:110,
        mousewheel: {
            releaseOnEdges: true
        },
        speed:500,
        parallax:true,
        autoHeight: true,
        height: window.innerHeight,
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false
        // },
        // 显示分页
        pagination: {
          el: ".swiper-pagination2",
          clickable: true //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        on:{
					init: function(){
						swiperAni.swiperAnimateCache(this); //隐藏动画元素 
						swiperAni.swiperAnimate(this); //初始化完成开始动画
					}, 
					slideChangeTransitionStart: function(){ 
						swiperAni.swiperAnimate(this); //每个slide开始切换时也运行当前slide动画
						//this.slides.eq(this.activeIndex).find('.ani').removeClass('ani'); 动画只展现一次，去除ani类名
					} 
				}
      },

      ourIndustryList:[],//我们的产业
      zklist:[],
      applicationAreaList:[],//产品应用
      cid:'1',
      jstype:'1',
      video_val:'',
      homePage:'',
      gupiaodata:'',

      ishbimg: false,
      lang:'',

      xcimg:'',

      portalMetalPriceListDtos:[], //金属价格
      pricelist:[],

      videoUrl:'https://bowaymenhu.oss-accelerate.aliyuncs.com/videos/cn/c4ecb05e3352466e9aac85d9602797cb/c4ecb05e3352466e9aac85d9602797cb.m3u8',
      video:'',
      

      //  metaList: [
      //   //SEO优化的meta数组
      //   {
      //     name: "description",
      //     content: "123123",
      //   },
      //   {
      //     name: "keywords",
      //     content: "123123",
      //   },
      //   {
      //     property: "release_date",
      //     content: "132123",
      //   },
      // ],

    }

    
  },
  //  metaInfo: {
  //   title: ``,
  //   meta: [
  //     {
  //       // set meta
  //       name: "description",
  //       content:"",
  //     },
  //     {
  //       name: "keywords",
  //       content: "",
  //     },
  //     {
  //       property: "release_date",
  //       content: "",
  //     },
  //   ],
  // },
  // metaInfo() {
  //   return {
  //     title: this.pageName,
  //     meta: this.metaList,
  //   };
  // },

  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  created() {
    this.lang = localStorage.getItem('lang')
    this.$get(this.apiUrl + 'home/homePage/bannerList').then((response) => {
        this.bannerlist =  response.data.portalIndexRotations;
        this.videoUrl = this.bannerlist[0].video
    });

    this.$get(this.apiUrl + 'home/homePage/ourIndustryList').then((response) => {
        this.ourIndustryList =  response.data;
        this.zklist = []
        for(let i in this.ourIndustryList){
          this.zklist.push({type:true})
        }
        //console.log(this.zklist)
    });

    this.$get(this.apiUrl + 'home/homePage/applicationAreaList').then((response) => {
        this.applicationAreaList =  response.data.applicationAreas;
    });

    this.$get(this.apiUrl + 'home/homePage/info').then((response) => {
        this.homePage =  response.data;
    });

    this.$get(this.apiUrl + 'home/MediaPage/sharesDetails').then((res)=>{
      this.gupiaodata = res.data
    })

    this.$get(this.apiUrl + 'home/homePage/investmentNavigationList').then((res)=>{
      this.investmentNavigationList = res.data.portalNavigationLists
    })

    this.$post(this.apiUrl + 'home/homePage/metalPriceReal').then((res)=>{
      this.pricelist = res.data
    })


    //this.getJsonp()
    //this.$refs.mySwiper.slideTo(2)

  },
  updated(){
    this.show()
    // this.$nextTick( () => { 
    //   console.log(document.querySelector(".videoElement"))
    // })
  },
  mounted() {

    //this.show()
    // this.show()
    // this.$nextTick( () => { 
    //   console.log(document.querySelector(".videoElement"))
    // })
    
    // setTimeout(() => {
    //   this.pageName = `${this.pipiload.hname}`;
    //   this.metaList[0].content = `${this.descriptionDate}`; // description
    //   this.metaList[1].content = `${this.pipiload.hname}`; //name="keywords"
    //   this.metaList[2].content = `${this.release_date}`;
    // }, 2000);

    
    // console.log("this is current swiper instance object", this.swiper);
    // this.swiper.slideTo(3, 1000, false);
    
    this.rechart(this.cid,this.jstype)
    this.chartgp()
    // 获取指定元素
    // const scrollview = this.$refs['scrollview']
    // 添加滚动监听，该滚动监听了拖拽滚动条
    // 尾部的 true 最好加上，我这边测试没加 true ，拖拽滚动条无法监听到滚动，加上则可以监听到拖拽滚动条滚动回调
    // scrollview.addEventListener('scroll', this.scrollChange, true)
  },
  // beforeDestroy 与 destroyed 里面移除都行
  beforeDestroy () {
    // 获取指定元素
    // const scrollview = this.$refs['scrollview']
    // // 移除监听
    // scrollview.removeEventListener('scroll', this.scrollChange, true)
  },

  methods: {

    //play for flv
    show() {
      // this.$nextTick( () => { 
      this.video = document.querySelector(".videoElement"); //定义挂载点
      //console.log(this.video);
      if(this.video){
        if (hlsjs.isSupported()) {
        this.hlsjs = new hlsjs();
        this.hlsjs.loadSource(this.videoUrl);//设置播放路径
        //console.log(this.videoUrl)
        this.hlsjs.attachMedia(this.video);//解析到video标签上
        this.hlsjs.on(hlsjs.Events.MANIFEST_PARSED, () => {
          this.video.play();
          //alert(1)
        });

        this.hlsjs.on(hlsjs.Events.ERROR, (event, data) => {
          console.log(event, data);
          // 监听出错事件
          console.log("加载失败");
        });
      } else {
        //this.$message.error("不支持的格式");
        return;
      }
      }
      //  } ) 
      
    },

    changezk(index){
      this.zklist[index].type = !this.zklist[index].type
    },

    pro_router(isChain,id,link){
        if(isChain == 1){
            window.open(link, '_blank');
        }else{
            this.$router.push({
                path:link,
            })
        }
    },

    linkalert(link){
      if(link != null){
        window.open(link, '_blank');
      }
    },

    showhbimg(url){
      if(url){
        this.ishbimg = !this.ishbimg
        this.xcimg = url
      }else{
        return
      }

      
    },



      // getCookie(cookieName) {
      //   let strCookie = document.cookie
      //   let cookieList = strCookie.split('; ')
        
      //   for(let i = 0; i < cookieList.length; i++) {
      //     const arr = cookieList[i].split('=')
      //     if (cookieName === arr[0]) {
      //       return arr[1]
      //     }
      //   }
        
      //   return ''
      // },
  //   async getJsonp(){
  //     alert(1)
  //     const response = await this.$jsonp('http://10.60.5.146:9001/customer/customerPage/jsonp',{
  //         callbackQuery:'callbackParam', //一定要加这两个参数
  //         callbackName:'jsonpCallback' //一定要加这两个参数,要不然会报错 导致代码阻塞
  //     })
  //     console.log(response,'jsonp')
  //     alert(2)
  // },
    video_hide(){
      this.video_val = ""
      this.$refs.myVideo.pause();
    },
    video_show(){
      this.video_val = "1"
      this.$refs.myVideo.play();
    },
    // 滚动监听
    // scrollChange () {
    //   let that = this
    //   let top1 = that.$el.querySelector('#indexscroll')
    //   if(top1.scrollTop == 0){
    //     that.swiper.mousewheel.enable();
    //   }else{
    //     that.swiper.mousewheel.disable();
    //   }
    // },

    rechart(id,type){
      this.cid = id
      this.jstype = type
      this.$post(this.apiUrl + 'home/homePage/metalPrice', { cid: this.cid,type }).then((response) => {
          let xlist = []
          let ylist = []
          for(let i = 0;i<response.data.portalMetalPriceDtos.length;i++){
            xlist.push(response.data.portalMetalPriceDtos[i].years);
            ylist.push(response.data.portalMetalPriceDtos[i].value);
          }
          this.portalMetalPriceListDtos = response.data.portalMetalPriceListDtos
          setTimeout(()=>{
            this.chartresize(xlist,ylist)
          },200)
      })
    },

    toDecimal(x) {

        let f = parseFloat(x);

        if (isNaN(f)) {

            return;

        }

        f = Math.round(x * 100) / 100;

        return f;

    },

   splitData(rawData) {

    var categoryData = [];

    var values = [];

    var rawData = rawData.split(',');

    for (var i = 0; i < rawData.length; i++) {

        rawData[i] = rawData[i].split(' ');

        categoryData.push(rawData[i][0]);

        rawData[i][0] = i;

        values.push(rawData[i]);

    }



    return {

        categoryData: categoryData,

        values: values

    };

},

renderItem(params, api) {

    var xValue = api.value(0);

    var openPoint = api.coord([xValue, api.value(1)]);

    var closePoint = api.coord([xValue, api.value(2)]);

    var lowPoint = api.coord([xValue, api.value(3)]);

    var highPoint = api.coord([xValue, api.value(4)]);

    var halfWidth = api.size([1, 0])[0] * 0.35;

    var style = api.style({

        stroke: api.visual('color')

    });



    return {

        type: 'group',

        children: [{

            type: 'line',

            shape: {

                x1: lowPoint[0], y1: lowPoint[1],

                x2: highPoint[0], y2: highPoint[1]

            },

            style: style

        }, {

            type: 'line',

            shape: {

                x1: openPoint[0], y1: openPoint[1],

                x2: openPoint[0] - halfWidth, y2: openPoint[1]

            },

            style: style

        }, {

            type: 'line',

            shape: {

                x1: closePoint[0], y1: closePoint[1],

                x2: closePoint[0] + halfWidth, y2: closePoint[1]

            },

            style: style

        }]

    };

},


    chartgp(){
      // $.get('/public/static/gupiao/public/GetStockInfo_list.php', function (rawData){
    this.$post(this.apiUrl + 'home/MediaPage/sharesTimeSharing', { type : 1}).then((rawData) => {
        // home/homePage/dailyStockPrice
        var yestclose = this.gupiaodata[4];
        // console.log(this.gupiaodata)
        if(yestclose == 0){
            yestclose = 40;
        }

        let max_prices = this.gupiaodata[33]
        let min_prices = this.gupiaodata[34]
        let max_price = 30;
        let min_price = 20;

        if(yestclose > this.toDecimal(max_prices)){
          max_price = parseFloat(yestclose) + 3;
        }else{
          max_price = this.toDecimal(max_prices);
        }



        if(yestclose < this.toDecimal(min_prices)){

            min_price = parseFloat(yestclose) - 3;

        }else{

            min_price = this.toDecimal(min_prices);

        }

        var maxnum = Math.ceil(max_price);

        var minnum = Math.floor(min_price);


        //console.log(rawData)
        var data = this.splitData(rawData.data.data.sh601137.data.data.toString());
        //console.log(data)
      let myChart1 = this.$echarts.init(document.getElementById('gupiao'));
      let option;
      myChart1.setOption(option = {

            // 整体背景

            backgroundColor: 'rgba(255, 255, 255, 0)',

            animation: false,

            legend: {

                bottom: 10,

                left: 'center',

                data: ['Dow-Jones index']

            },

            // 悬浮框

            tooltip: {

                trigger: 'axis',

                axisPointer: {

                    type: 'cross'

                },

                backgroundColor: 'rgba(245, 245, 245, 0.8)',

                borderWidth: 1,

                borderColor: '#ccc',

                padding: 10,

                textStyle: {

                    color: '#000'

                },

                position: function (pos, params, el, elRect, size) {

                    var obj = {top: 10};

                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;

                    return obj;

                },

                extraCssText: 'width: 170px'

            },

            // 坐标显示

            axisPointer: {

                link: {xAxisIndex: 'all'},

                label: {

                    backgroundColor: '#777'

                }

            },

            // 折现图显示的位置

            grid: [

                {

                    left: '48px',

                    right: '38px',

                    top: 15,

                    bottom: 30

                }

            ],

            // 横向坐标

            xAxis: [

                {
                    axisLine:{                 
                        //坐标 轴线
                        show:true,             
                        //是否显示坐标轴轴线
                        onZero:true,          
                         //X 轴或者 Y 轴的轴线是否在另一个轴的 0 刻度上，只有在另一个轴为数值轴且包含 0 刻度时有效
                        lineStyle:{
                            color:'#ED6C00',
                        }
                    },
                    axisLabel:{                 
                       //坐标轴刻度标签的相关设置
                        show:true,              
                        //是否显示
                        interval:59,        
                    },
                    axisTick: {
                        alignWithLabel: true,
                    },

                    data: ['09:30','09:31','09:32','09:33','09:34','09:35','09:36','09:37','09:38','09:39','09:40','09:41','09:42','09:43','09:44','09:45','09:46','09:47','09:48','09:49','09:50','09:51','09:52','09:53','09:54','09:55','09:56','09:57','09:58','09:59','10:00','10:01','10:02','10:03','10:04','10:05','10:06','10:07','10:08','10:09','10:10','10:11','10:12','10:13','10:14','10:15','10:16','10:17','10:18','10:19','10:20','10:21','10:22','10:23','10:24','10:25','10:26','10:27','10:28','10:29','10:30','10:31','10:32','10:33','10:34','10:35','10:36','10:37','10:38','10:39','10:40','10:41','10:42','10:43','10:44','10:45','10:46','10:47','10:48','10:49','10:50','10:51','10:52','10:53','10:54','10:55','10:56','10:57','10:58','10:59','11:00','11:01','11:02','11:03','11:04','11:05','11:06','11:07','11:08','11:09','11:10','11:11','11:12','11:13','11:14','11:15','11:16','11:17','11:18','11:19','11:20','11:21','11:22','11:23','11:24','11:25','11:26','11:27','11:28','11:29','11:30/13:00','13:01','13:02','13:03','13:04','13:05','13:06','13:07','13:08','13:09','13:10','13:11','13:12','13:13','13:14','13:15','13:16','13:17','13:18','13:19','13:20','13:21','13:22','13:23','13:24','13:25','13:26','13:27','13:28','13:29','13:30','13:31','13:32','13:33','13:34','13:35','13:36','13:37','13:38','13:39','13:40','13:41','13:42','13:43','13:44','13:45','13:46','13:47','13:48','13:49','13:50','13:51','13:52','13:53','13:54','13:55','13:56','13:57','13:58','13:59','14:00','14:01','14:02','14:03','14:04','14:05','14:06','14:07','14:08','14:09','14:10','14:11','14:12','14:13','14:14','14:15','14:16','14:17','14:18','14:19','14:20','14:21','14:22','14:23','14:24','14:25','14:26','14:27','14:28','14:29','14:30','14:31','14:32','14:33','14:34','14:35','14:36','14:37','14:38','14:39','14:40','14:41','14:42','14:43','14:44','14:45','14:46','14:47','14:48','14:49','14:50','14:51','14:52','14:53','14:54','14:55','14:56','14:57','14:58','14:59','15:00']

                }

            ],

            // 竖向坐标

            yAxis: [

                {
                    axisLine:{                 
                        //坐标 轴线
                        show:true,             
                        //是否显示坐标轴轴线
                        onZero:true,          
                         //X 轴或者 Y 轴的轴线是否在另一个轴的 0 刻度上，只有在另一个轴为数值轴且包含 0 刻度时有效
                        lineStyle:{
                            color:'#ED6C00',
                        }
                    },

                    splitLine:{                 
                        //坐标轴在 grid 区域中的分隔线。
                        show:true,             
                         //是否显示分隔线。默认数值轴显示，类目轴不显示。
                        interval:"auto",        
                        //坐标轴分隔线的显示间隔，在类目轴中有效。默认会采用标签不重叠的策略间隔显示标签。可以设置成 0 强制显示所有标签。如果设置为 1，表示『隔一个标签显示一个标签』，可以用数值表示间隔的数据，也可以通过回调函数控制。回调函数格式如下：
                        lineStyle:{
                            color:'#ED6C00',
                        }
                    },
                    scale: true,

                    min: function(value) {

                        return minnum;

                    },

                    max: function(value) {

                        return maxnum;

                    },

                    splitArea: {

                        show: true,
                        lineStyle:{
                            color:'#ff0',
                        },
                        areaStyle: {

                            // 使用深浅的间隔色

                            color: ['#f18e3a', '#f9d2b0']

                        }

                    }

                }

            ],

            series: [

                {

                    name:'博威合金',

                    type: 'line',

                    lineStyle: {

                        width: 1

                    },

                    itemStyle: {

                        // 折线颜色

                        color: 'rgb(237, 109, 0)'

                    },

                    renderItem: this.renderItem,

                    dimensions: [null, '当前价格', '均价', '交易量'],

                    encode: {

                        x: 0,

                        y: [1],

                        tooltip: [1, 2, 3]

                    },

                    areaStyle: {

                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{

                            offset: 0,

                            // 折线面的颜色

                            color: '#fff'

                        }, {

                            offset: 1,

                            color: '#fff'

                        }])

                    },

                    data: data.values,

                    markLine: {

                        silent: true,

                        lineStyle: {

                            width:1,

                            opacity:0.6,

                            color: 'rgb(237, 109, 0)'

                            // 横虚线的颜色

                        },

                        data: [{

                            yAxis: yestclose

                        }]

                    }

                }

            ]

        }, true);


})
    },

    chartresize(xlist,ylist){
      let myChart = this.$echarts.init(document.getElementById('chart'));
      let option;
      option = {
        color: ['rgba(255,255,255,0.6)'],
                      tooltip: {
                          trigger: 'axis',
                          axisPointer: { // 坐标轴指示器，坐标轴触发有效
                              type: 'cross' // 默认为直线，可选为：'line' | 'shadow'|'cross'
                          }                 
                      },
                      axisPointer: {
                        link: {xAxisIndex: 'all'},
                        label: {
                    backgroundColor: '#777'
                }
            },
                      legend: {
                          data: ['金属价格']
                      },
                      grid: {
                          left: '3%',
                          right: '10%',
                          bottom: '10%',
                          containLabel: true
                      },
                      xAxis: [{
                          type: 'category',
                          data: xlist,
                          boundaryGap: false,
                          axisTick: {
                              alignWithLabel: true,
                          },
                          axisLine:{                 
                        //坐标 轴线
                        show:true,             
                        //是否显示坐标轴轴线
                        onZero:true,          
                          //X 轴或者 Y 轴的轴线是否在另一个轴的 0 刻度上，只有在另一个轴为数值轴且包含 0 刻度时有效
                        lineStyle:{
                            color:'rgba(255,255,255,0.6)',
                        }
                    },
                      }],
                      yAxis: [{ // 纵轴标尺固定
                          type: 'value',
                          scale: true,
                          // max: data.maxs,
                          // min: data.mins,
                          splitNumber: 6,
                          axisLine:{                 
                        //坐标 轴线
                        show:true,             
                        //是否显示坐标轴轴线
                        onZero:true,          
                        //X 轴或者 Y 轴的轴线是否在另一个轴的 0 刻度上，只有在另一个轴为数值轴且包含 0 刻度时有效
                        lineStyle:{
                          color:'rgba(255,255,255,0.6)',
                        }
                    },
                  splitLine:{                 
                        //坐标轴在 grid 区域中的分隔线。
                        show:true,             
                        //是否显示分隔线。默认数值轴显示，类目轴不显示。
                        interval:"auto",        
                        //坐标轴分隔线的显示间隔，在类目轴中有效。默认会采用标签不重叠的策略间隔显示标签。可以设置成 0 强制显示所有标签。如果设置为 1，表示『隔一个标签显示一个标签』，可以用数值表示间隔的数据，也可以通过回调函数控制。回调函数格式如下：
                        lineStyle:{
                            color:'rgba(255,255,255,0.6)',
                        }
                    },

                      }],
        series: [
          {
            data: ylist,
            type: 'line'
          }
        ]
      };
      myChart.clear()
      option && myChart.setOption(option);
    },
  }

  // $(".y_slide").on("scroll",function(event){
	// 	var top = $(this).scrollTop();
	// 	console.log(top);
	// 	if(top == 0){
	// 		mySwiper1.mousewheel.enable();
	// 	}else{
	// 		mySwiper1.mousewheel.disable();
	// 	}
	// })
}
</script>

<!-- <script>
import $ from 'jquery'
  if ($(window).width() < 992) {
    var $copy_bottom_right = $('.dbgs span'),
		$translate = $('.dbgssub');
	$copy_bottom_right.click(function(e) {

		e.stopPropagation();
		$('.hhzhbg').fadeIn(200);
		$translate.fadeIn(300);

	});
    $translate.click(function() {

		$(this).fadeOut(200)

		$('.hhzhbg').fadeOut(200);

	});
    
    $translate.blur(function(){
        $(this).fadeOut(200)

		$('.hhzhbg').fadeOut(200);
    })


	$('.hhzhbg').click(function() {

		$(this).fadeOut(200)

		$translate.fadeOut(300);

	});
}else{
    var $copy_bottom_right = $('.dbgs'),
    $translate = $copy_bottom_right.find('.dbgssub');
    $copy_bottom_right.hover(function() {
        $translate.stop().slideDown(500);
    }, function() {
        $translate.stop().slideUp(500);
});   
}
</script> -->

<style lang="less" scoped>
  .home{
    .index{
      // height: calc(100vh - 110px);
      // width: 100%;
        padding-top: 90px;

      .indexsw /deep/ .swiper-container{
        position: relative;
        width: 100%;
        height: 36.45vw!important;
        display: block;
      }  
      .indexsw /deep/ .swiper-wrapper{
        height: 36.45vw!important;
      }
      .indexsw /deep/ .swiper-container .swiper-slide{
        width: 100%;
        height: 36.45vw!important;
        margin-bottom: 110px;
      }


      .indexsw /deep/ .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet{
        margin: 12px 0;
      }

      .indexsw /deep/ .swiper-pagination-bullet{
        opacity: 0.6;
      }

      .indexsw /deep/ .swiper-pagination-bullet-active{
        background: #ED6C00;
        opacity: 1;
      }

      .index_industry{
        width: 100%;
        // height: calc(100vh - 110px);
        padding: 70px 0;
        padding-bottom: 40px;
        display: flex;
        align-items: center;

        .industry{
          // margin-left: auto;
          // width: 87%;
          width: 90%;
          max-width: 1315px;
          margin: 0 auto;
          padding-bottom: 0;

          .hr{
            width: 200px;
            height: 1px;
            background: #000;
            margin-bottom: 60px;
          }

          .industryp1{
            line-height: 22px;
            font-size: 14px;
            color: #000000;
          }

          .industryp2{
            font-size: 40px;
            color: #000000;
            line-height: 64px;
            font-weight: bold;
          }

          .industrylist{
            display: flex;
            margin-top: 40px;
            flex-wrap: wrap;

            a{
              text-decoration: none;
              transition: 0.5s all;
              margin-bottom: 30px;
            }

            a:hover{
              box-shadow: 0px 1px 33px 0px rgb(232 232 232 / 75%);
            }

            a:hover .industrynoteh h3{
              color: #ED6C00;
            }

            // .industryshow:nth-child(n+4){
            //   display: none;
            // }

            .industryshow{
              // flex: 1;
              width: 32%;
              margin-right: 2%;

              

              .industryshowimg{
                width: 100%;
                height: 287px;
                object-fit: cover;
                display: block;
              }

              .industrynote{
                margin-top: 18px;
                padding-bottom: 20px;

                p{
                  color: #636366;
                  font-size: 16px;
                  line-height: 24px;
                  padding: 0 50px;
                }
              }

              .industrynoteh{
                height: 64px;
                display: flex;
                align-items: center;

                .industrynoteh1{
                  width: 50px;
                  display: flex;
                  align-items: center;

                  img{
                    max-width: 31px;
                    max-height: 31px;
                  }
                }

                h3{
                  font-size: 20px;
                  color: #3A3A3C;
                }
              }
            }
            &>.industryshow:nth-child(3n){
                margin-right: 0;
              }
          }
        }
      }

      .index_video{
        width: 100%;
        // height: calc(100vh - 110px);
        height: 30.2vw;
        position: relative;

        &>video{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &>img{
          width: 100%;
          // height: calc(100vh - 110px);
          height: 30.2vw;
          display: block;
          object-fit: cover;
        }

        .index_videopop{
          width: 100%;
          // height: calc(100vh - 110px);
          height: 30.2vw;
          position: absolute;
          background: rgba(0,0,0,0);
          left: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          img{
            width: 70px;
            height: 70px;
          }
        }
      }
      .index_applications{
        width: 100%;
        // height: calc(100vh - 110px);
        padding: 75px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(../assets/images/index_applicationsbg.jpg);
        background-size: 100% 100%;
        // background-attachment: fixed;
        background-repeat: no-repeat;

        .index_applicationscon{
          max-width: 1560px;
          width: 100%;
          padding: 0 70px;
          position: relative;
          
        }

        .index_applicationsconnote{
          display: flex;
          position:absolute;
          width: 1130px;
          height: 200px;
          right: 0;
          top: 27px;
          align-items: center;

          p{
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0px;
            color: #000000;
            opacity: 0.6;
            width: 843px;
          }

          a{
            display: flex;
            margin-left: auto;
            width: 170px;
            height: 50px;
            color: #000000;
            align-items: center;
            justify-content: center;
            border: 1px solid #000;
            text-decoration: none;
          }
        }

        .applicashowall{
          display: flex;
          margin-top: 40px;
          position: relative;

          .swiper-button-next{
            right: -45px;
            background: none;
          }

          .swiper-button-prev{
            left: -45px;
            background: none;
          }

          .applicashow:nth-child(1){
            margin-left: 0;
          }

          .applicashow:nth-child(n+6){
            display: none;
          }

          .applicashow{
            width: 100%;
            height: 370px;
            margin-left: 20px;
            position: relative;
            overflow: hidden;

            &>img{
              width: 268px;
              height: 370px;
              display: block;
              object-fit: cover;
            }

            .applicashowtitle{
              width: 100%;
              height: 100%;
              padding: 20px;
              box-sizing: border-box;
              position: absolute;
              top: 0;
              left: 0;
              // background: linear-gradient( to top,rgba(0,0,0,0.8),rgba(0,0,0,0.5),rgba(0,0,0,0.1));
              background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));


              p{
                font-size: 22px;
                color: #fff;
                margin-top: 270px;
                margin-bottom: 10px;
              }
            }

            .applicashownote{
              width: 100%;
              height: 370px;
              background-color: rgba(237,108,0,0.9);
              opacity: 0.95;
              position: absolute;
              z-index: 11;
              left: 0;
              top: 100%;
              padding: 20px;
              box-sizing: border-box;
              overflow: hidden;
              transition: 0.5s;
              cursor: pointer;
              

              h3{
                color: #fff;
                font-size: 24px;
                line-height: 60px;
              }

              p{
                font-size: 14px;
                line-height: 24px;
                color: #ffffff;
              }
            }
          }

          .applicashow:hover{
            .applicashownote{
              top: 0;
            }
          }
        }
      }

      .index_metal{
        background-image: url(../assets/images/jsprice.jpg);
      
        .hr{
          background: #fff;
        }

        .industryp1{
          color: #fff;
        }

        .industryp2{
          color: #fff;
        }

        .index_metaltop{
          display: flex;
        }

        .index_metaltopright{
          max-width: 1060px;
          width: calc(100% - 220px);
          height: 300px;
          border: 1px solid rgba(255,255,255,0.6);
          margin-left: auto;
          display: flex;
          background: rgba(255,255,255,0.1);
        
          .index_metaltopright1{
            height: 300px;
            flex: 1;
            padding: 30px 30px 30px 60px;
          
            h3{
              font-size: 18px;
              line-height: 58px;
              color: #fff;
            }

            p{
              font-size: 16px;
              line-height: 34px;
              color: #ffffff;
              opacity: 0.8;

              span{
                width: 130px;
                display: inline-block;
              }
            }
          }
        }

        .index_metalnote{
          width: 100%;
          height: 400px;
          border: 1px solid rgba(255,255,255,0.6);
          background: rgba(255,255,255,0.1);
          margin-top: 40px;
          display: flex;
          padding: 40px 40px 40px 60px;
          align-items: center;
        }

        .index_metalnoteleft{
          width: 140px;
          
          .index_metalnoteleft1{

            h3{
              color: #ffffff;
              font-size: 18px;
              font-weight: bold;
              line-height: 42px;
              margin-bottom: 5px;
            }

            .index_metalnoteleft2{
              display: flex;
              flex-wrap: wrap;
              font-size: 0;

              p{
                width: 40px;
                height: 40px;
                font-size: 16px;
                color: #ffffff;
                line-height: 40px;
                text-align: center;
                margin-right: 10px;
                background-color: rgba(255,255,255,0.2);
                margin-bottom: 10px;
              }

              p.active{
                background: #fff;
                color: #ED6C00;
              }

              p:nth-child(3n){
                margin-right: 0;
              }
            }
          }
        }

        .index_metalnoteright{
          // width: 1120px;
          width: calc(100% - 300px);
          height: 320px;
          margin-left: auto;
        }
      }

      .index_investor{
        background-image: url(../assets/images/tzzgx.jpg);
        background-size: 100% 100%;
      }
    }

    .indexscroll{
      overflow-y: scroll;
      height: calc(100vh - 110px)!important;
    }

    .footscroll{
      // height: calc(100vh + 500px)!important;
    }

    .index_investorall{
      margin-top: 50px;
      display: flex;


      .index_investorleft{
        display: flex;
        flex-wrap: wrap;
        width: 840px;

        .index_investorshow:nth-child(2n){
          margin-right: 0;
        }

        .index_investorshow:nth-child(n+3){
          margin-bottom: 0px;
        }

        .index_investorshow{
          box-sizing: border-box;
          text-decoration: none;
          width: 410px;
          height: 200px;
          display: block;
          margin-right: 20px;
          margin-bottom: 20px;
          background: #fff;
          background-image: url(../assets/images/invebg.jpg);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          padding: 20px;
          transition: 0.5s;
          font-size: 0;
          background-position: center center;

          &>p{
            font-size: 18px;
            color: #3A3A3C;
            // font-weight: bold;
            margin-top: 110px;
            margin-bottom: 20px;
          }

          img:nth-child(3){
            display: none;
          }

        }

        .index_investorshow1{
          background-image: url(../assets/images/invebg3.jpg);
        }

        .index_investorshow2{
          background-image: url(../assets/images/invebg1.jpg);
        }

        .index_investorshow3{
          background-image: url(../assets/images/invebg2.jpg);
        }

        .index_investorshow:hover{
          background-size: 120% 120%;
          // background: rgba(0,0,0,0.2);
          // transform: scale(1.2);
          transition: 0.5s all;
          background-position: center center;

          // &>p{
          //   color: #fff;
          // }

          // img:nth-child(2){
          //   display: none;
          // }

          // img:nth-child(3){
          //   display: none;
          // }
        }
      }

      .index_investorright{
        max-width: 560px;
        width: calc(100% - 870px);
        height: 420px;
        background: #fff;
        margin-left: auto;
        padding-left: 30px;
        padding-top: 30px;
        padding-right: 20px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        p{
          font-size: 22px;
          font-weight: bold;
          color: #3A3A3C;
          margin-bottom: 20px;
          padding-left: 40px;
          span{
            font-size: 14px;
            font-weight: 400;
          }
        }

        div{
          width: 100%;
          height: 300px;
        }
      }
    }
  }
  

  // .el-select-dropdown__item.selected{
  //   color: #ED6C00;
  //   background: #fff;
  //   text-align: center;
  // }
  // .el-select-dropdown__item{
  //   text-align: center;
  // }

  /* 全局滚动条样式 */
.cdl_scollerStyle1::-webkit-scrollbar {
    width: 6px;
    /*height: 4px;*/
}
.cdl_scollerStyle1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px #aaa;
    background: #aaa;
}
.cdl_scollerStyle1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px transparent;
    border-radius: 0;
    background: transparent;
}

.hr{
  width: 200px;
  height: 1px;
  background: #000;
  margin-bottom: 60px;
}

.industryp1{
  line-height: 22px;
  font-size: 14px;
  color: #000000;
}

.industryp2{
  font-size: 40px;
  color: #000000;
  line-height: 64px;
  font-weight: bold;
}

.swiperpag{
  position: absolute;
  left: 250px;
  bottom: 80px;
  z-index: 1;
  display: flex;
  font-size: 18px;
  color: #ffffff;

  p{
    width: 50px;
    margin-right: 10px;
    line-height: 60px;
    text-align: center;
    font-weight: bold;
  }

  p.active{
    font-size: 30px;
    color: #ED6C00;
    line-height: 50px;
    border-bottom: 1px solid #fff;
  }
}

.shubiao{
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translate(-50%,0);
  display: block;
  z-index: 1;
}

.shubiao1{
  width: 1px;
  height: 80px;
  opacity: 0.6;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%,0);
  display: block;
  z-index: 1;
  background: #fff;
}

.y_video_1{
        width: 100%;
        height: 100vh;
        z-index: 200;
        background: rgba(0,0,0,0.6);
        top:0px;
        left: 0px;
        position: fixed;
        display: none;
    }
    .y_video_1_active{
        display: block !important;
    }
    .y_video_2{
        width: 1200px;
        // height: 600px;
        // background: #fff;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        position: fixed;
        // padding: 20px;
    }
    .y_video_3{
        width: 100%;
        display: flex;
        justify-items: flex-end;
        text-align: right;
        img{
            width: 30px;
            height: 30px;
            display: inline-block;
            margin-left: auto;
            cursor: pointer;
        }
    }
    .y_video_4{
        width: 1200px;
        margin: 10px auto;
        // height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 600px;
      background: #000;
        video{
            width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }

    .swipervideo{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #000;

      video{
        width: 100%;
      height: 100%;
      object-fit: cover;
      }
    }

    .swipernote{
      position: absolute;
      z-index: 1;
      left: 250px;
      top: 32%;
      h3{
        font-size: 60px;
        color: #ffffff;
        font-weight: bold;
      }
      p{  
        color: #fff;
        font-size: 30px;
        margin-top: 10px;
        font-weight: 100;
      }
    }




    .leftdh {
      width: 68px;
      // height: 350px;
      position: fixed;
      right: 42px;
      top: 50%;
      transform: translate(0,-50%);
      margin-top: 60px;
      z-index: 11;

      .leftdhtop{
        display: flex;
        width: 100%;
        height: 70px;
        margin-top: 10px;
        align-items: center;
        justify-content: center;
        background-color: #fcfcfc;
        box-shadow: 0px 3px 24px 0px 
          rgba(232, 108, 0, 0.1);
      }

      .leftdhall{
        background-color: #fcfcfc;
        box-shadow: 0px 3px 24px 0px 
          rgba(232, 108, 0, 0.1);
      }
    
      .leftdha{
        width: 100%;
        height: 70px;
        display: block;
        box-sizing: border-box;
        border-bottom: 2px solid #f0f0f0;
        padding-top: 13px;

        .leftdhaimg{
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
        }

        p{
          font-size: 12px;
          line-height: 30px;
          text-align: center;
          display: block;
          color: #3A3A3C;
          width: 100%;
        }
      }
      
    }

    .cpxqsc{
      height: 240px;
          overflow-y: scroll;
    }

    .cpxqsc::-webkit-scrollbar-thumb {
        border-radius: 50px;
        -webkit-box-shadow: inset 0 0 5px rgb(255 255 255);
        background: #fff;
        width: 100%;
        height: 60px;
    }

    .cpxqsc::-webkit-scrollbar {
        width: 6px;
        height: 100%;
        border-radius: 50px;
    }



    @media screen and (max-width: 1500px) {
      // body {
      //     background-color:lightblue;
      // }

      .home .index .index_industry .industry .industryp2{
        font-size: 26px;
      }
      .home .index .index_industry .industry .industrylist{
        margin-top: 30px;
      }
      .home .index .index_industry .industry .hr{
        margin-bottom: 30px;
      }
      // .home .index .index_industry .industry .industrylist .industryshow .industryshowimg{
      //   height: 260px;
      // }
      .home .index .index_industry .industry .industrylist .industryshow .industrynoteh h3{
        font-size: 20px;
      }
      .industryp2{
        font-size: 26px;
      }
      .hr{
        margin-bottom: 30px;
      }
      .home .index .index_metal .index_metaltopright .index_metaltopright1{
        height: 270px;
        flex: 1;
        padding: 20px 20px 20px 40px;
      }
    }
    .w12 {
        width: 1200px;
        margin: auto;
    }
    .textH3 {
        text-align: center;
        margin-bottom: 35px;
        font-size: 26px;
    }
    .textH3 span {
        font-family: 'DINCond-Medium';
        color: #ededed;
        text-transform: uppercase;
    }
    .textH3 p {
        line-height: 33px;
        margin-top: -22px;
        color: #ED6C00;
        font-weight: bold;
    }

    .hbimg{
      width: 100%;
      height: 100%;
      position: fixed;
      left: 0;
      top: 0;
      background: rgba(0,0,0,0.4);
      z-index: 112;
      display: flex;
      align-items: center;
      justify-content: center;

      img{
        max-width: 80%;
        max-height: 80%;
      }
    }
    
.enclass{
  .textH3{
    span{
      display: none!important;
    }
    p{
      font-size: 35px!important;
      margin-top: 0!important;
    }
  }
}

.zkview{
  max-height: 72px;
  overflow: hidden;
}

.zkbtn{
  color: #ED6C00!important;
  position: absolute;
  top: 48px;
  right: 50px;
  width: 50px;
  padding: 0!important;
  background: #fff;
  text-align: right;
}

.zkbtn1{
  top: auto;
  bottom: 0;
}
</style>
