<template>
  <div class="contain">
    <div class="x_screen">
        <div class="left leftp">
          <!-- <div class="date">
            <el-date-picker
              v-model="checkTime"
              type="monthrange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy.MM"
              value-format="yyyy-MM"
            >
            </el-date-picker>
          </div> -->
          <!-- <div class="others">
            <el-select
              v-model="zsybt"
              placeholder="全部"
              @change="initchart"
            >
              <el-option
                v-for="item in twoSelect"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div> -->
          <p v-for="(item) in twoSelect" @click="changetype(item.value)" :class="zsybt == item.value?'active':''" :key="item.label">{{item.label}}</p>
        </div>
        <p style="color: #828593;font-size:16px;margin-left:auto;font-weight: normal;">统计时间范围：{{timeTip}}</p>
        
        <!-- <button class="searchbtn" @click="beginSearch">查询</button> -->
      </div>
    <div class="one" v-if="info.orderDetailsDto">
      <div class="detail" @click="gotoDdcx()" style="cursor: pointer;">
        <div class="deInfo">
          <div class="left"><img src="../../assets/images/x_icon22.png" alt=""></div>
          <div class="right">
            <div class="title">我的订单</div>
            <div class="number">{{info.orderDetailsDto.orderNumber}}<span>单</span></div>
          </div>
        </div>
      </div>
      <div class="detail" @click="gotoDdcxfh()" style="cursor: pointer;">
        <div class="deInfo">
          <div class="left"><img src="../../assets/images/x_icon23.png" alt=""></div>
          <div class="right">
            <div class="title">待发货</div>
            <div class="number">{{info.orderDetailsDto.shippedNumber}}<span>单</span></div>
          </div>
        </div>
      </div>
      <div class="detail">
        <div class="deInfo">
          <div class="left"><img src="../../assets/images/x_icon21.png" alt=""></div>
          <div class="right">
            <div class="title">待付款</div>
            <div class="number">{{info.orderDetailsDto.copeWithMonth}}<span>万元</span></div>
          </div>
        </div>
      </div>
      <div class="detail" @click="gotoSy()" style="cursor: pointer;">
        <div class="deInfo">
          <div class="left"><img src="../../assets/images/x_icon25.png" alt=""></div>
          <div class="right">
            <div class="title">产品试样</div>
            <div class="number">{{info.orderDetailsDto.sampleNumber}}<span>单</span></div>
          </div>
        </div>
      </div>
      <div class="detail">
        <div class="deInfo">
          <div class="left"><img src="../../assets/images/x_icon16.png" alt=""></div>
          <div class="right">
            <div class="title">合作项目</div>
            <div class="number">0<span>个</span></div>
          </div>
        </div>
      </div>
      <div class="detail" @click="gotoSh()" style="cursor: pointer;">
        <div class="deInfo">
          <div class="left"><img src="../../assets/images/x_icon24.png" alt=""></div>
          <div class="right">
            <div class="title">售后服务</div>
            <div class="number">{{info.orderDetailsDto.afterSalesNumber}}<span>单</span></div>
          </div>
        </div>
      </div>
      
      <!-- 
      <div class="detail">
        <div class="deInfo">
          <div class="left"><img src="../../assets/images/x_icon17.png" alt=""></div>
          <div class="right">
            <div class="title">剩余额度</div>
            <div class="number">{{info.orderDetailsDto.lessKlimk}}<span>万元</span></div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- <div class="x_screen">
        <div class="left">
          <div class="others">
            <el-select
              v-model="zsybt"
              placeholder="请选择"
            >
              <el-option
                v-for="item in twoSelect"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        
        <button class="searchbtn" @click="beginSearch">查询</button>
      </div> -->
    <div class="two">
      <div class="left">
        <div class="title">
          <div class="tiLeft">产品下单数量</div>
          <div class="tiRight">
            <!-- <div class="detail" :class="type == '1' ? 'detailActive' : ''"  @click="changeleft('1')">精密板带</div>
            <div class="detail" :class="type == '2' ? 'detailActive' : ''"  @click="changeleft('2')">精密棒材</div>
            <div class="detail" :class="type == '3' ? 'detailActive' : ''"  @click="changeleft('3')">精密线材</div>
            <div class="detail" :class="type == '4' ? 'detailActive' : ''"  @click="changeleft('4')">精密切割丝</div> -->
            <!-- <el-select
              v-model="zsybt"
              placeholder="请选择"
              @change="initchart"
            >
              <el-option
                v-for="item in twoSelect"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
            <p style="color: #828593;font-size:16px;font-weight: normal;">统计时间范围：{{timeTip}}</p>
          </div>
        </div>
        <div class="chart">
          <div class="myCharts" id="myChartsLeft"></div>
        </div>
      </div>
      <div class="right">
        <div class="detail">
          <div class="title" style="display:flex">{{title}} <p style="font-weight: normal;color: #828593;font-size:16px;margin-left: auto;">统计时间范围：{{timeTip}}</p></div>
          <div class="chart">
            <div class="myCharts" id="myChartsBd"></div>
          </div>
        </div>
        <!-- <div class="detail">
          <div class="title">精密棒材</div>
          <div class="chart">
            <div class="myCharts" id="myChartsBc"></div>
          </div>
        </div>
        <div class="detail">
          <div class="title">精密线材</div>
          <div class="chart">
            <div class="myCharts" id="myChartsXc"></div>
          </div>
        </div>
        <div class="detail">
          <div class="title">精密切割丝</div>
          <div class="chart">
            <div class="myCharts" id="myChartsQgs"></div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
export default {
  // name: 'Index', 
  components: {

  },
  data(){
    return {
      info:'',
      type:'',
      twoSelect:[],
      zsybt:'',
      timeTip:'',
      usersListData:[
        {
          x:'1',
          xx:'1',
          xxx:'1',
          xxxx:'1',
          xxxxx:'1'
        }
      ]
    }
  },
  mounted() {
    this.$post(this.apiUrl + 'customer/customerPage/getConditionList').then((response1) => {
      let list = response1.data.zsybt
      let str
      for(let i in list){
        if(list[i].value == '棒材事业部'){
          str = '宁波博威合金材料股份有限公司棒材事业部'
        }else if(list[i].value == '线材事业部'){
          str = '宁波博威合金材料股份有限公司线材事业部'
        }else if(list[i].value == '板带事业部'){
          str = '宁波博威合金板带有限公司'
        }
        this.twoSelect.push({'label':str,'value':list[i].value})
      }
      this.twoSelect.unshift({
          'label':'全部',
          'value': ""
      })
      setTimeout(()=>{
        this.initchart()
      },200)
    })
    
  },
  created() {
    // this.$post(this.apiUrl + 'customer/customerPage/businessData').then((response) => {
    //   this.info = response.data
    //   this.changeleft(this.type)
    // })
  },
  methods: {
    export2Excel() {
      var that = this
      require.ensure([], () => {
        const { export_json_to_excel } = require('@/assets/js/export2Excel') //这里必须使用绝对路径，使用@/+存放export2Excel的路径
        const tHeader = ['用户名', '角色名字', '电话', '邮箱', '状态'] // 导出的表头名信息
        const filterVal = [
          'username',
          'role_name',
          'mobile',
          'email',
          'mg_state'
        ] // 导出的表头字段名，需要导出表格字段名
        const list = that.usersListData //这里对应你页面表格的数据
        list.forEach(e => {
          if (e.mg_state === false) {
            e.mg_state = '未启用'
          } else {
            e.mg_state = '已启用'
          }
        })
        const data = that.formatJson(filterVal, list)

        export_json_to_excel(tHeader, data, '下载数据excel') // 导出的表格名称，根据需要自己命名
      })
    },
    //格式转换，直接复制即可
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]))
    },

    changetype(zsybt){
      this.zsybt = zsybt
      this.initchart()
    },

    initchart(){
      this.$post(this.apiUrl + 'customer/customerPage/businessData',{zsybt:this.zsybt}).then((response) => {
        this.info = response.data
        this.title = response.data.title
        this.timeTip = response.data.timeTip
        setTimeout(()=>{
          this.changeleft(this.type)
          this.rightbd(response.data.sapFamilyDetails)
        },200)
        // this.changeleft(this.type)
        // this.rightbd(response.data.sapFamilyDetails)
      })
    },
    
    //开始搜索
    beginSearch() {
     console.log(this.checkTime);
      this.initchart()
    },

    changeleft(type){
      this.type = type
      let info = this.info
      console.log(info)
      // let precisionStrips
      let arrNew = []
      let monNew = []
      info.orderProducts.forEach(item => {
          arrNew.push(item.zvbelns)
          monNew.push(item.erdatRrm)
        })
      this.chartleft(arrNew,monNew)
    },

    chartleft(arrNew,monNew){
      let that = this;
      //左侧图表
      let optionLeft = {
        tooltip: {
          trigger: 'axis',
          formatter: '{a0}:{c0}t' 
        },
        lineStyle:{
          color:'#ED6C00'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: monNew,
          axisLine: {
            lineStyle: {
              color: "#828593",
              fontSize: '12',
            },
            margin:20,
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
              formatter:'{value} t'
          }
        },
        series: [
          {
            name: '下单数量',
            data: arrNew,
            type: 'line',
            itemStyle : {
              normal : {
                color:'#ED6C00'
              }
            },
          },
        ]
      };
      let chartLeft = that.$echarts.init(document.getElementById('myChartsLeft'));
      
      chartLeft.clear();
      chartLeft.setOption(optionLeft);
      
    },



    //板带饼图
    rightbd(list){
      let that = this;
      let datas = []
      list.forEach(item => {
        datas.push({value: item.zvbelns, name: item.title})
      })
      console.log(datas)
      let optionBd = {
        legend: {
          orient:"vertical",
          top: '25%',
          right: '5%',
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '60%'],
            center: ["35%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
              formatter:'{a|{d}}{c|%} \n {b|{b}}',  // 设置默认显示值
            },
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = [
                    '#ffbe0b',
                    '#ED6C00',
                    '#7d6ee4',
                    '#47bff1',
                    '#45cb85',
                    '#D2691E',
                    '#FFC0CB',
                    '#45cb85',
                    '#4793D4',
                    '#F9E056',
                    '#F99356',
                    '#F95661',
                    '#73BCA1',
                    '#45DAD5',
                    '#73BCA1',
                    '#DC57B8',
                    '#B2E157',
                    '#DC57B8',
                    '#4793D4'
                  ];
                  return colorList[colors.dataIndex];
                }
              },
            },
            emphasis: {
              label: {
                show: true,
                formatter: '{a|{d}}{c|%} \n {b|{b}}',
                textStyle:{ 
                  rich:{
                    a:{
                      fontSize: '48',
                      color:"#3A3A3C",
                      fontWeight: "600",
                    },
                    b:{
                      fontSize:'16',
                      color:"#3A3A3C",
                      fontWeight: "600",
                    },
                    c:{
                      fontSize:'16',
                      color:"#3A3A3C",
                      padding: [10,0,0,0], // 文字块的内边距 
                      fontWeight: "600",
                    }
                  },
                } 
              }
            },
            labelLine: {
              show: false
            },
            data: datas
          }
        ]
      };
      
      let chartBd = that.$echarts.init(document.getElementById('myChartsBd'));
      chartBd.clear()
      chartBd.setOption(optionBd);
      chartBd.dispatchAction({type: 'highlight',seriesIndex: 0,dataIndex: 0});
      chartBd.on('mouseover',(v) => {
            if(v.dataIndex != 0){
                chartBd.dispatchAction({
                    type: 'downplay',
                    seriesIndex: 0,
                    dataIndex: 0
                });
            }
        });
    },

    //棒材
    rightbc(list){
      let that = this;
      let datas = []
      list.forEach(item => {
        datas.push({value: item.zvbelns, name: item.title})
      })
      let optionBc = {
        legend: {
          orient:"vertical",
          top: '25%',
          right: '5%'
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '60%'],
            center: ["35%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
              formatter:'{a|{d}}{c|%} \n {b|{b}}',  // 设置默认显示值
            },
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = [
                    '#ffbe0b',
                    '#ED6C00',
                    '#7d6ee4',
                    '#47bff1',
                    '#45cb85',
                  ];
                  return colorList[colors.dataIndex];
                }
              },
            },
            emphasis: {
              label: {
                show: true,
                formatter: '{a|{d}}{c|%} \n {b|{b}}',
                textStyle:{ 
                  rich:{
                    a:{
                      fontSize: '48',
                      color:"#3A3A3C",
                      fontWeight: "600",
                    },
                    b:{
                      fontSize:'16',
                      color:"#3A3A3C",
                      fontWeight: "600",
                    },
                    c:{
                      fontSize:'16',
                      color:"#3A3A3C",
                      padding: [10,0,0,0], // 文字块的内边距 
                      fontWeight: "600",
                    }
                  },
                } 
              }
            },
            labelLine: {
              show: false
            },
            data: datas
          }
        ]
      };
      let chartBc = that.$echarts.init(document.getElementById('myChartsBc'));
      chartBc.setOption(optionBc);
    },

    //线材
    rightxc(list){
      let that = this;
      let datas = []
      list.forEach(item => {
        datas.push({value: item.zvbelns, name: item.title})
      })
      let optionXc = {
        legend: {
          orient:"vertical",
          top: '25%',
          right: '5%'
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '60%'],
            center: ["35%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
              formatter:'{a|{d}}{c|%} \n {b|{b}}',  // 设置默认显示值
            },
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = [
                    '#ffbe0b',
                    '#ED6C00',
                    '#7d6ee4',
                    '#47bff1',
                    '#45cb85',
                  ];
                  return colorList[colors.dataIndex];
                }
              },
            },
            emphasis: {
              label: {
                show: true,
                formatter: '{a|{d}}{c|%} \n {b|{b}}',
                textStyle:{ 
                  rich:{
                    a:{
                      fontSize: '48',
                      color:"#3A3A3C",
                      fontWeight: "600",
                    },
                    b:{
                      fontSize:'16',
                      color:"#3A3A3C",
                      fontWeight: "600",
                    },
                    c:{
                      fontSize:'16',
                      color:"#3A3A3C",
                      padding: [10,0,0,0], // 文字块的内边距 
                      fontWeight: "600",
                    }
                  },
                } 
              }
            },
            labelLine: {
              show: false
            },
            data: datas
          }
        ]
      };
      let chartXc = that.$echarts.init(document.getElementById('myChartsXc'));
      chartXc.setOption(optionXc);
    },

    //切割丝
    rightqgs(list){
      let that = this;
      let datas = []
      list.forEach(item => {
        datas.push({value: item.zvbelns, name: item.title})
      })
      let optionQgs = {
        legend: {
          orient:"vertical",
          top: '25%',
          right: '5%'
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '60%'],
            center: ["35%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
              formatter:'{a|{d}}{c|%} \n {b|{b}}',  // 设置默认显示值
            },
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = [
                    '#ffbe0b',
                    '#ED6C00',
                    '#7d6ee4',
                    '#47bff1',
                    '#45cb85',
                    '#79DDFE',
                    '#4793D4',
                    '#F9E056',
                    '#F99356',
                    '#F95661',
                    '#73BCA1',
                    '#45DAD5',
                    '#73BCA1',
                    '#DC57B8',
                    '#B2E157',
                    '#DC57B8',
                    '#4793D4'
                  ];
                  return colorList[colors.dataIndex];
                }
              },
            },
            emphasis: {
              label: {
                show: true,
                formatter: '{a|{d}}{c|%} \n {b|{b}}',
                textStyle:{ 
                  rich:{
                    a:{
                      fontSize: '48',
                      color:"#3A3A3C",
                      fontWeight: "600",
                    },
                    b:{
                      fontSize:'16',
                      color:"#3A3A3C",
                      fontWeight: "600",
                    },
                    c:{
                      fontSize:'16',
                      color:"#3A3A3C",
                      padding: [10,0,0,0], // 文字块的内边距 
                      fontWeight: "600",
                    }
                  },
                } 
              }
            },
            labelLine: {
              show: false
            },
            data: datas
            // [
            //   { value: 25, name: '纤焊材料' ,itemStyle: {color:'#ffbe0b'}},
            //   { value: 15, name: '黄铜' ,itemStyle: {color:'#ED6C00'}},
            //   { value: 20, name: '青铜' ,itemStyle: {color:'#7d6ee4'}},
            //   { value: 35, name: '铅白铜' ,itemStyle: {color:'#47bff1'}},
            //   { value: 5, name: '锌白铜' ,itemStyle: {color:'#45cb85'}},
            // ]
          }
        ]
      };
      let chartQgs = that.$echarts.init(document.getElementById('myChartsQgs'));
      chartQgs.setOption(optionQgs);
    },



    //前往订单查询
    gotoDdcx(){
      this.$router.push({
        path: "/Ddcx",
        name: "Ddcx",
        query: {zsybt:this.zsybt},
      },()=>{},()=>{});
    },
    //
    gotoDdcxfh(){
      this.$router.push({
        path: "/Ddcx",
        name: "Ddcx",
        query: {fhtyp :'A'},
      },()=>{},()=>{});
    },
    //前往售后
    gotoSh(){
      this.$router.push({
        path: "/Shfw",
        name: "Shfw",
        query: {zsybt:this.zsybt},
      },()=>{},()=>{});
    },
    //前往试样
    gotoSy(){
      this.$router.push({
        path: "/Syfw",
        name: "Syfw",
        query: {zsybt:this.zsybt},
      },()=>{},()=>{});
    },
  }
}
</script>

<style lang="less" scoped>
.contain{
  padding: 30px;
  // height: 100%;
  background: #f2f2f7;
}
.one{
  height: 120px;
  background-color: #ffffff;
  box-shadow: 0px 3px 7px 0px rgba(21, 21, 41, 0.1);
  border-radius: 4px;
  display: flex;
  // overflow-x: auto;
  white-space: nowrap;
}
.one .detail{
  display: inline-block;
  height: 98%;
  min-width: 160px;
  flex: 1;
  padding-right: 20px;
  border-right: 1px solid #eee;
}
.one .detail:last-of-type{
  border: 0;
}
.one .detail .deInfo{
  display: flex;
  align-items: center;
  height: 100%;
}
.one .detail .deInfo .left{
  width: 75px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.one .detail .deInfo .right .title{
  font-size: 14px;
  color: #828593;
  line-height: 30px;
  // font-weight: bold;
}
.one .detail .deInfo .right .number{
  font-size: 24px;
  color: #495057;
  line-height: 30px;
  font-weight: 800;
}
.one .detail .deInfo .right .number span{
  display: inline;
  font-size: 14px;
  font-weight: normal;
}
//通用滚动条样式
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: rgba(237, 109, 0, 0.3);
}
.two{
  display: flex;
  margin-top: 30px;
  height: 660px;
}
.two .title{
  height: 67px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #495057;
  font-weight: bold;
  border-bottom: 1px solid #eee;
}
.two .left{
  // flex: 4;
  width: calc(100% - 648px);
  background: #fff;
  background-color: #ffffff;
	box-shadow: 0px 3px 7px 0px rgba(21, 21, 41, 0.1);
	border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.two .left .title{
  display: flex;
  justify-content: space-between;
}
.two .left .title .tiRight{
  display: flex;
  align-items: center;
}
.two .left .title .tiRight .detail{
  width: 100px;
	height: 36px;
	border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
}
.two .left .title .tiRight .detail:hover{
  color: #ED6C00;
}
.two .left .title .tiRight .detailActive:hover{
  color: #fff;
}
.two .left .title .tiRight .detailActive{
  background-color: #ED6C00;
  box-shadow: 0px 3px 5px 0px rgba(237, 109, 0, 0.1);
  color: #fff;
}
.two .right{
  // flex: 3;
  width: 648px;
  display: flex;
  flex-wrap: wrap;
}
.two .right .detail{
  width: 100%;
  margin-left: 4%;
  background-color: #ffffff;
	box-shadow: 0px 3px 7px 0px rgba(21, 21, 41, 0.1);
	border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.two .right .detail:nth-of-type(1),
.two .right .detail:nth-of-type(2){
  margin-bottom: 0px;
}

.two .left .chart{
  flex: 1;
}
.two .right .chart{
  height: 580px;
}
.two .left .chart .myCharts,.two .right .chart .myCharts{
  width: 100%;
  height: 100%;
}

.x_screen {
  // height: 73px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  // justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  background: #fff;
  box-shadow: 0px 3px 7px 0px rgb(21 21 41 / 10%);
 
  margin-top: 20px;
}
.x_screen .left {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.x_screen .left .others {
  width: 240px;
  // margin-left: 20px;
}

.x_screen .right {
  width: 500px;
  margin-left: 20px;
}
.searchbtn{
    width: 100px;
    height: 40px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    background-color: #ED6C00;
    box-shadow: 0px 3px 5px 0px rgb(237 109 0 / 10%);
    color: #fff;
    border: 0;
    margin-left: 20px;
}

.leftp p{
  padding: 0px 10px;
  display: block;
  line-height: 30px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.leftp p.active{
  background: #ED6C00;
  color: #fff;
}
</style>
