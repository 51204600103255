import { render, staticRenderFns } from "./Xzzx.vue?vue&type=template&id=4e6a4d90&scoped=true"
import script from "./Xzzx.vue?vue&type=script&lang=js"
export * from "./Xzzx.vue?vue&type=script&lang=js"
import style0 from "./Xzzx.vue?vue&type=style&index=0&id=4e6a4d90&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e6a4d90",
  null
  
)

export default component.exports