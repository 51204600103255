<template>
  <div class="contain">
    <div class="title">账单服务</div>
    <div class="info">
      <div class="x_screen">
        <div class="left">
          <!-- <div class="date">
            <el-date-picker
              v-model="checkTime"
              type="monthrange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy.MM"
              value-format="yyyy-MM"
            >
            </el-date-picker>
          </div> -->
          <div class="others">
            <el-select
              v-model="form.kkber"
              placeholder="全部"
              @change="beginSearch"
            >
              <el-option
                v-for="item in twoSelect"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        
        <!-- <button class="searchbtn" @click="beginSearch">查询</button> -->
      </div>
      <div class="two">
        <!-- <div class="detail">
          <div class="deInfo">
            <div class="left"><img src="../../assets/images/x_icon16.png" alt=""></div>
            <div class="right">
              <div class="name">信用额度</div>
              <div class="content"><span>￥</span>{{info.klimk}}</div>
            </div>
          </div>
          <div class="deInfo">
          <div class="left"><img src="../../assets/images/x_icon17.png" alt=""></div>
            <div class="right">
              <div class="name">剩余额度</div>
              <div class="content"><span>￥</span>{{info.lessKlimk}}</div>
            </div>
          </div>
        </div> -->
        <!-- <div class="detail">
          <div class="deInfo">
            <div class="left"><img src="../../assets/images/x_icon18.png" alt=""></div>
            <div class="right">
              <div class="name">累计超期次数</div>
              <div class="content">{{info.defaultNum}}<span>次</span></div>
            </div>
          </div>
          <div class="deInfo">
          <div class="left"><img src="../../assets/images/x_icon21.png" alt=""></div>
            <div class="right">
              <div class="name">当前应付款</div>
              <div class="content"><span>￥</span>{{info.copeWithTotal}}</div>
            </div>
          </div>
        </div> -->
        <div class="detail">
          <div class="deInfo">
          <div class="left"><img src="../../assets/images/x_icon21.png" alt=""></div>
            <div class="right">
              <div class="name">当前应付款</div>
              <div class="content"><span>￥</span>{{info.copeWithTotal}}<span>万元</span></div>
            </div>
          </div>
          <div class="deInfo">
          <div class="left"><img src="../../assets/images/x_icon19.png" alt=""></div>
            <div class="right">
              <div class="name">当前超期金额</div>
              <div class="content" :style="info.defaultMoney > 0 ? 'color:red!important' : ''"><span>￥</span><b>{{info.defaultMoney}}</b><span>万元</span></div>
            </div>
          </div>
          <div class="deInfo">
          <div class="left"><img src="../../assets/images/x_icon20.png" alt=""></div>
            <div class="right">
              <div class="name">超期天数</div>
              <div class="content">{{info.defaultMoney == '0' ? '无':info.cqContent}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="one">
        <div class="left">
          <div class="name">超期统计</div>
          <div class="chart">
            <div class="myCharts" id="myChartsLeft"></div>
          </div>
        </div>
        <div class="right">
          <div class="name">账单记录
            <!-- <p class="leijinump">累计超期次数：{{sumnum}}次，累计超期金额：{{summoney}}万元</p> -->
          </div>
          <div class="righttitle">
            <p style="flex:1">统计月份</p>
            <!-- <p style="flex:1">超期次数</p> -->
            <p style="flex:1">超期天数</p>
            <p style="flex:2">超期金额</p>
            <!-- <p style="flex:2">当月待付款</p>
            <p style="flex:1">超期比例</p> -->
          </div>
          <div class="listall">
            <div class="listshow" :class="item.cqDays != 0?'colorred':''" v-for="(item,index) in overdueProportion" :key="index">
              <p style="flex:1">{{item.monthTime}}</p>
              <!-- <p style="flex:1">{{item.cqwhkNum}}</p> -->
              <p style="flex:1">{{item.cqDays}}</p>
              <p style="flex:2">{{item.sumCqk}}万元</p>
              <!-- <p style="flex:2">{{item.sumYue}}万元</p>
              <p style="flex:1">{{item.percentage}}</p> -->
            </div>
          </div>
          <!-- <div>
            <p class="leijinump">累计超期次数：{{sumnum}}次，累计超期金额：{{summoney}}元</p>
          </div> -->
        </div>
        <!-- <div class="right">
          <div class="name">未还款比例</div>
          <div class="chart">
            <div class="myCharts" id="myChartsRight"></div>
          </div>
          <div class="message">
            <div class="detail">
              <div class="deLeft"><div class="circle yellow"></div>已支付</div>
              <div class="deRight">￥{{billOutstandingDto.ysk}}</div>
            </div>
            <div class="detail">
              <div class="deLeft"><div class="circle" style="background:#ED6C00"></div>超期未还款</div>
              <div class="deRight">￥{{billOutstandingDto.cqwhk}}</div>
            </div>
          </div>
        </div> -->
      </div>
      <!-- <div class="two">
        <div class="detail">
          <div class="deInfo">
            <div class="left"><img src="../../assets/images/x_icon18.png" alt=""></div>
            <div class="right">
              <div class="name">累计超期次数</div>
              <div class="content">{{info.defaultNum}}<span>次</span></div>
            </div>
          </div>
          <div class="deInfo">
          <div class="left"><img src="../../assets/images/x_icon21.png" alt=""></div>
            <div class="right">
              <div class="name">当前应付款</div>
              <div class="content"><span>￥</span>{{info.copeWithTotal}}</div>
            </div>
          </div>
        </div>
        <div class="detail">
          <div class="deInfo">
          <div class="left"><img src="../../assets/images/x_icon19.png" alt=""></div>
            <div class="right">
              <div class="name">当前超期金额</div>
              <div class="content"><span>￥</span>{{info.defaultMoney}}</div>
            </div>
          </div>
          <div class="deInfo">
          <div class="left"><img src="../../assets/images/x_icon20.png" alt=""></div>
            <div class="right">
              <div class="name">超期天数</div>
              <div class="content">{{info.defaultMoney == '0' ? '无':info.cqContent}}</div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="three">所有数据仅供参考，一切以双方财务对账为准</div>
    </div>
  </div>
</template>

<script>
export default {
  // name: 'Index', 
  components: {

  },
  data(){
    return {
      billOutstandingDto:'',//饼图
      overdueProportion:'',//折线数据
      copeWithMonth: "",
      copeWithTotal: "",
      defaultMoney: "",
      defaultNum: "",
      klimk: "",
      lessKlimk: "",
      checkTime:'',
      info:'',
      twoSelect:[{
        id:'',
        name:'全部'
      },{
        id:1100,
        name:'宁波博威合金材料股份有限公司棒线事业部'
      },{
        id:3000,
        name:'宁波博威合金板带有限公司'
      }],
      form:{
        defaultEndDate: "",
        defaultStartDate: "",
        kkber: ''
      },
      summoney:0,
      sumnum:0
    }
  },
  created(){
    // this.$post(this.apiUrl + 'customer/customerPage/getZsybtList').then((response) => {

    // })
    this.initchart()
    
  },
  mounted() {

  },
  methods: {

    initchart(){
      this.$post(this.apiUrl + 'customer/customerPage/billService',this.form).then((response) => {
        this.billOutstandingDto = response.data.billOutstandingDto
        this.overdueProportion = response.data.overdueProportion
        let sumnum = 0
        let summoney = 0
        for(let i in this.overdueProportion){
          sumnum += this.overdueProportion[i].cqwhkNum * 1
          summoney += this.overdueProportion[i].sumCqk * 1
        }
        this.sumnum = sumnum
        this.summoney = summoney.toFixed(2)
        this.info = response.data

        let arrNew = []
        let monNew = []
        this.overdueProportion.forEach((item,index) => {
          if( item.monthTime != null){
           //console.log(item.percentage)
           let arrNewjson = {
              cqwhkNum: "",
              value: "",
              sumCqk: "",
              sumYue: ""
            }
            arrNewjson.cqwhkNum = item.cqwhkNum
            arrNewjson.value = item.cqwhkNum
            arrNewjson.percentage = item.percentage
            arrNewjson.sumCqk = item.sumCqk
            arrNewjson.sumYue = item.sumYue
            arrNew.push(arrNewjson)
            monNew.push(item.monthTime)
          }
        })

        setTimeout(() => {
          this.leftchar(arrNew,monNew)
        }, 100);
      })
    },

    //开始搜索
    beginSearch() {
     console.log(this.checkTime);
      this.form.defaultStartDate = this.checkTime[0]
      this.form.defaultEndDate = this.checkTime[1]
      this.initchart()
    },

    leftchar(arrNew,monNew){
      let that = this;
      let overdueProportion = that.overdueProportion
      let optionLeft = {
      tooltip: {
        trigger: 'axis',
        formatter:function(overdueProportion){
          // console.log(params);
          var html = overdueProportion[0].name + "<br/>";
          for(var i=0;i<overdueProportion.length;i++){
            html +=
            overdueProportion[i].marker +"超期次数 "+
            overdueProportion[i].value +"<br/>" +
            overdueProportion[i].marker +"当月超期金额 " + overdueProportion[i]['data'].sumCqk+"万元<br/>" +
            overdueProportion[i].marker +"当月待付款 " + overdueProportion[i]['data'].sumYue+"万元<br/>" +
            overdueProportion[i].marker +"超期比例 " + overdueProportion[i]['data'].percentage
          }
          return html 
        },
      },
      grid: {//设置柱状图位置 上下左右距离也可以用 y y2 x x2 表示 可以用百分比表示也可以直接数字 例如 x:20

    left: '3%',

    right: '5%',

    bottom: '10%',

    containLabel: true // 表示grid 区域是否包含坐标轴的刻度标签 常用于『防止标签溢出』的场景，标签溢出指的是，标签长度动态变化时，可能会溢出容器或者覆盖其他组件

    },
      lineStyle:{
        color:'#ED6C00'
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: monNew,
        axisLine: {
          lineStyle: {
            color: "#828593",
            fontSize: '12',
          },
          margin:20,
        },
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: '超期比例',
          data: arrNew,
          type: 'line',
          itemStyle : {
            normal : {
              color:'#ED6C00'
            }
          },
        },
      ]
    };
    let chartLeft = that.$echarts.init(document.getElementById('myChartsLeft'));
    chartLeft.setOption(optionLeft);
    },

    rightchar(cqwhkPro,yskPro){
      let that = this;
      let optionRight = {
      series: [
        {
          type: 'pie',
          radius: ['60%', '80%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
            formatter:'{a|{d}}{c|%} \n {b|{b}}',  // 设置默认显示值
          },
          emphasis: {
            label: {
              show: true,
              formatter: '{a|{d}}{c|%} \n {b|{b}}',
              textStyle:{ 
                rich:{
                  a:{
                    fontSize: '48',
                    color:"#3A3A3C",
                    fontWeight: "600",
                  },
                  b:{
                    fontSize:'16',
                    color:"#3A3A3C",
                    fontWeight: "600",
                  },
                  c:{
                    fontSize:'16',
                    color:"#3A3A3C",
                    padding: [10,0,0,0], // 文字块的内边距 
                    fontWeight: "600",
                  }
                },
              } 
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: cqwhkPro, name: '超期未还比例' ,itemStyle: {color:'#ED6C00'}},
            { value: yskPro, name: '已支付比例' ,itemStyle: {color:'#ffbe0b'}},
          ]
        }
      ]
    };
    let chartRight = that.$echarts.init(document.getElementById('myChartsRight'));
    chartRight.setOption(optionRight);
    },
  }
}
</script>

<style lang="less" scoped>
.contain{
  display: flex;
  flex-direction: column;
  // height: 100%;
}
.contain .title{
  flex-shrink: 0;
  height: 50px;
  background-color: #ffffff;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.06);
  font-size: 18px;
  color: #495057;
  padding: 0 35px;
  display: flex;
  align-items: center;
  font-weight: bold;
}  
.contain .info{
  flex: 1;
  background: #f2f2f7;
  padding: 30px 30px 0;
  display: flex;
  flex-direction: column;
}
.contain .info .one{
  display: flex;
}
.contain .info .one .left{
  flex: 2;
  background: #fff;
  background-color: #ffffff;
	box-shadow: 0px 3px 7px 0px rgba(21, 21, 41, 0.1);
	border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 550px;
  width: 49%;
}
.contain .info .one .right{
  margin-left: 30px;
  flex: 2;
  background-color: #ffffff;
	box-shadow: 0px 3px 7px 0px rgba(21, 21, 41, 0.1);
	border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 49%;
}
.contain .info .one .name{
  height: 67px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #495057;
  font-weight: bold;
  border-bottom: 1px solid #eee;
}
.contain .info .one .left .chart{
  flex: 1;
}
.contain .info .one .right .chart{
  height: 290px;
  border-bottom: 1px dashed #eee;
}
.contain .info .one .left .chart .myCharts,.contain .info .one .right .chart .myCharts{
  width: 100%;
  height: 100%;
}
.contain .info .one .right .message{
  padding: 0 20px;
}
.contain .info .one .right .message .detail{
  height: 60px;
  border-bottom: 1px dashed #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #3A3A3C;
  font-weight: bold;
}
.contain .info .one .right .message .detail .deLeft{
  display: flex;
  align-items: center;
}
.contain .info .one .right .message .detail .deLeft .circle{
  width: 8px;
	height: 8px;
  border-radius: 50%;
  margin-right: 10px;
}
.contain .info .one .right .message .detail .deLeft .circle.orange{
  background: #ED6C00;
}
.contain .info .one .right .message .detail .deLeft .circle.yellow{
  background: #ffbe0b;
}
.contain .info .one .right .message .detail .deLeft .circle.green{
  background: #45cb85;
}

.contain .info .two{
  display: flex;
  margin-bottom: 20px;
}
.contain .info .two .detail{
  flex: 1;
  height: 100px;
	background-color: #ffffff;
	box-shadow: 0px 3px 7px 0px	rgba(21, 21, 41, 0.1);
	border-radius: 4px;
  display: flex;
  //flex-direction: column;
  //margin-right: 30px;
}
.contain .info .two .detail:last-of-type{
  margin-right: 30px;
}
.contain .info .two .detail .deInfo{
  flex: 1;
  display: flex;
}
.contain .info .two .detail .deInfo:first-of-type .right{
  border-bottom: 1px solid #eeeeee;
}
.contain .info .two .detail .deInfo .left{
  width: 95px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.contain .info .two .detail .deInfo .right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
.contain .info .two .detail .deInfo .right .name{
  font-size: 14px;
  color: #828593;
  // font-weight: bold;
  line-height: 30px;
}
.contain .info .two .detail .deInfo .right .content{
  font-size: 24px;
  color: #495057;
  font-weight: 800;
  line-height: 30px;
}
.contain .info .two .detail .deInfo .right .content span{
  font-size: 14px;
  display: inline;
  font-weight: normal;
}
.contain .info .two .detail:last-of-type{
  margin-right: 0;
}
.contain .info .three{
  height: 86px;
  padding-top: 20px;
  font-size: 14px;
  color: #828593;
}
.x_screen {
  height: 73px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  // justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  background: #fff;
  box-shadow: 0px 3px 7px 0px rgb(21 21 41 / 10%);
  margin-bottom: 20px;
}
.x_screen .left {
  display: flex;
  align-items: center;
}

.x_screen .left .others {
  width: 400px;
  margin-left: 0px;
}

.x_screen .left .others /deep/ .el-select{
  width: 100%;
}

.x_screen .right {
  width: 500px;
  margin-left: 20px;
}
.searchbtn{
    width: 100px;
    height: 40px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    background-color: #ED6C00;
    box-shadow: 0px 3px 5px 0px rgb(237 109 0 / 10%);
    color: #fff;
    border: 0;
    margin-left: 20px;
}

.righttitle{
  background: #F3F6F9;
  height: 45px;
  display: flex;
  padding-right: 8px;
  box-sizing: border-box;
  padding-left: 20px;
  p{
    //flex: 2;
    white-space: nowrap;
    color: #878A99;
    //padding-left: 25px;
    display: block;
    box-sizing: border-box;
    line-height: 45px;
  }
}

.listall{
  overflow-y: scroll;
  //padding-right: 8px;
  height: 400px;
  padding-left: 20px;
  .listshow{
    height: 54px;
    display: flex;
    border-bottom: 1px solid #eeeeee;
    p{
      //padding-left: 26px;
      line-height: 53px;
    }
  }
}

//通用滚动条样式
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: rgba(237, 109, 0, 0.3);
}

.leijinump{
  box-sizing: border-box;
  padding-left: 20px;
  line-height: 40px;
  font-size: 14px;
  margin-left: auto;
  color: red;
  //margin-top: 14px;
}
.colorred p{
  color: red!important;
}
</style>
