import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Index from '../views/admin/Index.vue'
import Hzcpmlcx from '../views/admin/Hzcpmlcx.vue'
import Ddcx from '../views/admin/Ddcx.vue'
import DdcxDetail from '../views/admin/Ddcx-detail.vue'
import Bhkccx from '../views/admin/Bhkccx.vue'
import Syfw from '../views/admin/Syfw.vue'
import Zdfw from '../views/admin/Zdfw.vue'
import Fpcx from '../views/admin/Fpcx.vue'
import Gylgl from '../views/admin/Gylgl.vue'
import Shfw from '../views/admin/Shfw.vue'
import Yfstmh from '../views/admin/Yfstmh.vue'
import Xzzx from '../views/admin/Xzzx.vue'
import Wdsc from '../views/admin/Wdsc.vue'
import Lljl from '../views/admin/Lljl.vue'
import Zhgl from '../views/admin/Zhgl.vue'
import onlinefeed from '../views/admin/onlinefeed.vue'
import aboutbw_honor from '../views/aboutbw_honor.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import( '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import( '../views/login.vue')
  },
  {
    path: '/logingw',
    name: 'logingw',
    component: () => import( '../views/logingw.vue')
  },
  {
    path: '/logingw_re',
    name: 'logingw_re',
    component: () => import( '../views/logingw_re.vue')
  },
  {
    path: '/login_hzhb',
    name: 'login_hzhb',
    component: () => import( '../views/login_hzhb.vue')
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import( '../views/registration.vue')
  },
  {
    path: '/forgotten_password',
    name: 'forgotten_password',
    component: () => import( '../views/forgotten_password.vue')
  },
  {
    path: '/fw_after_sales',
    name: 'fw_after_sales',
    component: () => import( '../views/fw_after_sales.vue')
  },
  {
    path: '/fw_download',
    name: 'fw_download',
    component: () => import( '../views/fw_download.vue')
  },
  {
    path: '/solutions',
    name: 'solutions',
    component: () => import( '../views/solutions.vue')
  },
  {
    path: '/solutions_xq',
    name: 'solutions_xq',
    component: () => import( '../views/solutions_xq.vue')
  },
  {
    path: '/investor_relations',
    name: 'investor_relations',
    component: () => import( '../views/investor_relations.vue')
  },
  {
    path: '/interim_ann',
    name: 'interim_ann',
    component: () => import( '../views/interim_ann.vue')
  },
  {
    path: '/investment_advice',
    name: 'investment_advice',
    component: () => import( '../views/investment_advice.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import( '../views/news.vue')
  },
  {
    path: '/news_zh',
    name: 'news_zh',
    component: () => import( '../views/news_zh.vue')
  },
  {
    path: '/news_out',
    name: 'news_out',
    component: () => import( '../views/news_out.vue')
  },
  {
    path: '/site_map',
    name: 'site_map',
    component: () => import( '../views/site_map.vue')
  },
  {
    path: '/our_brands',
    name: 'our_brands',
    component: () => import( '../views/our_brands.vue')
  },
  {
    path: '/news_date',
    name: 'news_date',
    component: () => import( '../views/news_date.vue')
  },
  {
    path: '/privacy_zc',
    name: 'privacy_zc',
    component: () => import( '../views/privacy_zc.vue')
  },
  {
    path: '/aboutbw',
    name: 'aboutbw',
    component: () => import( '../views/aboutbw.vue')
  },
  {
    path: '/aboutbw_honor',
    name: 'aboutbw_honor',
    component: () => import( '../views/aboutbw_honor.vue')
  },
  {
    path: '/aboutbw_qywh',
    name: 'aboutbw_qywh',
    component: () => import( '../views/aboutbw_qywh.vue')
  },
  {
    path: '/aboutbw_rlzy',
    name: 'aboutbw_rlzy',
    component: () => import( '../views/aboutbw_rlzy.vue')
  },
  {
    path: '/aboutbw_pxfz',
    name: 'aboutbw_pxfz',
    component: () => import( '../views/aboutbw_pxfz.vue')
  },
  {
    path: '/aboutbw_shzp',
    name: 'aboutbw_shzp',
    component: () => import( '../views/aboutbw_shzp.vue')
  },
  {
    path: '/social_good',
    name: 'social_good',
    component: () => import( '../views/social_good.vue')
  },
  {
    path: '/social_responsibility',
    name: 'social_responsibility',
    component: () => import( '../views/social_responsibility.vue')
  },
  {
    path: '/organizational_structure',
    name: 'organizational_structure',
    component: () => import( '../views/organizational_structure.vue')
  },
  {
    path: '/sales_network',
    name: 'sales_network',
    component: () => import( '../views/sales_network.vue')
  },
  {
    path: '/bw_rd',
    name: 'bw_rd',
    component: () => import( '../views/bw_rd.vue')
  },
  {
    path: '/product_list',
    name: 'product_list',
    component: () => import( '../views/product_list.vue')
  },
  {
    path: '/copyright_notice',
    name: 'copyright_notice',
    component: () => import( '../views/copyright_notice.vue')
  },
  {
    path: '/product_one',
    name: 'product_one',
    component: () => import( '../views/product_one.vue'),
    meta: { keepAlive: true,isBack:false }
  },
  {
    path: '/product_two',
    name: 'product_two',
    component: () => import( '../views/product_two.vue')
  },
  {
    path: '/aboutbwdetail',
    name: 'aboutbwdetail',
    component: () => import( '../views/aboutbwdetail.vue')
  },{
    path: '/screening',
    name: 'screening',
    component: () => import( '../views/screening.vue')
  },{
    path: '/searchview',
    name: 'searchview',
    component: () => import( '../views/searchview.vue')
  }
  ,{
    path: '/indexadmin',
    name: 'indexadmin',
    component: () => import( '../views/admin/indexadmin.vue'),
    redirect:"/Index",
    children:[
      {
        path: '/Index',
        name: 'Index',
        component: Index
      },
      {
        path: '/Hzcpmlcx',
        name: 'Hzcpmlcx',
        component: Hzcpmlcx
      },
      {
        path: '/Ddcx',
        name: 'Ddcx',
        component: Ddcx,
        meta: { keepAlive: true,isBack:false }
      },
      {
        path: '/DdcxDetail',
        name: 'DdcxDetail',
        component: DdcxDetail
      },
      {
        path: '/Bhkccx',
        name: 'Bhkccx',
        component: Bhkccx
      },
      {
        path: '/Syfw',
        name: 'Syfw',
        component: Syfw
      },
      {
        path: '/Zdfw',
        name: 'Zdfw',
        component: Zdfw
      },
      {
        path: '/Fpcx',
        name: 'Fpcx',
        component: Fpcx
      },
      {
        path: '/Gylgl',
        name: 'Gylgl',
        component: Gylgl
      },
      {
        path: '/Shfw',
        name: 'Shfw',
        component: Shfw
      },
      {
        path: '/Yfstmh',
        name: 'Yfstmh',
        component: Yfstmh
      },
      {
        path: '/Xzzx',
        name: 'Xzzx',
        component: Xzzx
      },
      {
        path: '/Wdsc',
        name: 'Wdsc',
        component: Wdsc
      },
      {
        path: '/Lljl',
        name: 'Lljl',
        component: Lljl
      },
      {
        path: '/Zhgl',
        name: 'Zhgl',
        component: Zhgl
      },
      {
        path: '/onlinefeed',
        name: 'onlinefeed',
        component: onlinefeed
      }
    ]
  },

]

const router = new VueRouter({
  scrollBehavior(to,from,saveTop){
    if(to.name != 'bw_rd') {
      if(saveTop){
          return saveTop;
        }else{
          return {x:0,y:0}
      }}
    },
  mode: 'hash',
  // base: '/en/',
  routes
})

export default router

// registration   注册
// forgotten_password 忘记密码
// login 登录 验证码/密码
// fw_after_sales 服务与支持 售后服务
// fw_download  服务与支持 下载
// solutions  解决方案
// solutions_xq 解决方案详情
// investor_relations 投资者关系
// interim_ann 媒体中心 投资者关系 临时公告
// investment_advice 媒体中心 投资者关系 投资咨询
// news 新闻中心
// site_map 网站地图
// our_brands 我们的品牌
// news_date 新闻详情
// privacy_zc 隐私政策
// aboutbw 走进博威
// aboutbw_qywh 走进博威 企业文化
// aboutbw_rlzy 走进博威 人力资源
// aboutbw_pxfz 走进博威 培训发展
// aboutbw_shzp 走进博威 社会招聘
// social_good 社会公益
// social_responsibility 社会责任
// organizational_structure 公司介绍
// sales_network 销售网络
// bw_rd 博威研发
// product_list 博威产品列表
// copyright_notice 版权声明
