<template>
  <div class="contain">
    <div class="info">
      <div class="x_title">合作产品目录查询
        <p data-v-619f20c8="" style="color: rgb(130, 133, 147); font-size: 16px; margin-left: auto; font-weight: normal;">统计时间范围：2022-01-01 - {{nowtime}}</p>
      </div>
      <div class="x_screen">
            <el-select
              v-model="form.zsybt"
              clearable
              placeholder="请选择事业部"
            >
              <el-option
                v-for="item in twoSelect"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="form.wgbez"
              filterable 
              clearable
              placeholder="请选择产品大类"
            >
              <el-option
                v-for="item in oneSelect"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- <el-input
            placeholder="请输入产品牌号搜索"
            prefix-icon="el-icon-search"
            v-model="form.zkhph"
          >
          </el-input>
          <el-input
            placeholder="请输入博威牌号搜索"
            prefix-icon="el-icon-search"
            v-model="form.ewbez"
          >
          </el-input>
          <el-input
            placeholder="请输入产品描述搜索"
            prefix-icon="el-icon-search"
            v-model="form.arktx"
          >
          </el-input> -->
          <el-input
            placeholder="请输入关键词搜索"
            prefix-icon="el-icon-search"
            v-model="form.keywords"
          >
          </el-input>
          <button class="searchbtn" @click="getlist">查询</button>
          <button class="searchbtn" @click="showload">导出</button>
          </div>
      <div class="x_thead">
        <div class="detail f1"><span>产品大类</span></div>
        <div class="detail f1"><span>物料号(客户)</span></div>
        <div class="detail f2"><span>产品描述</span></div>
        <div class="detail f1"><span>产品牌号</span></div>
        <!-- <div class="detail f1">博威牌号</div> -->
        <div class="detail f1"><span>规格</span></div>
        <div class="detail f1"><span>事业部</span></div>
        <div class="detail f1"><span>国标</span></div>
        <div class="detail f1"><span>美标</span></div>
        <div class="detail f1"><span>欧标</span></div>
        <div class="detail f1"><span>日标</span></div>
        <div class="detail f1"><span>操作</span></div>
        <div class="line"></div>
      </div>
      <div class="x_tbody" v-loading="loading">
        <div class="x_td" v-for="(item, index) in list" :key="index">
          <div class="detail f1"><span>{{item.wgbez}}</span></div>
          <div class="detail f1"><span>{{item.kdmat}}</span></div>
          <div class="detail f2"><span>{{item.arktx}}</span></div>
          <!-- <div class="detail f1"><span>{{item.matnr}}</span></div> -->
          <div class="detail f1"><span>{{item.zkhph}}</span></div>
          <!-- <div class="detail f1"><span>{{item.ewbez}}</span></div> -->
          <div class="detail f1"><span>{{item.zgge}}</span></div>
          <div class="detail f1"><span>{{item.zsybt}}</span></div>
          <div class="detail f1"><span>{{item.nationalStandard}}</span></div>
          <div class="detail f1"><span>{{item.americanStandard}}</span></div>
          <div class="detail f1"><span>{{item.europeanStandard}}</span></div>
          <div class="detail f1"><span>{{item.japaneseStandard}}</span></div>
          <div class="detail f1">
            <div class="deBtn" @click="openXj(1)">
              <img src="../../assets/images/x_icon0.png" alt="" />询价
            </div>
          </div>
        </div>
      </div>
      <div class="x_bottom">
        <div class="left"><span style="color:#ED6C00">共{{total}}条</span>,所有数据仅供参考，一切以双方财务对账为准</div>
        <div class="right">
          <el-pagination background layout="prev, pager, next , jumper" :total="total" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
      <!-- 询价弹窗 -->
      <transition name="el-fade-in-linear">
        <div class="xjPop" v-show="isOpenXj">
          <div class="popInfo">
            <div class="top">
              <span>询价单</span>
              <img src="../../assets/images/x_close.png" alt="" @click="closeXj">
            </div>
            <div class="middle">
              <div class="one">
                <div class="left">询价单</div>
                <div class="right" @click="addXj">新增询价</div>
              </div>
              <div class="two">
                <div class="deOne">商品名称</div>
                <div class="deTwo">数量</div>
                <div class="deTwo">单位</div>
                <div class="deThree">备注</div>
                <div class="deFour"></div>
              </div> 
              <div class="three">
                <div class="detail" v-for="(item,index) in orders" :key="index">
                  <div class="deOne">
                    <el-input v-model="item.productName" placeholder="请输入商品名称"></el-input>
                  </div>
                  <div class="deTwo">
                    <el-input v-model="item.productNum" placeholder="请输入数量"></el-input>
                  </div>
                  <div class="deTwo">
                    <el-select v-model="item.productUnit" placeholder="请选择">
                      <el-option
                        v-for="sitem in unitList"
                        :key="sitem.id"
                        :label="sitem.name"
                        :value="sitem.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div class="deThree">
                    <el-input v-model="item.productRemark" placeholder="请填写备注，如：型号、性能、用途等"></el-input>
                  </div>
                  <div class="deFour">
                    <div class="fourBtn" @click="delXj(index)"><img src="../../assets/images/x_del.png" alt="">删除</div>
                  </div>
                </div>
              </div>
              <div class="four">联系方式</div>
              <div class="five">
                <div class="deOne">联系人</div>
                <div class="deTwo">手机号</div>
                <div class="deThree">公司名称</div>
              </div>
              <div class="six">
                <div class="deOne">
                  <el-input v-model="xjform.username" placeholder="请填写联系人"></el-input>
                </div>
                <div class="deTwo">
                  <el-input v-model="xjform.phone" placeholder="请填写手机号"></el-input>
                </div>
                <div class="deThree">
                  <el-input v-model="xjform.company" placeholder="请填写公司名称"></el-input>
                </div>
              </div>
              <div class="seven">
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  :action="upurl + 'customer/customerPage/CustomerUpload'"
                  :headers="{Authorization:Authorization}"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  :on-success="yyzzUpload"
                  >
                  <el-button slot="trigger" size="small" type="primary" class="x_upLoad">添加附件</el-button>
                </el-upload>
              </div>
            </div>
            <div class="bottom">
              <div class="left">
                提交询价后，博威合金会1~2个工作日内与您联系，请注意接听，您也可以直接拨打电话咨询
                <img src="../../assets/images/x_phone.png" alt=""><span>400 9262 798</span>
              </div>
              <div class="right">
                <div class="cancel" @click="closeXj">取消</div>
                <div class="submit" @click="submitxj">提交询价</div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div class="popdc" @click="hidedown" v-if="is_dc" v-loading="loading1">
      <div class="popdcview" @click.stop="" >
          <p>选择要导出的数据</p>
          <div>
            <p @click="download(1)">全部导出</p>
            <p @click="download(2)">当前页导出</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
import axios from 'axios'
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Authorization'] = 'Bearer ' + localStorage.getItem('token');
// axios.defaults.headers.post['application/json'] = 'Bearer ' + localStorage.getItem('token');
export default {
  //   name: 'Index',
  components: {},
  data() {
    return {
      is_dc:false,
      loading: true,
      isOpenXj:false,//是否打开询价弹窗
      loading1: false,
      orders:[
        {
          productName:"",//商品名称
          productNum:"1",//数量
          productUnit:"1",//选中单位
          productRemark:"",//备注
        },
      ],//询价单列表
      imageList:[],
      xjform:{
        username:"",//联系人
        phone:"",//手机号
        company:"",//公司名称
        productVos:'',
        fileUrlL:''
      },
      contact_name:"",//联系人
      contact_phone:"",//手机号
      contact_company:"",//公司名称
      fileList:[],//附件列表
      form:{
          pageNum: 1,
          pageSize: 10,
          zsybt:'',
          wgbez:'',
          zkhph:'',
          ewbez:'',
          arktx:'',
          matnr:'',
          keywords:''
          // customerNumber:localStorage.getItem('customerNumber')
      },
      list:'',
      total:'',
      twoSelect:[],
      oneSelect:[],

      unitList:[{
        id:'1',
        name:'千克'
      },{
        id:'2',
        name:'吨'
      }],

      items: [
          { '姓名': '谢国庆', '年龄': 29, '电话': '13697653219' },
          { '姓名': '吕小果', '年龄': 25, '电话': '13235789213' },
          { '姓名': '宋阿美', '年龄': 27, '电话': '13756776977' },
          { '姓名': '蒋铁柱', '年龄': 33, '电话': '15003373377' }
        ],

        nowtime:''


    };
  },
  created() {
    this.nowtime = this.yest(Date.now())
    this.upurl = this.apiUrl
    this.Authorization = 'Bearer ' + localStorage.getItem('token')
    this.$post(this.apiUrl + 'customer/customerPage/getConditionList').then((response1) => {
      //this.twoSelect = response1.data.zsybt
      let list = response1.data.zsybt
      let str
      for(let i in list){
        if(list[i].value == '棒材事业部'){
          str = '宁波博威合金材料股份有限公司棒材事业部'
        }else if(list[i].value == '线材事业部'){
          str = '宁波博威合金材料股份有限公司线材事业部'
        }else if(list[i].value == '板带事业部'){
          str = '宁波博威合金板带有限公司'
        }
        this.twoSelect.push({'label':str,'value':list[i].value})
      }
      this.twoSelect.unshift({
          'label':'全部',
          'value': ""
      })
      this.oneSelect = response1.data.wgbez
    })
    this.getlist()
  },
  methods: {
    yest (now) {
        let year = new Date(now).getFullYear();
        let month = new Date(now).getMonth() + 1;
        let date = new Date(now).getDate() - 1;
        if (month < 10) month = "0" + month;
        if (date < 10) date = "0" + date;
        return year + "-" + month + "-" + date;
      },
      // download() {
      //   this.form.pageSize = 10000
      //   this.$post(this.apiUrl + 'customer/customerPage/dwdSapCooperateDirectoryExport',this.form).then(res => {
      //     let blob = new Blob([res], { type: "application/vnd.ms-excel" }); // res就是接口返回的文件流了
      //     let objectUrl = URL.createObjectURL(blob);
      //     window.location.href = objectUrl;
      //   });
      // },

    //     download() {
    //       this.form.pageSize = 10000
    //   this.$exexl(this.apiUrl + 'customer/customerPage/dwdSapCooperateDirectoryExport',this.form)
    //     .then((res) => {
    //       this.$message({
    //         message: "导出成功",
    //         type: "success",
    //       });
    //       console.log(res)
    //       let objectUrl = window.URL.createObjectURL(new Blob([res]));
    //       const elink = document.createElement('a');
    //       elink.download = `${this.tableTitle}.xlsx`; //下载文件名称,
    //       elink.style.display = 'none';
    //       elink.href = objectUrl;
    //       document.body.appendChild(elink);
    //       elink.click();
    //       document.body.removeChild(elink);
    //       window.URL.revokeObjectURL(elink.href); // 释放URL 对象
    //     })
    //     .catch(() => {
    //       this.loading = false;
    //     });
    // },

      showload(){
        this.is_dc = true
      },

      hidedown(){
        this.is_dc = false
      },

    
      download (type) {
        // this.form.pageSize = 20000
        let pnum = this.form.pageNum
        if(type == 1){
          this.form.pageSize = 20000
          this.form.pageNum = 1
        }
        this.loading1 = true
        this.$exexl(this.apiUrl + 'customer/customerPage/dwdSapCooperateDirectoryExport',this.form).then((response) => {
          this.form.pageSize = 10
          if(type == 1){
            this.form.pageSize = 10
            this.form.pageNum = pnum
          }
          const blob = new Blob([response])
          const downloadElement = document.createElement('a')
          const href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = '合作产品目录.xlsx' // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
          this.loading1 = false
          this.is_dc = false
        })
      },

    getlist(){
      this.loading = true
      this.$post(this.apiUrl + 'customer/customerPage/dwdSapCooperateDirectory',this.form).then((response) => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    handleCurrentChange(val){
        this.form.pageNum = val
        this.getlist()
    },
    //打开询价弹窗
    openXj(id){
      console.log(id);
      let that = this;
      that.isOpenXj = true;
    },
    //删除询价产品
    delXj(index){
      let that = this;
      let orders = that.orders;
      orders.splice(index,1);
    },
    //新增询价
    addXj(){
      let that = this;
      let orders = that.orders;
      let str = {
        productName:"",//商品名称
        productNum:"10",//数量
        productUnit:"1",//选中单位
        productRemark:"",//备注
      };
      orders.push(str)
    },
    //上传附件
    //上传附件
    handleRemove(file, fileList) {
        this.imageList= fileList
        let listxx = []
        this.imageList.forEach(item => {
            listxx.push(item.response.url)
        })
        this.xjform.fileUrl = listxx
    },

    yyzzUpload(res, file, fileList) {
      if(res.code !== 200){
        return this.$message.error(res.msg)
      }
      
      this.imageList = fileList
      console.log(this.imageList)
      let formImgList = []
      this.imageList.forEach(item => {
        formImgList.push(item.response.url)
      })
      this.xjform.fileUrl = formImgList
    },
    handlePreview(file) {
      console.log(file);
    },
    //关闭询价
    closeXj(){
      this.isOpenXj = false;
    },

    submitxj(){
      if(this.xjform.fileUrl){
        this.xjform.fileUrl = this.xjform.fileUrl.toString()
      }
      this.xjform.productVos = JSON.stringify( this.orders );
      this.$post(this.apiUrl + 'customer/customerPage/addInquirySheet',this.xjform).then((response) => {
        if(response.code == 200){
          this.$message({
              message: '提交成功！',
              type: 'success'
          });
          this.isOpenXj = false;
        }else{
          this.$message({
              message: response.msg,
              type: 'error'
          });
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.contain {
  // height: 100%;
  padding: 30px 30px 50px 30px;
  background: #f2f2f7;
}
.contain .info {
  background-color: #ffffff;
  box-shadow: 0px 3px 7px 0px rgba(21, 21, 41, 0.1);
  border-radius: 4px;
}
.x_title {
  height: 72px;
  padding: 0 16px;
  font-size: 18px;
  color: #495057;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-bottom: 1px solid #e6e6e6;
}
.x_thead {
  display: flex;
  align-items: center;
  height: 45px;
  background: #f3f6f9;
}
.x_thead .detail {
  font-size: 14px;
  color: #878a99;
  padding-left: 26px;
  display: flex;
  align-items: center;
  height: 100%;
}
.x_thead .line {
  width: 8px;
  height: 100%;
}
.f1 {
  flex: 1;
}
.f2 {
  flex: 2;
}
.f3 {
  flex: 3;
}
.f4 {
  flex: 4;
}
.x_tbody {
  flex: 1;
  overflow-y: auto;
}
.x_tbody .x_td {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
}
.x_tbody .x_td:last-of-type{
  border-bottom: 0;
}
.x_tbody .x_td .detail {
  font-size: 14px;
  color: #212529;
  display: flex;
  padding: 20px 0 20px 26px;
  align-items: center;
  min-height: 53px;
  text-align: justify;
}

.x_tbody .x_td .detail span{
  text-align: left;
  word-break: break-all;
}
.x_tbody .x_td .detail .deBtn {
  padding: 0 8px;
  height: 22px;
  background-color: #fff1e5;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ED6C00;
  cursor: pointer;
}
.x_tbody .x_td .detail .deBtn img {
  margin-right: 6px;
}
.x_bottom {
  height: 74px;
  display: flex;
  align-items: center;
  padding: 0 21px 14px;
  border-top: 1px solid #e6e6e6;
  justify-content: space-between;
}
.x_bottom .left {
  font-size: 14px;
  color: #828593;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ED6C00;
}
/deep/.el-pagination.is-background .el-pager li:hover {
  color: #ED6C00!important;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #fff!important;
}
//通用滚动条样式
::-webkit-scrollbar {
  width: 8px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: rgba(237, 109, 0, 0.3);
  cursor: pointer;
}


// 询价弹窗
.xjPop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 130;
}
.xjPop .popInfo{
  width: 1100px;
  max-height: 640px;
  background-color: #ffffff;
	border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.xjPop .popInfo .top{
  height: 56px;
	background-color: #fae5d9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  flex-shrink: 0;
}
.xjPop .popInfo .top span{
  font-size: 14px;
  color: #495057;
}
.xjPop .popInfo .top img{
  cursor: pointer;
}
.xjPop .popInfo .middle{
  flex: 1;
  overflow-y: auto;
  padding: 20px 25px 0 30px;
}
.xjPop .popInfo .middle .one{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.xjPop .popInfo .middle .one .left{
  font-size: 18px;
  color: #495057;
}

.xjPop .popInfo .middle .one .right{
  width: 100px;
	height: 36px;
	background-color: #ED6C00;
	box-shadow: 0px 3px 5px 0px 	rgba(237, 109, 0, 0.1);
	border-radius: 3px;
  font-size: 18px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.xjPop .popInfo .middle .two{
  height: 40px;
	background-color: #f4f4f4;
  display: flex;
}
.xjPop .popInfo .middle .two .deOne{
  width: 300px;
  margin-right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #495057;
  padding-left: 14px;
}
.xjPop .popInfo .middle .two .deTwo{
  width: 100px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #495057;
}
.xjPop .popInfo .middle .two .deThree{
  width: 400px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #495057;
}
.xjPop .popInfo .middle .two .deFour{
  width: 100px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #495057;
}
.xjPop .popInfo .middle .three{
  padding: 20px 0 30px;
  border-bottom: 1px solid #e6e6e6;
}
.xjPop .popInfo .middle .three .detail{
  display: flex;
  margin-bottom: 14px;
}
.xjPop .popInfo .middle .three .detail:last-of-type{
  margin-bottom: 0;
}

.xjPop .popInfo .middle .three .detail input{
  width: 100%;
  font-size: 14px;
  color: #3A3A3C;
}

.xjPop .popInfo .middle .three .detail .deOne{
  width: 300px;
  margin-right: 10px;
}
.xjPop .popInfo .middle .three .detail .deTwo{
  width: 100px;
  margin-right: 10px;
}
.xjPop .popInfo .middle .three .detail .deThree{
  width: 400px;
  margin-right: 10px;
}
.xjPop .popInfo .middle .three .detail .deFour{
  width: 100px;
}
.xjPop .popInfo .middle .three .detail .deFour .fourBtn{
  width: 100%;
	height: 40px;
	background-color: #aaaaaa;
	border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}
.xjPop .popInfo .middle .three .detail .deFour .fourBtn img{
  margin-right: 8px;
}
.xjPop .popInfo .middle .four{
  font-size: 18px;
  color: #495057;
  line-height: 1;
  margin: 30px 0 23px;
}
.xjPop .popInfo .middle .five{
  display: flex;
  height: 40px;
	background-color: #f4f4f4;
}
.xjPop .popInfo .middle .five .deOne{
  width: 300px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #495057;
  padding-left: 16px;
}
.xjPop .popInfo .middle .five .deTwo{
  width: 210px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #495057;
}
.xjPop .popInfo .middle .five .deThree{
  width: 515px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #495057;
}
.xjPop .popInfo .middle .six{
  display: flex;
  margin: 20px 0;
}
.xjPop .popInfo .middle .six input{
  width: 100%;
  font-size: 14px;
  color: #3A3A3C;
}
.xjPop .popInfo .middle .six .deOne{
  width: 300px;
  margin-right: 10px;
}
.xjPop .popInfo .middle .six .deTwo{
  width: 210px;
  margin-right: 10px;
}
.xjPop .popInfo .middle .six .deThree{
  width: 515px;
}

.xjPop .popInfo .middle .seven{
  padding-bottom: 30px;
}
.xjPop .popInfo .middle .seven .upload-demo{
  display: flex;
}
.xjPop .popInfo .middle .seven .x_upLoad{
  font-size: 14px;
  color: #fff;
  background-color: #ED6C00;
  border: 0;
}
/deep/ .el-upload{
  margin-right: 30px;
}
/deep/ .el-upload-list__item:first-child{
  margin-top: 0;
}
/deep/.el-upload-list__item .el-icon-close{
  top: 10px;
}
/deep/.el-upload-list__item-name{
  height: 32px;
  line-height: 32px;
}

.xjPop .popInfo .bottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0 35px;
  width: 1040px;
  margin: auto;
  border-top: 1px solid #e6e6e6;
}
.xjPop .popInfo .bottom .left{
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #3A3A3C;
}
.xjPop .popInfo .bottom .left span{
  color: #ED6C00;
}
.xjPop .popInfo .bottom .left img{
  margin: 0 5px 0 15px;
}
.xjPop .popInfo .bottom .right{
  display: flex;
  align-items: center;
}
.xjPop .popInfo .bottom .right .cancel{
  width: 60px;
	height: 36px;
	background-color: #f3f6f9;
	box-shadow: 0px 3px 5px 0px 	rgba(237, 109, 0, 0.1);
	border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #353637;
  cursor: pointer;
}
.xjPop .popInfo .bottom .right .submit{
  width: 100px;
	height: 36px;
	background-color: #ED6C00;
	box-shadow: 0px 3px 5px 0px 	rgba(237, 109, 0, 0.1);
	border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
}

// 高度为整屏
// .contain .info{
//   height: 100%;
//   display: flex;
//   flex-direction: column;
// }

.searchbtn{
    width: 100px;
    height: 40px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    background-color: #ED6C00;
    box-shadow: 0px 3px 5px 0px rgb(237 109 0 / 10%);
    color: #fff;
    border: 0;
    margin-left: 20px;
}

/deep/ .el-input{
  width: auto;
  margin-left: 20px;
}

.x_screen {
  height: 73px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  padding: 0 16px;
  border-bottom: 1px solid #e6e6e6;
}
.x_screen .left {
  display: flex;
  align-items: center;
}

.x_screen .left .others {
  width: 120px;
  margin-left: 20px;
}

.x_screen .right {
  width: 500px;
  margin-left: 20px;
}

.popdc{
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.4);
  z-index: 111;

  .popdcview{
    width: 300px;
    // height: 200px;
    padding: 20px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

    &>p{
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      color: #ED6C00;
    }

    &>div{
      display: flex;
      margin-top: 20px;
      justify-content: center;

      p{
        width: 100px;
        height: 40px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 16px;
        background-color: #ED6C00;
        box-shadow: 0px 3px 5px 0px rgb(237 109 0 / 10%);
        color: #fff;
        border: 0;
      }

      p:nth-child(2){
        margin-left: 20px;
      }
    }
  }
}

.x_screen /deep/ .el-select:nth-child(1) .el-input{
  margin-left: 0;
}
</style>
