<template>
  <div class="contain">
    <div class="title">研发生态门户</div>
    <div class="info">
      <a href="https://www.bowayctw.com/web/frontend/alloyadvisor" target="_blank" class="detail">
        <div class="one">
          <img src="../../assets/images/x_icon2.png" alt="">
          <img src="../../assets/images/x_logo.png" alt="">
        </div>
        <div class="two">
          <div class="left">智能选材</div>
          <img src="../../assets/images/x_icon9.png" alt="">
        </div>
      </a>
      <a href="https://www.bowayctw.com/web/frontend/news" class="detail" target="_blank">
        <div class="one">
          <img src="../../assets/images/x_icon3.png" alt="">
          <img src="../../assets/images/x_logo.png" alt="">
        </div>
        <div class="two">
          <div class="left">技术动态</div>
          <img src="../../assets/images/x_icon9.png" alt="">
        </div>
      </a>
      <a href="https://www.bowayctw.com/web/frontend/testingservice" class="detail" target="_blank">
        <div class="one">
          <img src="../../assets/images/x_icon4.png" alt="">
          <img src="../../assets/images/x_logo.png" alt="">
        </div>
        <div class="two">
          <div class="left">数字化测试平台</div>
          <img src="../../assets/images/x_icon9.png" alt="">
        </div>
      </a>
      <a href="https://www.bowayctw.com/web/frontend/bbs" class="detail" target="_blank">
        <div class="one">
          <img src="../../assets/images/x_icon5.png" alt="">
          <img src="../../assets/images/x_logo.png" alt="">
        </div>
        <div class="two">
          <div class="left">交流平台</div>
          <img src="../../assets/images/x_icon9.png" alt="">
        </div>
      </a>
      <a href="https://www.bowayctw.com/web/frontend/simulationhomes" class="detail" target="_blank">
        <div class="one">
          <img src="../../assets/images/x_icon6.png" alt="">
          <img src="../../assets/images/x_logo.png" alt="">
        </div>
        <div class="two">
          <div class="left">数字化研发技术支持</div>
          <img src="../../assets/images/x_icon9.png" alt="">
        </div>
      </a>
      <a href="https://www.bowayctw.com/web/frontend/testingproducts" class="detail" target="_blank">
        <div class="one">
          <img src="../../assets/images/x_icon7.png" alt="">
          <img src="../../assets/images/x_logo.png" alt="">
        </div>
        <div class="two">
          <div class="left">数字化研制一体化服务</div>
          <img src="../../assets/images/x_icon9.png" alt="">
        </div>
      </a>
      <a href="https://www.bowayctw.com/web/frontend/onlinetools" class="detail" target="_blank">
        <div class="one">
          <img src="../../assets/images/x_icon8.png" alt="">
          <img src="../../assets/images/x_logo.png" alt="">
        </div>
        <div class="two">
          <div class="left">数字化研发工具箱</div>
          <img src="../../assets/images/x_icon9.png" alt="">
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  // name: 'Index', 
  components: {

  },
  data(){
    return {
     
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
  .contain{
    display: flex;
    flex-direction: column;
    // height: 100%;
  }
  .contain .title{
    flex-shrink: 0;
    height: 50px;
    background-color: #ffffff;
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.06);
    font-size: 18px;
    color: #495057;
    padding: 0 35px;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  .contain .info{
    flex: 1;
    background: #f2f2f7;
    padding: 30px;
    overflow-y: auto;
  }
  .contain .info .detail{
    width: 32%;
    height: 220px;
    background-color: #ffffff;
    box-shadow: 0px 3px 7px 0px rgba(21, 21, 41, 0.1);
    border-radius: 4px;
    padding: 50px 30px 50px 40px;
    display: block;
    box-sizing: border-box;
    text-decoration: none;
    margin: 0 20px 20px 0;
    float: left;
  }
  .contain .info .detail:nth-of-type(3n){
    margin-right: 0;
  }
  .contain .info .detail:nth-of-type(3n){
    margin-right: 0;
  }
  .contain .info .detail .one{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .contain .info .detail .two{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
  }
  .contain .info .detail .two .left{
    font-size: 24px;
    color: #495057;
  }
  //通用滚动条样式
  ::-webkit-scrollbar {
    width: 8px;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background: rgba(237, 109, 0, 0.3);
  }
</style>
