<template>
  <div class="contain">
    <div class="info upinfo" style="margin-bottom: 15px">
      <div class="x_title">在线反馈</div>
      <div class="y_aboutbw_shzp_tc_6">
        <!-- <div class="y_aboutbw_shzp_tc_8"> -->
          <div class="y_aboutbw_shzp_tc_9">
              <h2><span style="color:#ED6C00">*</span>事业部名称</h2>
              <el-select
                  v-model="upform.businessunitname"
                  clearable
                  placeholder="请选择事业部"
                  >
                  <el-option
                      v-for="item in upsyblist"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
              </el-select>
          </div>
          <div class="y_aboutbw_shzp_tc_9">
              <h2><span style="color:#ED6C00">*</span>反馈类型</h2>
              <el-select
                  v-model="upform.feedbacktype"
                  clearable
                  placeholder="请选择反馈类型"
                  >
                  <el-option
                      v-for="item in fkSelect"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
              </el-select>
          </div>
        <!-- </div>
        <div class="y_aboutbw_shzp_tc_8"> -->
            
        <!-- </div> -->
        <!-- <div class="y_aboutbw_shzp_tc_8"> -->
            <div class="y_aboutbw_shzp_tc_9">
                <h2>联系人</h2>
                <el-input v-model="upform.contacts" placeholder="请输入"></el-input>
            </div>
            <div class="y_aboutbw_shzp_tc_9">
                <h2>联系电话</h2>
                <el-input v-model="upform.contactnumber" placeholder="请输入"></el-input>
            </div>
            <div class="y_aboutbw_shzp_tc_9">
                <h2><span style="color:#ED6C00">*</span>反馈内容</h2>
                <el-input type="textarea" :rows="4" v-model="upform.feedbackcontent" placeholder="请输入"></el-input>
            </div>
        <!-- </div> -->
        <div class="y_aboutbw_shzp_tc_10 y_aboutbw_shzp_tc_9">
          <h2>上传附件</h2>
            <!-- <el-input type="file" v-model="upform.fileUrl" placeholder="请输入"></el-input> -->
            <el-upload class="upload-demo" 
                :on-preview="handlePreview"
                :action=" upurl + 'customer/customerPage/onlineFeedFile'"
                :headers="{Authorization:Authorization}"
                :on-change="onUploadChange"
                :on-success="userUpload"
                multiple 
                :limit="1"
                :before-upload="beforeUpload"
                :file-list="fileList">
                <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
        </div>
      </div>
      <div class="y_aboutbw_shzp_tc_11">
          <div class="y_aboutbw_shzp_tc_12" @click="deleteform">
              <p>取消</p>
          </div>
          <div class="y_aboutbw_shzp_tc_13" @click="upsubmit">
              <p>提交反馈</p>
          </div>
      </div>
    </div>
    <div class="info">
      <div class="x_title">反馈记录</div>
          <div class="x_screen" v-if="false">
            <el-select
              v-model="form.zsybt"
              clearable
              placeholder="请选择事业部"
            >
              <el-option
                v-for="item in twoSelect"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="form.wgbez"
              filterable 
              clearable
              placeholder="请选择产品大类"
            >
              <el-option
                v-for="item in oneSelect"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- <el-input
            placeholder="请输入试样单号搜索"
            prefix-icon="el-icon-search"
            v-model="form.newOrdSaleorderlineId"
          >
          </el-input>
          <el-input
            placeholder="请输入产品牌号搜索"
            prefix-icon="el-icon-search"
            v-model="form.zkhph"
          >
          </el-input> -->
          <el-input
            placeholder="请输入关键词搜索"
            prefix-icon="el-icon-search"
            v-model="form.keywords"
          >
          </el-input>
          <button class="searchbtn" @click="shzp_hide">+ 新增反馈</button>
          <!-- <button class="searchbtn" @click="showload">导出</button> -->
          </div>
      <div class="x_thead">
        <div class="detail f1"><span>反馈时间</span></div>
        <div class="detail f1"><span>反馈到部门</span></div>
        <div class="detail f1"><span>反馈问题类型</span></div>
        <div class="detail f1"><span>联系人</span></div>
        <div class="detail f1"><span>联系电话</span></div>
        <!-- <div class="detail f1">创建时间</div> -->
        <div class="detail f3"><span>在线反馈内容</span></div>
        <!-- <div class="detail f2">附件</div> -->
        <div class="detail f2"><span>反馈结果</span></div>
        <div class="line"></div>
      </div>
      <div class="x_tbody"  v-loading="loading">
        <div class="x_td" v-for="(item, index) in list" :key="index">
          <div class="detail f1"><span>{{item.feebackTime}}</span></div>
          <div class="detail f1"><span>{{item.businessunitname}}</span></div>
          <div class="detail f1"><span>{{item.feedbacktype}}</span></div>
          <div class="detail f1"><span>{{item.contacts}}</span></div>
          <div class="detail f1"><span>{{item.contactnumber}}</span></div>
          <!-- <div class="detail f1"><span>{{item.createdon}}</span></div> -->
          <!-- <div class="detail f1"><span>{{item.newSampleResults}}</span></div> -->
          <div class="detail f3"><span>{{item.feedbackcontent}}</span></div>
          <div class="detail f2"><span></span></div>
          <!-- <div class="detail f2"><span>{{item.fileUrl}}</span></div> -->
          <!-- <div class="detail f2" v-if="item.type == 0"><span style="display: block;box-sizing:border-box; padding-right: 30px">待反馈</span></div>
          <div class="detail f2" v-if="item.type == 1"><span style="display: block;box-sizing:border-box; padding-right: 30px">已收到您的反馈信息，客户经理将会尽快与您联系，您也可以拨打售后热线057485623158</span></div> -->
        </div>
      </div>
      <div class="x_bottom">
        <div class="left"><span style="color:#ED6C00">共{{total}}条</span>，所有数据仅供参考，一切以双方财务对账为准</div>
        <div class="right">
          <el-pagination :page-size="form.pageNum" background layout="prev, pager, next , jumper" :total="total" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>

      <div class="y_aboutbw_shzp_tc_1" v-if="is_add">
            <div class="y_aboutbw_shzp_tc_2" v-loading="loading1">
                <div class="y_aboutbw_shzp_tc_3">
                    <div class="y_aboutbw_shzp_tc_4">
                        <h2>新增反馈</h2>
                    </div>
                    <div class="y_aboutbw_shzp_tc_5">
                        <img src="../../assets/images/icon_61.png"  @click="shzp_hide()" alt="">
                    </div>
                </div>
                <div class="y_aboutbw_shzp_tc_6">
                    <div class="y_aboutbw_shzp_tc_8">
                        <div class="y_aboutbw_shzp_tc_9">
                            <h2>事业部名称</h2>
                            <el-select
                                v-model="upform.businessunitname"
                                clearable
                                placeholder="请选择事业部"
                                >
                                <el-option
                                    v-for="item in upsyblist"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="y_aboutbw_shzp_tc_9">
                            <h2>反馈类型</h2>
                            <el-select
                                v-model="upform.feedbacktype"
                                clearable
                                placeholder="请选择反馈类型"
                                >
                                <el-option
                                    v-for="item in fkSelect"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="y_aboutbw_shzp_tc_8">
                        <div class="y_aboutbw_shzp_tc_9" style="width:100%;margin-right: 0">
                            <h2>反馈内容</h2>
                            <el-input type="textarea" :rows="4" v-model="upform.feedbackcontent" placeholder="请输入"></el-input>
                        </div>
                    </div>
                    <div class="y_aboutbw_shzp_tc_8">
                        <div class="y_aboutbw_shzp_tc_9">
                            <h2>联系人</h2>
                            <el-input v-model="upform.contacts" placeholder="请输入"></el-input>
                        </div>
                        <div class="y_aboutbw_shzp_tc_9">
                            <h2>联系电话</h2>
                            <el-input v-model="upform.contactnumber" placeholder="请输入"></el-input>
                        </div>
                    </div>
                    <div class="y_aboutbw_shzp_tc_10">
                        <h2>上传附件</h2>
                        <!-- <el-input type="file" v-model="upform.fileUrl" placeholder="请输入"></el-input> -->
                        <el-upload class="upload-demo" 
                            :on-preview="handlePreview"
                            :action=" upurl + 'customer/customerPage/onlineFeedFile'"
                            :headers="{Authorization:Authorization}"
                            :on-change="onUploadChange"
                            :on-success="userUpload"
                            multiple 
                            :limit="1"
                            :before-upload="beforeUpload"
                            :file-list="fileList">
                            <el-button size="small" type="primary">点击上传</el-button>
                        </el-upload>
                    </div>
                </div>
                <div class="y_aboutbw_shzp_tc_11">
                    <div class="y_aboutbw_shzp_tc_12" @click="shzp_hide">
                        <p>取消</p>
                    </div>
                    <div class="y_aboutbw_shzp_tc_13" @click="upsubmit">
                        <p>提交反馈</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="popdc" @click="hidedown" v-if="is_dc">
      <div class="popdcview" @click.stop="" v-loading="loading2">
          <p>选择要导出的数据</p>
          <div>
            <p @click="download(1)">全部导出</p>
            <p @click="download(2)">当前页导出</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
import axios from 'axios'
export default {
  //   name: 'Index',
  components: {},
  data() {
    return {
        is_add:false,
      loading: true,
      loading1: false,
      loading2: false,
      isOpenFkPop:false,//是否打开反馈弹窗
      isOpenFkxqPop: false,
      content:"",//反馈内容
      form:{
        pageNum: 1,
        pageSize: 5,
      },
      total:0,
      xid:'',
      notec:'',
      isdis: false,
      list:[],
      isssatisfied: '1',
      failreason:'',
      zsybt:'',
      twoSelect:[],
      oneSelect:[],
      xqinfo:'',

      is_dc: false,

      fileList:[],

      upurl:'',
      Authorization:'',

      upform:{
        businessunitname: "",
        contactnumber: "",
        contacts: "",
        feedbackcontent: "",
        feedbacktype: '1',
        file: "",
        fileUrl:'',
        new_documentbody:'',
        new_file_size:'',
        new_name:'',
      },
      upsyblist:'',
      fkSelect:[
        {
            label:'产品质量',
            value:'1'
        },{
            label:'产品交期',
            value:'2'
        },{
            label:'业务支持',
            value:'3'
        },{
            label:'物流运输',
            value:'4'
        },{
            label:'业务合作',
            value:'5'
        },{
            label:'其他',
            value:'6'
        }
      ]
    };
  },
  created() {
    this.upurl = this.apiUrl
    this.Authorization = 'Bearer ' + localStorage.getItem('token')
    let zsybt = this.$route.query.zsybt
    if(zsybt){
      this.form.zsybt = zsybt
    }
    this.$post(this.apiUrl + 'customer/customerPage/getConditionList').then((response1) => {
      let list = response1.data.zsybt
      let str
      for(let i in list){
        if(list[i].value == '棒材事业部'){
          str = '宁波博威合金材料股份有限公司棒材事业部'
        }else if(list[i].value == '线材事业部'){
          str = '宁波博威合金材料股份有限公司线材事业部'
        }else if(list[i].value == '板带事业部'){
          str = '宁波博威合金板带有限公司'
        }
        this.twoSelect.push({'label':str,'value':list[i].value})
      }
      this.upsyblist = JSON.parse(JSON.stringify(this.twoSelect))
      this.twoSelect.unshift({
          'label':'全部',
          'value': ""
      })
      this.oneSelect = response1.data.wgbez
    })
    this.getlist()
  },
  methods: {

    deleteform(){
      this.upform.businessunitname = ''
      this.upform.contactnumber = ''
      this.upform.contacts = ''
      this.upform.feedbackcontent = ''
      this.upform.feedbacktype = '1'
      this.upform.file = ''
      this.upform.fileUrl = ''
      this.upform.new_documentbody = ''
      this.upform.new_file_size = ''
      this.upform.new_name = ''
      this.fileList = []
    },
    
    userUpload(res, file) {
        console.log(file)
        this.upform.new_documentbody = res.data.new_documentbody;
        this.upform.new_file_size = res.data.new_file_size;
        this.upform.new_name = res.data.new_name;
    },
    handlePreview(file) {
        console.log(file);
    },

    beforeUpload(file) {
        
    },

    

    onUploadChange(file){
        console.log(file);
    },

    shzp_hide(){
        this.is_add = !this.is_add
    },
        

    showload(){
        this.is_dc = true
      },

      hidedown(){
        this.is_dc = false
      },

    
      download (type) {
        let pnum = this.form.pageNum
        if(type == 1){
          this.form.pageSize = 20000
          this.form.pageNum = 1
        }
        this.loading2 = true
        this.$exexl(this.apiUrl + 'customer/customerPage/sampleQueryExport',this.form).then((response) => {
          this.form.pageSize = 10
          if(type == 1){
            this.form.pageSize = 10
            this.form.pageNum = pnum
          }
          const blob = new Blob([response])
          const downloadElement = document.createElement('a')
          const href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = '试样服务.xlsx' // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
          this.loading2 = true
          this.is_dc = false
        })
      },
    getlist(){
        this.loading = true
        this.$post(this.apiUrl + 'customer/customerPage/onlineFeedList',this.form).then((response) => {
          this.list = []
          this.list = response.data.records
          //console.log(this.list)
          this.total = response.data.total
          this.loading = false
        })
    },
    handleCurrentChange(val){
        this.form.pageNum = val
        this.getlist()
    },
    // 打开提交反馈
    openFk(id){
      console.log(id)
      this.xid = id
      this.isOpenFkPop = true;
    },

    openFkxq(id){
      this.$post(this.apiUrl + 'customer/customerPage/mopFeebackSampleQuery',{feedbackNum:id}).then((response) => {
        this.xqinfo = response.data
        this.isOpenFkxqPop = true;
      })
      
    },

    closeFkxq(){
      this.isOpenFkxqPop = false;
    },

    openFklook(id,notec){
      this.xid = id
      this.notec = notec
      this.isdis = true;
      this.isOpenFkPop = true;
    },
    // 关闭提交反馈
    closeFk(){
      this.isOpenFkPop = false;
      this.isdis = false;
    },

    upsubmit(){
    //   let content = this.content
      this.loading1 = true
      this.$post(this.apiUrl + 'customer/customerPage/onlineFeed',this.upform).then((response) => {
        if(response.code == 200){
          this.$message({
              message: '提交成功！',
              type: 'success'
          });
          this.deleteform()
          this.getlist()
          this.is_add = false
          this.loading1 = false
        }else{
          this.$message({
              message: response.msg,
              type: 'error'
          });
        }
      })
      
    },
  },
};
</script>

<style lang="less" scoped>
.contain {
  // height: 100%;
  padding: 30px 30px 50px 30px;
  background: #f2f2f7;
}
.contain .info {
  background-color: #ffffff;
  box-shadow: 0px 3px 7px 0px rgba(21, 21, 41, 0.1);
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.x_title {
  height: 72px;
  padding: 0 16px;
  font-size: 18px;
  color: #495057;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-bottom: 1px solid #e6e6e6;
}
.x_thead {
  display: flex;
  align-items: center;
  height: 45px;
  background: #f3f6f9;
}
.x_thead .detail {
  font-size: 14px;
  color: #878a99;
  padding-left: 26px;
  display: flex;
  align-items: center;
  height: 100%;
}
.x_thead .line {
  width: 8px;
  height: 100%;
}
.f1 {
  flex: 1;
}
.f2 {
  flex: 2;
}
.f3 {
  flex: 3;
}
.f4 {
  flex: 4;
}
.x_tbody {
  flex: 1;
  overflow-y: auto;
}
.x_tbody .x_td {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
}
.x_tbody .x_td:last-of-type{
  border-bottom: 0;
}
.x_tbody .x_td .detail {
  font-size: 14px;
  color: #212529;
  line-height: 18px;
  display: flex;
  align-items: center;
  padding: 20px 0 20px 26px; 
  min-height: 72px;
  text-align: justify;
  word-break: break-word;
  //padding-right: 30px;
}
.x_tbody .x_td .detail .light{
  color: #ED6C00;
  cursor: pointer;
}

.x_tbody .x_td .detail .deBtn {
  width: 60px;
  height: 22px;
  background-color: #fff1e5;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ED6C00;
  cursor: pointer;
}
.x_tbody .x_td .detail .deBtn img {
  margin-right: 6px;
}
.x_bottom {
  height: 74px;
  display: flex;
  align-items: center;
  padding: 0 21px 14px;
  border-top: 1px solid #e6e6e6;
  justify-content: space-between;
}
.x_bottom .left {
  font-size: 14px;
  color: #828593;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ED6C00;
}
/deep/.el-pagination.is-background .el-pager li:hover {
  color: #ED6C00!important;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #fff!important;
}
//通用滚动条样式
::-webkit-scrollbar {
  width: 8px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: rgba(237, 109, 0, 0.3);
}


// 反馈弹窗
.fkPop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 130;
}
.fkPop .popInfo{
  width: 800px;
  max-height: 640px;
  background-color: #ffffff;
	border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.fkPop .popInfo .top{
  height: 56px;
	background-color: #fae5d9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  flex-shrink: 0;
}
.fkPop .popInfo .top span{
  font-size: 14px;
  color: #495057;
}
.fkPop .popInfo .top img{
  cursor: pointer;
}
.fkPop .popInfo .bottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 35px;
}
.fkPop .popInfo .bottom .left{
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #3A3A3C;
}
.fkPop .popInfo .bottom .left span{
  color: #ED6C00;
}
.fkPop .popInfo .bottom .left img{
  margin: 0 5px 0 15px;
}
.fkPop .popInfo .bottom .right{
  display: flex;
  align-items: center;
}
.fkPop .popInfo .bottom .right .cancel{
  width: 60px;
	height: 36px;
	background-color: #f3f6f9;
	box-shadow: 0px 3px 5px 0px 	rgba(237, 109, 0, 0.1);
	border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #353637;
  cursor: pointer;
}
.fkPop .popInfo .bottom .right .submit{
  width: 100px;
	height: 36px;
	background-color: #ED6C00;
	box-shadow: 0px 3px 5px 0px 	rgba(237, 109, 0, 0.1);
	border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
}
.fkPop .popInfo .middle{
  padding: 20px;
  flex: 1;
  overflow-y: auto;
}
.fkPop .popInfo .middle .title{
  font-size: 14px;
  color: #495057;
}
.fkPop .popInfo .middle .el-textarea{
  font-size: 14px;
  color: #495057;
  margin-top: 15px;
}

.popInfo /deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #ED6C00 !important;
}
/* 选中后小圆点的颜色 */
.popInfo /deep/ .el-radio__input.is-checked .el-radio__inner {
  background: #ED6C00 !important;
  border-color: #ED6C00 !important;
}

.searchbtn{
    width: 150px;
    height: 40px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    background-color: #ED6C00;
    box-shadow: 0px 3px 5px 0px rgb(237 109 0 / 10%);
    color: #fff;
    border: 0;
    margin-left: auto;
}

/deep/ .el-input{
  width: auto;
  margin-left: 20px;
}

.x_screen {
  height: 73px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  padding: 0 16px;
  border-bottom: 1px solid #e6e6e6;
}
.x_screen .left {
  display: flex;
  align-items: center;
}

.x_screen .left .others {
  width: 120px;
  margin-left: 20px;
}

.x_screen .right {
  width: 500px;
  margin-left: 20px;
}

.popdc{
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.4);
  z-index: 111;

  .popdcview{
    width: 300px;
    // height: 200px;
    padding: 20px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

    &>p{
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      color: #ED6C00;
    }

    &>div{
      display: flex;
      margin-top: 20px;
      justify-content: center;

      p{
        width: 100px;
        height: 40px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 16px;
        background-color: #ED6C00;
        box-shadow: 0px 3px 5px 0px rgb(237 109 0 / 10%);
        color: #fff;
        border: 0;
      }

      p:nth-child(2){
        margin-left: 20px;
      }
    }
  }
}
.x_screen /deep/ .el-select:nth-child(1) .el-input{
  margin-left: 0;
}

// 弹窗 社会招聘
    .y_aboutbw_shzp_tc_1{
        width: 100%;
        height: 100vh;
        z-index: 101;
        top: 0px;
        left: 0px;
        background: rgba(0,0,0,0.7);
        position: fixed;
    }
    .y_aboutbw_shzp_tc_2{
        top: 50%;
        left: 50%;
        width: 800px;
        // height: 620px;
        padding-bottom: 50px;
        position: fixed;
        background: #fff;
        transform: translate(-50%,-50%);
        .y_aboutbw_shzp_tc_3{
            width: 100%;
            background: #f5e5d9;
            display: flex;
            justify-content: space-between;
            padding: 0px 15px;
            height: 56px;
            align-items: center;
            .y_aboutbw_shzp_tc_4{
                h2{
                    font-size: 14px;
                    color:#495057;
                }
            }
            .y_aboutbw_shzp_tc_5{
                width: 15px;
                height: 100%;
                display: flex;
                align-items: center;
                img{
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
        .y_aboutbw_shzp_tc_6{
            padding: 30px 20px 0px;
            .y_aboutbw_shzp_tc_7{
                width: 100%;
                h2{
                    font-size: 14px;
                    color: #ED6C00;
                }
            }
            .y_aboutbw_shzp_tc_8{
                display: flex;
                justify-items: flex-start;
                flex-wrap: wrap;
                //margin-top: 20px;
                .y_aboutbw_shzp_tc_9{
                    /deep/ .el-select{
                        width: 100%;
                    }
                    width: 49%;
                    margin-right: 2%;
                    margin-bottom: 25px;
                    h2{
                        width: 100%;
                        font-size: 14px;
                        color: #3A3A3C;
                        margin-bottom: 10px;
                    }
                    input{
                        width: 100%;
                        height: 36px;
                        line-height: 36px;
                        border: 1px solid #ced4da;
                        font-size: 14px;
                        color: #495057;
                    }
                }
            }
            .y_aboutbw_shzp_tc_8 .y_aboutbw_shzp_tc_9:nth-child(2n){
                margin-right: 0%;
            }
        }
    }
    .y_aboutbw_shzp_tc_10{
        width: 100%;
        margin-bottom: 25px;
        h2{
            width: 100%;
            font-size: 14px;
            color: #3A3A3C;
            margin-bottom: 10px;
        }
        input{
            width: 100%;
            height: 35px;
            line-height: 35px;
            border: 1px solid #ced4da;
            font-size: 14px;
            color: #495057;
        }
        .el-button--small{
            width: 90px;
            height: 35px;
            background: #f3f6f9;
            color: #495057;
            border: 1px solid #ced4da;
        }
        .upload-demo{
            width: 100%;
            border: 1px solid #ced4da;
            height: 35px;
        }
        .el-upload-list__item .el-icon-upload-success{
            // display: none;
        }
        .el-upload-list__item-name{
            // display: none;
        }
    }
    .y_aboutbw_shzp_tc_11{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0px 20px;
        // margin-top: 60px;
        .y_aboutbw_shzp_tc_12{
            width: 60px;
            height: 36px;
            margin-right: 10px;
            p{
                width: 100%;
                line-height: 36px;
                color: #3A3A3C;
                font-size: 14px;
                background: #f3f6f9;
                border-radius: 5px;
                text-align: center;
            }
        }
        .y_aboutbw_shzp_tc_13{
            width: 100px;
            height: 36px;
            p{
                color:#fff;
                background: #ED6C00;
                text-align: center;
                font-size: 14px;
                border-radius: 5px;
                line-height: 36px;
            }
        }
    }

    .y_aboutbw_shzp_tc_1 /deep/ .el-input{
        margin-left: 0;
        width: 100%;
    }

    .upinfo{
      padding-bottom: 40px;

      .y_aboutbw_shzp_tc_6{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 30px;
        padding-right: 50px;
        padding-bottom: 0;
        &>div{
          width: 30%;
          
        }

        .y_aboutbw_shzp_tc_9{
          display: flex;
          margin-bottom: 25px;
          /deep/ .el-select{
            width: calc(100% - 80px);
          }
          /deep/ .el-select .el-input{
            width: 100%;
            margin-left: 0;
          }
          /deep/ .el-input{
            width: calc(100% - 80px);
            margin-left: 0;
          }
          /deep/ .el-textarea{
            width: calc(100% - 80px);
            margin-left: 0;
          }
          /deep/ .upload-demo{
            width: calc(100% - 80px);
            margin-left: 0;
          }
          
          h2{
            line-height: 40px;
            width: 80px;
            font-size: 14px;
          }
        }
      }
    }
</style>
