import { render, staticRenderFns } from "./Gylgl.vue?vue&type=template&id=e5152928&scoped=true"
import script from "./Gylgl.vue?vue&type=script&lang=js"
export * from "./Gylgl.vue?vue&type=script&lang=js"
import style0 from "./Gylgl.vue?vue&type=style&index=0&id=e5152928&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5152928",
  null
  
)

export default component.exports