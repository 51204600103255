import { render, staticRenderFns } from "./right.vue?vue&type=template&id=724b4b96&scoped=true"
import script from "./right.vue?vue&type=script&lang=js"
export * from "./right.vue?vue&type=script&lang=js"
import style0 from "./right.vue?vue&type=style&index=0&id=724b4b96&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "724b4b96",
  null
  
)

export default component.exports