<template>
  <div class="contain">
    <p style="color: #999;margin-bottom: 10px;font-size: 14px;">试样订单针对新产品新客户加工工艺，性能等测试，符合客户要求以后需要不低于3批次验证，部分验证订单在博威视为试样订单，在客户处可能属于正式订单。</p>
    <div class="info">
      <div class="x_title">试样服务
        <p data-v-619f20c8="" style="color: rgb(130, 133, 147); font-size: 16px; margin-left: auto; font-weight: normal;">统计时间范围：2022-01-01 - {{nowtime}}</p>
      </div>
          <div class="x_screen">
            <el-select
              v-model="form.zsybt"
              clearable
              placeholder="请选择事业部"
            >
              <el-option
                v-for="item in twoSelect"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="form.wgbez"
              filterable 
              clearable
              placeholder="请选择产品大类"
            >
              <el-option
                v-for="item in oneSelect"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- <el-input
            placeholder="请输入试样单号搜索"
            prefix-icon="el-icon-search"
            v-model="form.newOrdSaleorderlineId"
          >
          </el-input>
          <el-input
            placeholder="请输入产品牌号搜索"
            prefix-icon="el-icon-search"
            v-model="form.zkhph"
          >
          </el-input> -->
          <el-input
            placeholder="请输入关键词搜索"
            prefix-icon="el-icon-search"
            v-model="form.keywords"
          >
          </el-input>
          <button class="searchbtn" @click="getlist">查询</button>
          <button class="searchbtn" @click="showload">导出</button>
          </div>
      <div class="x_thead">
        <div class="detail f1"><span>博威试样单号</span></div>
        <div class="detail f1"><span>采购单号(客户)</span></div>
        <div class="detail f1"><span>物料号(客户)</span></div>
        <div class="detail f1"><span>物料描述(客户)</span></div>
        <div class="detail f1"><span>事业部</span></div>
        <div class="detail f1"><span>产品大类</span></div>
        <div class="detail f1"><span>产品牌号</span></div>
        <div class="detail f1"><span>规格</span></div>
        <div class="detail f1"><span>数量</span></div>
        <div class="detail f1"><span>创建时间</span></div>
        <div class="detail f1"><span>试样发货时间</span></div>
        <!-- <div class="detail f1">试样反馈状态</div> -->
        <div class="detail f1"><span>试样结果</span></div>
        <div class="detail f2"><span>结果反馈</span></div>
        <div class="line"></div>
      </div>
      <div class="x_tbody"  v-loading="loading">
        <div class="x_td" v-for="(item, index) in list" :key="index">
          <div class="detail f1"><span>{{item.newOrdSaleorderlineId}}</span></div>
          <div class="detail f1"><span>{{item.bstnk}}</span></div>
          <div class="detail f1"><span>{{item.newAccountproductno}}</span></div>
          <div class="detail f1"><span>{{item.materialDescription}}</span></div>
          <div class="detail f1"><span>{{item.newBusinessunitIdName}}</span></div>
          <div class="detail f1"><span>{{item.wgbez}}</span></div>
          <div class="detail f1"><span>{{item.newAccountbrand}}</span></div>
          <!-- <div class="detail f1"><span>{{item.newBrandIdName}}</span></div> -->
          <div class="detail f1"><span>{{item.zgge}}</span></div>
          <div class="detail f1"><span>{{item.newQty}}</span></div>
          <div class="detail f1"><span>{{item.createdon}}</span></div>
          <div class="detail f1"><span>{{item.newCheckdate}}</span></div>
          <!-- <div class="detail f1"><span>{{item.newSampleResults}}</span></div> -->
          <div class="detail f1"><span>{{item.newSampleResultContent}}</span></div>
          
          <div class="detail f2" v-if="item.type == 0"><span>待反馈，请</span><span class="light" @click="openFk(item.newOrdSaleorderlineId)">提交反馈</span></div>
          <div class="detail f2" v-if="item.type == 1"><span>已反馈，<span class="light" @click="openFkxq(item.newOrdSaleorderlineId)">查看详情</span></span></div>
        </div>
      </div>
      <div class="x_bottom">
        <div class="left"><span style="color:#ED6C00">共{{total}}条</span>，所有数据仅供参考，一切以双方财务对账为准</div>
        <div class="right">
          <el-pagination background layout="prev, pager, next , jumper" :total="total" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
      <!-- 反馈弹窗 -->
      <transition name="el-fade-in-linear">
        <div class="fkPop" v-show="isOpenFkPop">
          <div class="popInfo" v-loading = 'loading1'>
            <div class="top">
              <span>试样结果反馈</span>
              <img src="../../assets/images/x_close.png" alt="" @click="closeFk">
            </div>
            <div class="middle">
              <div class="title">反馈结果</div>
              <div class="title" style="margin-top: 10px"></div>
              <el-radio v-model="isssatisfied" label="1" fill="#ED6C00">合格</el-radio>
              <el-radio v-model="isssatisfied" label="2" fill="#ED6C00">不合格</el-radio>
              <el-radio v-model="isssatisfied" label="3" fill="#ED6C00">试样中</el-radio>
              <el-radio v-model="isssatisfied" label="4" fill="#ED6C00">后续订单需求</el-radio>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="客户主要失败原因"
                v-model="failreason"
                v-if = "isssatisfied == '2'"
                >
              </el-input>
              <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入"
                v-model="content"
                :disabled = 'isdis'
                >
              </el-input>
            </div>
            <div class="bottom">
              <div class="left"></div>
              <div class="right">
                <div class="cancel" @click="closeFk">取消</div>
                <div class="submit" @click="submitnote">提交反馈</div>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <transition name="el-fade-in-linear">
        <div class="fkPop" v-show="isOpenFkxqPop">
          <div class="popInfo" v-loading = 'loading1'>
            <div class="top">
              <span>试样结果</span>
              <img src="../../assets/images/x_close.png" alt="" @click="closeFkxq">
            </div>
            <div class="middle" style="pointer-events: none;">
              <div class="title">反馈结果</div>
              <div class="title" style="margin-top: 10px"></div>
              <el-radio v-model="xqinfo.sampleresults" :label="1" fill="#ED6C00">合格</el-radio>
              <el-radio v-model="xqinfo.sampleresults" :label="2" fill="#ED6C00">不合格</el-radio>
              <el-radio v-model="xqinfo.sampleresults" :label="3" fill="#ED6C00">试样中</el-radio>
              <el-radio v-model="xqinfo.sampleresults" :label="4" fill="#ED6C00">订单需求</el-radio>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="客户主要失败原因"
                v-model="xqinfo.failreason"
                v-if = "xqinfo.sampleresults == '2'"
                >
              </el-input>
              <el-input
                type="textarea"
                :rows="4"
                placeholder=""
                v-model="xqinfo.sampleresultcontent"
                >
              </el-input>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="popdc" @click="hidedown" v-if="is_dc">
      <div class="popdcview" @click.stop="" v-loading="loading2">
          <p>选择要导出的数据</p>
          <div>
            <p @click="download(1)">全部导出</p>
            <p @click="download(2)">当前页导出</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
export default {
  //   name: 'Index',
  components: {},
  data() {
    return {
      loading: true,
      loading1: false,
      loading2: false,
      isOpenFkPop:false,//是否打开反馈弹窗
      isOpenFkxqPop: false,
      content:"",//反馈内容
      form:{
        pageNum: 1,
        pageSize: 10,
        zsybt:'',
        wgbez:'',
        zkhph:'',
        newBrandIdName:'',
        keywords:''
        // // customerNumber:localStorage.getItem('customerNumber')
      },
      total:0,
      xid:'',
      notec:'',
      isdis: false,
      list:[],
      isssatisfied: '1',
      failreason:'',
      zsybt:'',
      twoSelect:[],
      oneSelect:[],
      xqinfo:'',

      is_dc: false,
      nowtime:''
    };
  },
  created() {
    this.nowtime = this.yest(Date.now())
    let zsybt = this.$route.query.zsybt
    if(zsybt){
      this.form.zsybt = zsybt
    }
    // this.$post(this.apiUrl + 'customer/customerPage/sampleQuery',this.form).then((response) => {
    //   this.list = response.data.records
    //   this.total = response.data.total
    // })
    this.$post(this.apiUrl + 'customer/customerPage/getConditionList').then((response1) => {
      //this.zsybt = response1.data.zsybt[0].value
      //this.twoSelect = response1.data.zsybt
      let list = response1.data.zsybt
      let str
      for(let i in list){
        if(list[i].value == '棒材事业部'){
          str = '宁波博威合金材料股份有限公司棒材事业部'
        }else if(list[i].value == '线材事业部'){
          str = '宁波博威合金材料股份有限公司线材事业部'
        }else if(list[i].value == '板带事业部'){
          str = '宁波博威合金板带有限公司'
        }
        this.twoSelect.push({'label':str,'value':list[i].value})
      }
      this.twoSelect.unshift({
          'label':'全部',
          'value': ""
      })
      this.oneSelect = response1.data.wgbez
      // setTimeout(()=>{
      //   this.initchart()
      // },200)
    })
    this.getlist()
  },
  methods: {
    yest (now) {
        let year = new Date(now).getFullYear();
        let month = new Date(now).getMonth() + 1;
        let date = new Date(now).getDate() - 1;
        if (month < 10) month = "0" + month;
        if (date < 10) date = "0" + date;
        return year + "-" + month + "-" + date;
      },
    showload(){
        this.is_dc = true
      },

      hidedown(){
        this.is_dc = false
      },

    
      download (type) {
        let pnum = this.form.pageNum
        if(type == 1){
          this.form.pageSize = 20000
          this.form.pageNum = 1
        }
        this.loading2 = true
        this.$exexl(this.apiUrl + 'customer/customerPage/sampleQueryExport',this.form).then((response) => {
          this.form.pageSize = 10
          if(type == 1){
            this.form.pageSize = 10
            this.form.pageNum = pnum
          }
          const blob = new Blob([response])
          const downloadElement = document.createElement('a')
          const href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = '试样服务.xlsx' // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
          this.loading2 = false
          this.is_dc = false
        })
      },
    getlist(){
        this.loading = true
        this.$post(this.apiUrl + 'customer/customerPage/sampleQuery',this.form).then((response) => {
          this.list = []
          this.list = response.data.records
          //console.log(this.list)
          this.total = response.data.total
          this.loading = false
        })
    },
    handleCurrentChange(val){
        this.form.pageNum = val
        this.getlist()
    },
    // 打开提交反馈
    openFk(id){
      console.log(id)
      this.xid = id
      this.isOpenFkPop = true;
    },

    openFkxq(id){
      this.$post(this.apiUrl + 'customer/customerPage/mopFeebackSampleQuery',{feedbackNum:id}).then((response) => {
        this.xqinfo = response.data
        this.isOpenFkxqPop = true;
      })
      
    },

    closeFkxq(){
      this.isOpenFkxqPop = false;
    },

    openFklook(id,notec){
      this.xid = id
      this.notec = notec
      this.isdis = true;
      this.isOpenFkPop = true;
    },
    // 关闭提交反馈
    closeFk(){
      this.isOpenFkPop = false;
      this.isdis = false;
    },

    submitnote(){
      let content = this.content
      this.loading1 = true
      this.$post(this.apiUrl + 'customer/customerPage/sampleQueryFeedback',{samorderno:this.xid,sampleresultcontent:content,failreason:this.failreason,sampleresults:this.isssatisfied}).then((response) => {
        if(response.code == 200){
          this.$message({
              message: '提交成功！',
              type: 'success'
          });
          this.getlist()
          this.isOpenFkPop = false;
          this.isdis = false;
          this.loading1 = false
        }else{
          this.$message({
              message: response.msg,
              type: 'error'
          });
        }
      })
      
    },
  },
};
</script>

<style lang="less" scoped>
.contain {
  // height: 100%;
  padding: 30px 30px 50px 30px;
  background: #f2f2f7;
}
.contain .info {
  background-color: #ffffff;
  box-shadow: 0px 3px 7px 0px rgba(21, 21, 41, 0.1);
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.x_title {
  height: 72px;
  padding: 0 16px;
  font-size: 18px;
  color: #495057;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-bottom: 1px solid #e6e6e6;
}
.x_thead {
  display: flex;
  align-items: center;
  height: 45px;
  background: #f3f6f9;
}
.x_thead .detail {
  font-size: 14px;
  color: #878a99;
  padding-left: 26px;
  display: flex;
  align-items: center;
  height: 100%;
}
.x_thead .line {
  width: 8px;
  height: 100%;
}
.f1 {
  flex: 1;
}
.f2 {
  flex: 2;
}
.f3 {
  flex: 3;
}
.f4 {
  flex: 4;
}
.x_tbody {
  flex: 1;
  overflow-y: auto;
}
.x_tbody .x_td {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
}
.x_tbody .x_td:last-of-type{
  border-bottom: 0;
}
.x_tbody .x_td .detail {
  font-size: 14px;
  color: #212529;
  line-height: 18px;
  display: flex;
  align-items: center;
  padding: 20px 0 20px 26px; 
  min-height: 72px;
  text-align: justify;
  word-break: break-word;
  width: min-content;
}
.x_tbody .x_td .detail .light{
  color: #ED6C00;
  cursor: pointer;
}

.x_tbody .x_td .detail .deBtn {
  width: 60px;
  height: 22px;
  background-color: #fff1e5;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ED6C00;
  cursor: pointer;
}
.x_tbody .x_td .detail .deBtn img {
  margin-right: 6px;
}
.x_bottom {
  height: 74px;
  display: flex;
  align-items: center;
  padding: 0 21px 14px;
  border-top: 1px solid #e6e6e6;
  justify-content: space-between;
}
.x_bottom .left {
  font-size: 14px;
  color: #828593;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ED6C00;
}
/deep/.el-pagination.is-background .el-pager li:hover {
  color: #ED6C00!important;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #fff!important;
}
//通用滚动条样式
::-webkit-scrollbar {
  width: 8px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: rgba(237, 109, 0, 0.3);
}


// 反馈弹窗
.fkPop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 130;
}
.fkPop .popInfo{
  width: 800px;
  max-height: 640px;
  background-color: #ffffff;
	border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.fkPop .popInfo .top{
  height: 56px;
	background-color: #fae5d9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  flex-shrink: 0;
}
.fkPop .popInfo .top span{
  font-size: 14px;
  color: #495057;
}
.fkPop .popInfo .top img{
  cursor: pointer;
}
.fkPop .popInfo .bottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 35px;
}
.fkPop .popInfo .bottom .left{
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #3A3A3C;
}
.fkPop .popInfo .bottom .left span{
  color: #ED6C00;
}
.fkPop .popInfo .bottom .left img{
  margin: 0 5px 0 15px;
}
.fkPop .popInfo .bottom .right{
  display: flex;
  align-items: center;
}
.fkPop .popInfo .bottom .right .cancel{
  width: 60px;
	height: 36px;
	background-color: #f3f6f9;
	box-shadow: 0px 3px 5px 0px 	rgba(237, 109, 0, 0.1);
	border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #353637;
  cursor: pointer;
}
.fkPop .popInfo .bottom .right .submit{
  width: 100px;
	height: 36px;
	background-color: #ED6C00;
	box-shadow: 0px 3px 5px 0px 	rgba(237, 109, 0, 0.1);
	border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
}
.fkPop .popInfo .middle{
  padding: 20px;
  flex: 1;
  overflow-y: auto;
}
.fkPop .popInfo .middle .title{
  font-size: 14px;
  color: #495057;
}
.fkPop .popInfo .middle .el-textarea{
  font-size: 14px;
  color: #495057;
  margin-top: 15px;
}

.popInfo /deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #ED6C00 !important;
}
/* 选中后小圆点的颜色 */
.popInfo /deep/ .el-radio__input.is-checked .el-radio__inner {
  background: #ED6C00 !important;
  border-color: #ED6C00 !important;
}

.searchbtn{
    width: 100px;
    height: 40px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    background-color: #ED6C00;
    box-shadow: 0px 3px 5px 0px rgb(237 109 0 / 10%);
    color: #fff;
    border: 0;
    margin-left: 20px;
}

/deep/ .el-input{
  width: auto;
  margin-left: 20px;
}

.x_screen {
  height: 73px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  padding: 0 16px;
  border-bottom: 1px solid #e6e6e6;
}
.x_screen .left {
  display: flex;
  align-items: center;
}

.x_screen .left .others {
  width: 120px;
  margin-left: 20px;
}

.x_screen .right {
  width: 500px;
  margin-left: 20px;
}

.popdc{
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.4);
  z-index: 111;

  .popdcview{
    width: 300px;
    // height: 200px;
    padding: 20px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

    &>p{
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      color: #ED6C00;
    }

    &>div{
      display: flex;
      margin-top: 20px;
      justify-content: center;

      p{
        width: 100px;
        height: 40px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 16px;
        background-color: #ED6C00;
        box-shadow: 0px 3px 5px 0px rgb(237 109 0 / 10%);
        color: #fff;
        border: 0;
      }

      p:nth-child(2){
        margin-left: 20px;
      }
    }
  }
}
.x_screen /deep/ .el-select:nth-child(1) .el-input{
  margin-left: 0;
}
</style>
