import { render, staticRenderFns } from "./aboutbw_honor.vue?vue&type=template&id=e9701a42&scoped=true"
import script from "./aboutbw_honor.vue?vue&type=script&lang=js"
export * from "./aboutbw_honor.vue?vue&type=script&lang=js"
import style0 from "./aboutbw_honor.vue?vue&type=style&index=0&id=e9701a42&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9701a42",
  null
  
)

export default component.exports