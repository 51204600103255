import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import AOS from "aos";
// import "../node_modules/aos/dist/aos.css";
// Vue.use(AOS)
import axios from 'axios'
import qs from 'qs'
Vue.prototype.$axios = axios    //全局注册，使用方法为:this.$axios
Vue.prototype.qs = qs           //全局注册，使用方法为:this.qs
axios.defaults.withCredentials = true;
import jquery from "jquery";
Vue.prototype.$ = jquery;
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts
// import md5 from 'js-md5';
// Vue.prototype.$md5 = md5;
import VueAwesomeSwiper from 'vue-awesome-swiper'
import '../node_modules/swiper/dist/css/swiper.css'
import './assets/css/quill.snow.css'
import './assets/css/quill.core.css'
import './assets/css/quill.bubble.css'
import './assets/css/iconfont.css'
// import LA from './assets/js/js-sdk-pro.min.js'
// LA.init({id: "Jlx9lZC546ZE5lnz",ck: "Jlx9lZC546ZE5lnz",autoTrack:true,hashMode:true})
import '../node_modules/animate.css/animate.css';
import '../node_modules/animate.css/animate.compat.css';
import '../node_modules/animate.css/animate.min.css';
// import animated from "animate.css"
// Vue.use(animate)
Vue.use(VueAwesomeSwiper)
// Vue.use(animated)
Vue.use(ElementUI)
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)
import $ from 'jquery'
import 'aos/dist/aos.css'
import AOS from 'aos/dist/aos.js'

import '@/assets/font/font.css';
AOS.init()
// import {VueJsonp} from 'vue-jsonp'
// Vue.prototype.$VueJsonp = VueJsonp

import videojs from 'video.js';
import 'video.js/dist/video-js.css';
Vue.prototype.$VideoJs = videojs;


//多语言
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: localStorage.getItem('lang') == '' || localStorage.getItem('lang') == undefined ? 'cn' : localStorage.getItem('lang'),
  messages: {
      cn: require('./assets/lang/lang_cn.json'),
      en: require('./assets/lang/lang_cn.json'),
      //en: require('./assets/lang/lang_en.json'),
  }
})

// meta标签
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

Vue.config.productionTip = false
const showStatus = (status) => { // 网络错误提示
  let message = '';
  switch (status) {
    case 400:
      message = 'phpin.showStatus.400';
      break
    case 401:
      // router.push({
      //   path: 'guodu',
      // })
      // router.push({
      //   path: 'guodu',
      // })
      //router.go(0)
      message = '您没有权限访问，请登录';
      break
    case 403:
      message = 'phpin.showStatus.403';
      break
    case 404:
      message = 'phpin.showStatus.404';
      break
    case 408:
      message = 'phpin.showStatus.408';
      break
    case 500:
      message = 'phpin.showStatus.500';
      break
    case 501:
      message = 'phpin.showStatus.501';
      break
    case 502:
      message = 'phpin.showStatus.502';
      break
    case 503:
      message = 'phpin.showStatus.503';
      break
    case 504:
      message = 'phpin.showStatus.504';
      break 
    case 505:
      message = 'phpin.showStatus.505';
      break
    default:
      message = '未知错误';
  }
  return message;
}
// const apiUrl = 'http://10.60.5.146:9001/';
// const apiUrl = 'http://192.168.1.10:8080/';
// const apiUrl = 'http://47.100.232.176:9001/';
// const apiUrl = 'https://boway.v-trend.cn/prod-api/';
// const apiUrl = 'https://www.bowayalloy.com/prod-api/';
const apiUrl = 'https://admin.bowayalloy.com/prod-api/';

// localStorage.setItem('lang','cn')

// router.afterEach( ( to, from, next ) => {
//   setTimeout(()=>{
//     var _hmt = _hmt || [];
//     (function() {
//       //每次执行前，先移除上次插入的代码
//       document.getElementById('51_la') && document.getElementById('51_la').remove();
//       var hm = document.createElement("script");
//       hm.src = "//sdk.51.la/js-sdk-pro.min.js";
//       hm.id = "51_la";
//       var s = document.getElementsByTagName("script")[0];
//       s.parentNode.insertBefore(hm, s);
//     })();
//   },0);
// });


Vue.prototype.apiUrl = apiUrl
/**
 * axios 配置
 */
const httpService = axios.create({
  baseURL: 'http://47.100.232.176:9001/', // api 的 base_url
  timeout: 20000, // 设置请求时长
  headers: {
    get: {
      'X-Requested-With':'XMLHttpRequest',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      'lang':'cn',
      dataType: 'jsonp',
      crossDomain: true,
      'method':'GET',
      'jsonp':'callback'
    },
    post: {
      'X-Requested-With':'XMLHttpRequest',
      // 'Content-Type' : 'application/x-www-form-urlencoded;charset=UTF-8',
      'Content-Type' : 'application/json',
      'Authorization':'Bearer ' + localStorage.getItem('token'),
      'lang':'cn',
      'method':'post'
    },
    exexl: {
      'X-Requested-With':'XMLHttpRequest',
      // 'Content-Type' : 'application/x-www-form-urlencoded;charset=UTF-8',
      'Content-Type' : 'application/json',
      'Authorization':'Bearer ' + localStorage.getItem('token'),
      'lang':'cn',
      'responseType': 'blob',
      'method':'post'
    },

   
  },
});

const httpService1 = axios.create({
  baseURL: 'http://47.100.232.176:9001/', // api 的 base_url
  timeout: 20000, // 设置请求时长
  responseType: "arraybuffer",
  headers:{
    post: {
      'X-Requested-With':'XMLHttpRequest',
      // 'Content-Type' : 'application/x-www-form-urlencoded;charset=UTF-8',
      'Content-Type' : 'application/json',
      'Authorization':'Bearer ' + localStorage.getItem('token'),
      'lang':'cn',
      'responseType': 'blob',
      'method':'post'
    },
  }
})

httpService1.interceptors.request.use(
  config => { // 发送请求前需要做什么，比如检查token之类
    if (localStorage.getItem("token") != null) {
      // config.headers["token"] = localStorage.getItem("token");
      config.headers["Authorization"] = 'Bearer ' + localStorage.getItem('token')
      config.headers["lang"] = 'cn'
    }
    return config;
  },
  error => { // 对请求错误做些什么
    return Promise.reject(error);
  }
);


/**
 * axios 请求拦截器
 */
httpService.interceptors.request.use(
  config => { // 发送请求前需要做什么，比如检查token之类
    if (localStorage.getItem("token") != null) {
      // config.headers["token"] = localStorage.getItem("token");
      config.headers["Authorization"] = 'Bearer ' + localStorage.getItem('token')
      config.headers["lang"] = 'cn'
    }
    return config;
  },
  error => { // 对请求错误做些什么
    return Promise.reject(error);
  }
);
/**
 * axios 响应拦截器
 */
httpService.interceptors.response.use(
  response => { // 对响应数据做点什么,一般是判断状态码，进行一些逻辑处理
    return response;
  },
  error => { // 对响应错误做点什么
    if(showStatus(error.response.status)=='您没有权限访问，请登录'){
      console.log(1)
    }else{
      ElementUI.Notification.error({ //拦截异常（通知）
        dangerouslyUseHTMLString: true,
        message:  showStatus(error.response.status),
        duration: 3000
      });
    }

    return Promise.reject(error) ;
  }
);


/**
 * post 请求
 * @param url
 * @param params
 * @returns {Promise}
 */
Vue.prototype.$post = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    httpService.post(url, params)
      .then(response => {
        if(response.data.code == 300){
          ElementUI.Message.closeAll();
          // ElementUI.Message({
          //   message: response.data.msg,
          //   type: 'error',
          //   duration:3000
          // });
          $.ajax({
            type: "get",
            async: false,
            url:"https://ctw.pwholdings.cn/o/osapp/crosSite",
            dataType: "jsonp",
            jsonp: "callback",
            jsonpCallback: "jsonpscall",//自定义的jsonp回调函数名称，请求成功后会执行后台返回的jsonpscall方法
            success: function (jsons) {
                console.log("成功");
            },
            error: function () {
                console.log("失败");
            }
          });
          document.cookie = "boway-cookie=";
          let cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
              let cookie = cookies[i];
              let eqPos = cookie.indexOf("=");
              let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
              document.cookie =
                name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
            }
            if (cookies.length > 0) {
              for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i];
                let eqPos = cookie.indexOf("=");
                let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                let domain = location.host.substr(location.host.indexOf("."));
                document.cookie =
                  name +
                  "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=" +
                  domain;
              }
            }
          localStorage.setItem('token','')
          router.push({
            path: '/login',
            query:{
              type:'nologin'
            }
          })
          
          return
        }
        if(response.data.code == 200){
          resolve(response.data);
        }else{
          ElementUI.Message.closeAll();
          ElementUI.Message({
            message: response.data.msg,
            type: 'error',
            duration:3000
          });
          reject(response.data)
        }
      })
      .catch(error => {
        reject(error);
      })
  })
};

Vue.prototype.$exexl = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    httpService1.post(url, params)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      })
  })
};



/**
 * get 请求
 * @param url
 * @param params
 * @returns {Promise}
 */
//paramsSerializer: (params) => Qs.stringify(params, { indices: false })
Vue.prototype.$get = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    httpService.get(url, {params, paramsSerializer: (params) => qs.stringify(params)})
      .then(response => {
        if(response.data.code == 300){
          ElementUI.Message.closeAll();
          // ElementUI.Message({
          //   message: response.data.msg,
          //   type: 'error',
          //   duration:3000
          // });
          $.ajax({
            type: "get",
            async: false,
            url:"https://ctw.pwholdings.cn/o/osapp/crosSite",
            dataType: "jsonp",
            jsonp: "callback",
            jsonpCallback: "jsonpscall",//自定义的jsonp回调函数名称，请求成功后会执行后台返回的jsonpscall方法
            success: function (jsons) {
                console.log("成功");
            },
            error: function () {
                console.log("失败");
            }
          });
          document.cookie = "boway-cookie=";
          let cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
              let cookie = cookies[i];
              let eqPos = cookie.indexOf("=");
              let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
              document.cookie =
                name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
            }
            if (cookies.length > 0) {
              for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i];
                let eqPos = cookie.indexOf("=");
                let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                let domain = location.host.substr(location.host.indexOf("."));
                document.cookie =
                  name +
                  "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=" +
                  domain;
              }
            }
          localStorage.setItem('token','')
          router.push({
            path: '/login',
            query:{
              type:'nologin'
            }
          })
          return
        }
        if(response.data.code == 200){
          resolve(response.data);
        }else{
          ElementUI.Message.closeAll();
          ElementUI.Message({
            message: response.data.msg,
            type: 'error',
            duration:3000
          });
        }
      })
      .catch(error => {
        reject(error);
      })
  })
}
new Vue({
  router,
  i18n,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
