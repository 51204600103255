<template>
  <div class="contain">
    <div class="info">
      <div class="x_title">供应链管理
        <p data-v-619f20c8="" style="color: rgb(130, 133, 147); font-size: 16px; margin-left: auto; font-weight: normal;">统计时间范围：2022-01-01 - {{nowtime}}</p>
      </div>
      <div class="x_screen">
        <div class="left">
          已选<span>{{checkedCities.length}}</span>条，共<span>{{numsum}}kg</span>
        </div>
        <div class="right">
          <div class="date">
            <el-date-picker
              v-model="checkTime"
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy.MM.dd"
              value-format="yyyyMMdd"
              @change="changeTime"
            >
            </el-date-picker>
          </div>
          <!-- <div class="detail">
            <el-input
              placeholder="请输入产品牌号"
              prefix-icon="el-icon-search"
              v-model="form.zkhph"
            >
            </el-input>
          </div>
          <div class="detail">
            <el-input
              placeholder="请输入物料名称"
              prefix-icon="el-icon-search"
              v-model="form.keywords"
            >
            </el-input>
          </div> -->
          <div class="detail">
            <el-select
              v-model="form.company"
              placeholder="请选择公司"
              clearable
              @change="changeTwo"
            >
              <el-option
                v-for="item in companylist"
                :key="item.customerNumber"
                :label="item.enterpriseName"
                :value="item.customerNumber"
              >
              </el-option>
            </el-select>
          </div>
          <div class="detail">
            <el-input
              placeholder="请输入关键词搜索"
              prefix-icon="el-icon-search"
              v-model="form.keywords"
            >
            </el-input>
          </div>
          <button class="searchbtn" @click="beginSearch">查询</button>
          <button class="searchbtn" @click="showload">导出</button>
        </div>
      </div>
      <div class="x_thead">
        <div class="detail f2">
          <!-- <img class="checkbox" src="../../assets/images/x_checkbox_show.png" v-show="isChoose" alt="">
          <img class="checkbox" src="../../assets/images/x_checkbox.png" v-show="!isChoose" alt=""> -->
          <el-checkbox style="margin-right: 16px;" :isIndeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"></el-checkbox>
          公司
        </div> 
        <div class="detail f3"><span> 物料描述</span></div>
        <div class="detail f2"><span>物料号(客户)</span></div>
        <div class="detail f1"><span>产品牌号</span></div>
        <div class="detail f2"><span>数量</span></div>
        <div class="detail f1"><span>发货状态</span></div>
        <!-- <div class="detail f1">博威牌号</div> -->
        <!-- <div class="detail f1">产品牌号</div> -->
        <div class="detail f1"><span>订单时间</span></div>
        <div class="detail f1"><span>承诺发货时间</span></div>
        <div class="line"></div>
      </div>
      
      <div class="x_tbody" v-loading="loading">
      <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
        <div class="x_td" v-for="(item, index) in list" :key="index">
          <div class="detail f2">
            <!-- <img class="checkbox" src="../../assets/images/x_checkbox_show.png" v-show="isChoose" alt="">
            <img class="checkbox" src="../../assets/images/x_checkbox.png" v-show="!isChoose" alt=""> -->
            <el-checkbox style="margin-right: 16px;" :label="index" fill="#ED6C00"></el-checkbox>
            {{item.enterpriseName}}
          </div>
          <div class="detail f3"><span>{{item.arktx}}</span></div>
          <div class="detail f2"><span>{{item.kdmat}}</span></div>
          <div class="detail f1"><span>{{item.zkhph}}</span></div>
          <div class="detail f2"><span>{{item.kwmeng}}kg</span></div>
          <div class="detail f1">
            <span>
              <div class="deBtn">{{item.name?item.name:'暂无'}}</div>
            </span>
          </div>
          <!-- <div class="detail f1">{{item.ewbez}}</div> -->
          <!-- <div class="detail f1">{{item.zkhph}}</div> -->
          <div class="detail f1"><span>{{item.erdatCrm}}</span></div>
          <div class="detail f1"><span>{{item.edatu}}</span></div>
        </div>
        </el-checkbox-group>
      </div>
      <div class="x_bottom">
        <div class="left"><span style="color:#ED6C00">共{{total}}条</span>，所有数据仅供参考，一切以双方财务对账为准</div>
        <div class="right">
          <el-pagination background layout="prev, pager, next , jumper" :total="total" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="popdc" @click="hidedown" v-if="is_dc">
      <div class="popdcview" @click.stop="" v-loading="loading1">
          <p>选择要导出的数据</p>
          <div>
            <p @click="download(1)">全部导出</p>
            <p @click="download(2)">当前页导出</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //   name: 'Index',
  components: {},
  data() {
    return {
      is_dc: false,
      loading: true,
      loading1 : false,
      checkTime: [], //时间范围
      oneSelect: [
        {
          id: "1",
          name: "物料一",
        },
        {
          id: "2",
          name: "物料二",
        },
      ],//物料名称
      oneValue: "",
      twoSelect: [
        {
          id: "1",
          name: "公司一",
        },
        {
          id: "2",
          name: "公司二",
        },
      ],//公司
      companylist: [],
      twoValue: "",
      isChoose: true,//是否选中   可删

      form:{
        beginDate: "",
        company: "",
        endDate: "",
        keywords: "",
        pageNum: 1,
        pageSize: 10,
        ewbez:'',
        zkhph:'',
      },
      list:[],
      total: 0,
      checkAll: false,
      isIndeterminate: true,
      checkedCities: [],
      listxx:[],

      numsum: 0,
      nowtime:''
    };
  },
  created() {
    this.nowtime = this.yest(Date.now())
    // customer/customerPage/supplyCompanyList
    this.$post(this.apiUrl + 'customer/customerPage/supplyCompanyList').then((response) => {
        this.companylist = response.data
    })

    this.getlist()
  },
  methods: {
    yest (now) {
        let year = new Date(now).getFullYear();
        let month = new Date(now).getMonth() + 1;
        let date = new Date(now).getDate() - 1;
        if (month < 10) month = "0" + month;
        if (date < 10) date = "0" + date;
        return year + "-" + month + "-" + date;
      },
    showload(){
        this.is_dc = true
      },

      hidedown(){
        this.is_dc = false
      },
      download (type) {
        let pnum = this.form.pageNum
        if(type == 1){
          this.form.pageSize = 20000
          this.form.pageNum = 1
        }
        this.loading1 = true
        this.$exexl(this.apiUrl + 'customer/customerPage/supplyManagementExport',this.form).then((response) => {
          this.form.pageSize = 10
          if(type == 1){
            this.form.pageSize = 10
            this.form.pageNum = pnum
          }
          this.loading1 = false
          const blob = new Blob([response])
          const downloadElement = document.createElement('a')
          const href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = '供应链.xlsx' // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
          this.is_dc = false
        })
      },
    getlist(){
        //this.changenum()
        this.listxx = []
        this.checkedCities = []
        this.numsum = 0
        this.loading = true
        this.$post(this.apiUrl + 'customer/customerPage/supplyManagement',this.form).then((response) => {
          this.list = response.data.records
          this.total = response.data.total
          let listxx = []
          this.list.forEach((item,index) => {
            listxx.push(index)
          })
          this.listxx = listxx
          this.loading = false
        })
    },
    handleCurrentChange(val){
        this.form.pageNum = val
        this.getlist()
    },
    //开始搜索
    beginSearch() {
      if(this.checkTime == null){
        this.form.beginDate = ''
        this.form.endDate = ''
      }else{
        this.form.beginDate = this.checkTime[0]
        this.form.endDate = this.checkTime[1]
      }
      // this.form.keywords = this.keywords
      this.form.pageNum = 1
      this.getlist()
    },
    // 改变时间
    changeTime() {
      let checkTime = this.checkTime;
      console.log(checkTime);
    },
    //改变物料
    changeOne() {
      console.log(this.oneValue);
    },
    //改变公司
    changeTwo() {
      console.log(this.twoValue);
    },
    handleCheckAllChange(val) {
        this.checkedCities = val ? this.listxx : [];
        this.isIndeterminate = false;
        this.changenum()
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      //console.log(value)
      this.checkAll = checkedCount === this.listxx.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.listxx.length;

      this.changenum()
    },

    changenum(){
      let list = this.list
      let num = 0
      this.checkedCities.forEach((item,index) => {
        num = num*1 + list[item].kwmeng*1
        // console.log(num)
      })
      this.numsum = num
    },
  },
};
</script>

<style lang="less" scoped>
.contain {
  // height: 100%;
  padding: 30px 30px 50px 30px;
  background: #f2f2f7;
}
.contain .info {
  background-color: #ffffff;
  box-shadow: 0px 3px 7px 0px rgba(21, 21, 41, 0.1);
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.x_title {
  height: 72px;
  padding: 0 16px;
  font-size: 18px;
  color: #495057;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-bottom: 1px solid #e6e6e6;
}
.x_screen {
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-bottom: 1px solid #e6e6e6;
}
.x_screen .left {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #3A3A3C;
}
.x_screen .left span{
  color: #ED6C00;
  margin: 0 5px;
}

.x_screen .right {
  display: flex;
}
.x_screen .right .detail{
  width: 160px;
  margin-left: 10px;
}
/deep/.el-select{
  width: 100%;
}

.x_thead {
  display: flex;
  align-items: center;
  height: 45px;
  background: #f3f6f9;
}
.x_thead .detail {
  font-size: 14px;
  color: #878a99;
  padding-left: 16px;
  display: flex;
  align-items: center;
  height: 100%;
}
.x_thead .detail .checkbox{
  margin-right: 16px;
  cursor: pointer;
}
.x_thead .line {
  width: 8px;
  height: 100%;
}
.f1 {
  flex: 1;
}
.f2 {
  flex: 2;
}
.f3 {
  flex: 3;
}
.f4 {
  flex: 4;
}
.x_tbody {
  flex: 1;
  overflow-y: auto;
}
.x_tbody .x_td {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
}
.x_tbody .x_td:last-of-type{
  border-bottom: 0;
}
.x_tbody .x_td .detail {
  font-size: 14px;
  color: #212529;
  display: flex;
  padding: 20px 0 20px 16px;
  align-items: center;
  min-height: 53px;
  text-align: justify;
}
.x_tbody .x_td .detail .checkbox{
  margin-right: 16px;
  cursor: pointer;
}
.x_tbody .x_td .detail .deBtn {
  padding: 0 8px;
  height: 22px;
  background-color: #fff1e5;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ED6C00;
  cursor: pointer;
}
.x_tbody .x_td .detail .deBtn img {
  margin-right: 6px;
}
.x_bottom {
  height: 74px;
  display: flex;
  align-items: center;
  padding: 0 21px 14px;
  border-top: 1px solid #e6e6e6;
  justify-content: space-between;
}
.x_bottom .left {
  font-size: 14px;
  color: #828593;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ED6C00;
}
/deep/.el-pagination.is-background .el-pager li:hover {
  color: #ED6C00!important;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #fff!important;
}
//通用滚动条样式
::-webkit-scrollbar {
  width: 8px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: rgba(237, 109, 0, 0.3);
  cursor: pointer;
}

.searchbtn{
    width: 100px;
    height: 40px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    background-color: #ED6C00;
    box-shadow: 0px 3px 5px 0px rgb(237 109 0 / 10%);
    color: #fff;
    border: 0;
    margin-left: 20px;
}

.info /deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #ED6C00;
    border-color: #ED6C00;
}
.info /deep/ .el-checkbox__inner:hover {
    border-color: #ED6C00;
}

.info /deep/ .el-checkbox__label{
  display: none;
}
.popdc{
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.4);
  z-index: 111;

  .popdcview{
    width: 300px;
    // height: 200px;
    padding: 20px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

    &>p{
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      color: #ED6C00;
    }

    &>div{
      display: flex;
      margin-top: 20px;
      justify-content: center;

      p{
        width: 100px;
        height: 40px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 16px;
        background-color: #ED6C00;
        box-shadow: 0px 3px 5px 0px rgb(237 109 0 / 10%);
        color: #fff;
        border: 0;
      }

      p:nth-child(2){
        margin-left: 20px;
      }
    }
  }
}

.x_info_right .x_thead .detail /deep/ .el-checkbox__inner{
  padding-left: 0!important;
}

</style>
