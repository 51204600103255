<template>
  <div class="contain">
    <div class="conLeft">
      <div class="top">
        <div class="detail">
          <div class="one">
            <div class="left">手机</div>
            <div class="middle">订单提醒通知</div>
            <div class="right">
              <el-switch
                v-model="isPhone"
                active-color="#ED6C00"
                inactive-color="#e2e6eb"
                @change="changePhone">
              </el-switch>
            </div>
          </div>
          <div class="two">{{portalCustomer.phone}}</div>
          <div class="three">
            <div class="left" @click="openPhonePop">修改手机号</div>
            <img src="../../assets/images/x_icon11.png" alt="">
          </div>
        </div>
        <div class="detail">
          <div class="one">
            <div class="left">邮箱</div>
            <div class="middle">订单提醒通知</div>
            <div class="right">
              <el-switch
                v-model="isEmail"
                active-color="#ED6C00"
                inactive-color="#e2e6eb"
                @change="changeEmail">
              </el-switch>
            </div>
          </div>
          <div class="two">{{portalCustomer.email}} </div>
          <div class="three">
            <div class="left" @click="openEmailPop">修改邮箱</div>
            <img src="../../assets/images/x_icon12.png" alt="">
          </div>
        </div>
        <div class="detail">
          <div class="one">
            <div class="left">账号</div>
          </div>
          <div class="two">{{portalCustomer.userName}}</div>
          <div class="three">
            <div class="left" @click="openUserPop">完善信息</div>
            <img src="../../assets/images/x_icon13.png" alt="">
          </div>
        </div>
        <div class="detail">
          <div class="one">
            <div class="left">密码</div>
          </div>
          <div class="two">************</div>
          <div class="three">
            <div class="left" @click="openPasswordPop">修改密码</div>
            <img src="../../assets/images/x_icon14.png" alt="">
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="one">
          <div class="left" @click="openAddPop()"><img src="../../assets/images/x_add.png" alt="">创建子用户</div>
          <div class="right">
            <el-select @change="getsublist" v-model="sublistform.status" placeholder="请选择" class="select">
              <el-option
                v-for="item in oneSelect"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-input placeholder="输入关键词搜索" v-model="sublistform.fullName" @keyup.enter.native="getsublist">
              <template slot="prepend"><img src="../../assets/images/x_search.png" alt=""></template>
            </el-input>
          </div>
        </div>
        <div class="x_thead">
          <div class="detail f2"><span>人员</span></div>
          <div class="detail f1"><span>部门</span></div>
          <div class="detail f1"><span>职位</span></div>
          <div class="detail f1"><span>手机号</span></div>
          <div class="detail f1"><span>用户权限</span></div>
          <div class="detail f1"><span>用户状态</span></div>
          <div class="detail f1"><span>操作</span></div>
          <div class="line"></div>
        </div>
        <div class="x_tbody">
          <div class="x_td" v-for="(item, index) in sublist" :key="index">
            <div class="detail f2"><span><img src="../../assets/images/x_icon15.png" alt="">{{item.fullName}}</span></div>
            <div class="detail f1"><span>{{item.deptName}}</span></div>
            <div class="detail f1"><span>{{item.position}}</span></div>
            <div class="detail f1"><span>{{item.phone}}</span></div>
            <div class="detail f1"><span class="edit" @click="openJurisdictionPop(item.id)">编辑</span></div>
            <div class="detail f1">
              <el-switch
                v-model="item.status" 
                :active-value="1"
                :inactive-value="0"
                @change="subchange(item.status,item.id)"
                active-color="#ED6C00"
                inactive-color="#e2e6eb">
              </el-switch>
            </div>
            <div class="detail f1"><span class="del" @click="subdelshow(item.id)">删除</span></div>
          </div>
        </div>
        <div class="x_bottom">
          <div class="left"></div>
          <div class="right">
            <el-pagination background layout="prev, pager, next , jumper" :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="conRight">
      <div class="one">
        <img src="../../assets/images/x_zhgl0.png" alt="" />
        <div class="name">{{portalCustomer.enterpriseName}}</div>
        <div class="address">{{portalCustomer.businessAddress}}</div>
      </div>
      <div class="two">
        <div class="detail">
          <div class="title">联系人</div>
          <div class="content">{{portalCustomer.contactsName}}</div>
        </div>
        <div class="detail">
          <div class="title">部门</div>
          <div class="content">{{portalCustomer.deptName}}</div>
        </div>
        <div class="detail">
          <div class="title">职位</div>
          <div class="content">{{portalCustomer.position}}</div>
        </div>
      </div>
      <div class="three" @click="openComPop">企业信息认证</div>
    </div>
    <!-- 企业信息认证 -->
    <transition name="el-fade-in-linear">
      <div class="allPop" v-show="isOpenComPop">
        <el-form :model="qyxxForm" :rules="qyxxRules" ref="qyxxForm" label-position="top" class="popInfo" :hide-required-asterisk="true" :inline-message="true">
          <div class="top">
            <span>企业信息修改</span>
            <img src="../../assets/images/x_close.png" alt="" @click="closeComPop"/>
          </div>
          <div class="middle">
            <div class="one">
              <el-form-item label="企业名称" prop="enterpriseName" class="detail">
                <el-input v-model="qyxxForm.enterpriseName" placeholder="请输入企业名称"></el-input>
              </el-form-item>
            </div>
            <div class="one">
              <el-form-item label="联系人" prop="userName" class="detail">
                <el-input v-model="qyxxForm.contactsName" placeholder="请输入联系人"></el-input>
              </el-form-item>
              <el-form-item label="部门" prop="deptName" class="detail">
                <el-input v-model="qyxxForm.deptName" placeholder="请输入部门"></el-input>
              </el-form-item>
              <el-form-item label="职位" prop="position" class="detail">
                <el-input v-model="qyxxForm.position" placeholder="请输入职位"></el-input>
              </el-form-item>
              <el-form-item label="联系电话" prop="phone" class="detail">
                <el-input v-model="qyxxForm.phone" placeholder="请输入联系电话"></el-input>
              </el-form-item>
            </div>
            <div class="one">
              <el-form-item label="公司性质" prop="companyNature" class="detail">
                <el-input v-model="qyxxForm.companyNature" placeholder="请输入公司性质"></el-input>
              </el-form-item>
              <el-form-item label="经营地址" prop="businessAddress" class="detail">
                <el-input v-model="qyxxForm.businessAddress" placeholder="请输入经营地址"></el-input>
              </el-form-item>
            </div>
            <div class="one">
              <el-form-item label="主营行业" prop="mainIndustry" class="detail">
                <el-input type="textarea" :rows="4" v-model="qyxxForm.mainIndustry" placeholder="请输入主营行业"></el-input>
              </el-form-item>
            </div>
            <el-form-item label="营业执照" prop="yyzzImg" class="three">
              <el-upload
                class="imgUp"
                :action=" upurl + 'customer/customerPage/CustomerUpload'"
                :headers="{Authorization:Authorization}"
                :show-file-list="false"
                :on-success="yyzzUpload">
                <img v-if="qyxxForm.businessLicense" :src="qyxxForm.businessLicense">
                <img v-else src="../../assets/images/x_uploadImg.png" alt="">
              </el-upload>
            </el-form-item>  
          </div>
          <el-form-item class="bottom">
            <div class="left"></div>
            <div class="right">
              <el-button class="cancel" @click="closeComPop">取消</el-button>
              <el-button class="submit" type="primary" @click="submitqyrz(qyxxForm)">提交认证</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </transition>
    <!-- 手机号修改 -->
    <transition name="el-fade-in-linear">
      <div class="allPop" v-show="isOpenPhonePop">
        <el-form :model="phoneForm" :rules="phoneRules" ref="phoneForm" label-position="top" class="popInfo" :hide-required-asterisk="true" :inline-message="true">
          <div class="top">
            <span>修改手机号</span>
            <img src="../../assets/images/x_close.png" alt="" @click="closePhonePop"/>
          </div>
          <div class="middle">
            <div class="four">当前绑定手机号：{{portalCustomer.phone}}<span @click="oldPhoneTime==120?sendOldCode():''">{{oldPhoneCode_text}}</span></div>
            <div class="one">
              <el-form-item label="短信验证码" prop="oldPhoneCode" class="detail">
                <el-input v-model="phoneForm.code" placeholder="请输入短信验证码"></el-input>
              </el-form-item>
            </div>
            <div class="one">
              <el-form-item label="新绑定手机" prop="newPhone" class="detail">
                <el-input v-model="phoneForm.newPhone" placeholder="请输入要新绑定的手机号">
                  <el-button slot="append" @click="newPhoneTime==120?sendNewCode():''">{{newPhoneCode_text}}</el-button>
                </el-input>
              </el-form-item>
            </div>
            <div class="one">
              <el-form-item label="短信验证码" prop="newPhoneCode" class="detail">
                <el-input v-model="phoneForm.newCode" placeholder="请输入短信验证码"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="bottom">
            <div class="left"></div>
            <div class="right">
              <el-button class="cancel" @click="closePhonePop">取消</el-button>
              <el-button class="submit" type="primary" @click="submitnewform('phoneForm')">提交修改</el-button>
            </div>
          </div>
        </el-form>
      </div>
    </transition>
    <!-- 邮箱修改 -->
    <transition name="el-fade-in-linear">
      <div class="allPop" v-show="isOpenEmailPop">
        <el-form :model="emailForm" :rules="emailRules" ref="emailForm" label-position="top" class="popInfo" :hide-required-asterisk="true" :inline-message="true">
          <div class="top">
            <span>修改邮箱</span>
            <img src="../../assets/images/x_close.png" alt="" @click="closeEmailPop"/>
          </div>
          <div class="middle">
            <div class="four">当前绑定邮箱：{{portalCustomer.email}}<span @click="oldEmailTime==120?sendOldCodeEmail():''">{{oldEmailCode_text}}</span></div>
            <div class="one">
              <el-form-item label="邮箱验证码" prop="email" class="detail">
                <el-input v-model="emailForm.code" placeholder="请输入邮箱验证码"></el-input>
              </el-form-item>
            </div>
            <div class="one">
              <el-form-item label="新绑定邮箱" prop="newEmail" class="detail">
                <el-input v-model="emailForm.newEmail" placeholder="请输入要新绑定的邮箱">
                  <el-button slot="append" @click="newEmailTime==120?sendNewCodeEmail():''">{{newEmailCode_text}}</el-button>
                </el-input>
              </el-form-item>
            </div>
            <div class="one">
              <el-form-item label="邮箱验证码" prop="newEmailCode" class="detail">
                <el-input v-model="emailForm.newCode" placeholder="请输入邮箱验证码"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="bottom">
            <div class="left"></div>
            <div class="right">
              <el-button class="cancel" @click="closeEmailPop">取消</el-button>
              <el-button class="submit" type="primary" @click="submitemailForm()">提交修改</el-button>
            </div>
          </div>
        </el-form>
      </div>
    </transition>

    <!-- 账号修改 -->
    <transition name="el-fade-in-linear">
      <div class="allPop" v-show="isOpenUserPop">
        <el-form :model="userForm" :rules="userRules" ref="userForm" label-position="top" class="popInfo" :hide-required-asterisk="true" :inline-message="true">
          <div class="top">
            <span>完善账号信息</span>
            <img src="../../assets/images/x_close.png" alt="" @click="closeUserPop"/>
          </div>
          <div class="middle">
            <el-form-item label="头像" prop="headPic" class="three">
              <el-upload
                class="headPic"
                :action=" upurl + 'customer/customerPage/CustomerUpload'"
                :headers="{Authorization:Authorization}"
                :show-file-list="false"
                :on-success="userUpload">
                <div class="cover"><span>修改头像</span></div>
                <img v-if="userForm.headPortrait" :src="userForm.headPortrait">
                <img v-else src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" alt="">
              </el-upload>
            </el-form-item>
            <div class="one">
              <el-form-item label="姓名" prop="name" class="detail">
                <el-input v-model="userForm.fullName" placeholder="请输入姓名"></el-input>
              </el-form-item>
            </div>
            <div class="one">
              <el-form-item label="部门" prop="department" class="detail">
                <el-input v-model="userForm.deptName" placeholder="请输入部门"></el-input>
              </el-form-item>
            </div>
            <div class="one">
              <el-form-item label="职位" prop="position" class="detail">
                <el-input v-model="userForm.position" placeholder="请输入职位"></el-input>
              </el-form-item>
            </div>
            <div class="one">
              <el-form-item label="绑定邮箱" prop="newEmail" class="detail">
                <el-input v-model="userForm.email" placeholder="请输入要新绑定的邮箱">
                  <el-button slot="append" @click="userEmailTime==120?senduserCodeEmail():''">{{userEmailCode_text}}</el-button>
                </el-input>
              </el-form-item>
            </div>
            <div class="one">
              <el-form-item label="邮箱验证码" class="detail">
                <el-input v-model="userForm.emailCode" placeholder="请输入邮箱验证码"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="bottom">
            <div class="left"></div>
            <div class="right">
              <el-button class="cancel" @click="closeUserPop">取消</el-button>
              <el-button class="submit" type="primary" @click="submitinfoform()">提交修改</el-button>
            </div>
          </div>
        </el-form>
      </div>
    </transition>

    <!-- 密码修改 -->
    <transition name="el-fade-in-linear">
      <div class="allPop" v-show="isOpenPasswordPop">
        <el-form :model="passwordForm" :rules="passwordRules" ref="passwordForm" label-position="top" class="popInfo" :hide-required-asterisk="true" :inline-message="true">
          <div class="top">
            <span>修改密码</span>
            <img src="../../assets/images/x_close.png" alt="" @click="closePasswordPop"/>
          </div>
          <div class="middle">
            <div class="four">当前绑定手机号：{{portalCustomer.phone}}<span @click="passwordTime==120?sendPasswordCode():''">{{passwordCode_text}}</span></div>
            <div class="one">
              <el-form-item label="短信验证码" prop="code" class="detail">
                <el-input v-model="passwordForm.code" placeholder="请输入短信验证码"></el-input>
              </el-form-item>
            </div>
            <div class="one">
              <el-form-item label="新密码" prop="newPwd" class="detail">
                <el-input type="password" v-model="passwordForm.newPwd" placeholder="请输入新密码"></el-input>
              </el-form-item>
            </div>
            <div class="one">
              <el-form-item label="确认新密码" prop="renewPwd" class="detail">
                <el-input type="password" v-model="passwordForm.renewPwd" placeholder="请确认新密码"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="bottom">
            <div class="left"></div>
            <div class="right">
              <el-button class="cancel" @click="closePasswordPop">取消</el-button>
              <el-button class="submit" type="primary" @click="submitpasswordForm()">提交修改</el-button>
            </div>
          </div>
        </el-form>
      </div>
    </transition>

    <!-- 创建子用户 -->
    <transition name="el-fade-in-linear">
      <div class="allPop" v-show="isOpenAddPop">
        <el-form :model="addForm" :rules="addRules" ref="addForm" label-position="top" class="popInfo" :hide-required-asterisk="true" :inline-message="true">
          <div class="top">
            <span>创建子用户</span>
            <img src="../../assets/images/x_close.png" alt="" @click="closeAddPop"/>
          </div>
          <div class="middle">
            <div class="one">
              <el-form-item label="姓名" prop="fullName" class="detail">
                <el-input v-model="addForm.fullName" placeholder="请输入账号"></el-input>
              </el-form-item>
              <el-form-item label="部门" prop="deptName" class="detail">
                <el-input v-model="addForm.deptName" placeholder="请输入部门"></el-input>
              </el-form-item>
              <el-form-item label="职位" prop="position" class="detail">
                <el-input v-model="addForm.position" placeholder="请输入职位"></el-input>
              </el-form-item>
            </div>
            <div class="one">
              <el-form-item label="绑定手机" prop="phone" class="detail">
                <el-input v-model="addForm.phone" placeholder="请输入子用户要绑定的手机号">
                  <el-button slot="append" @click="addTime==120?sendAddCode():''">{{addCode_text}}</el-button>
                </el-input>
              </el-form-item>
            </div>
            <div class="one">
              <el-form-item label="短信验证码" prop="code" class="detail">
                <el-input v-model="addForm.code" placeholder="请输入短信验证码"></el-input>
              </el-form-item>
            </div>
            <div class="one">
              <el-form-item label="密码" prop="password" class="detail">
                <el-input v-model="addForm.password" placeholder="请输入密码"></el-input>
              </el-form-item>
            </div>
            <div class="one">
              <el-form-item label="确认密码" prop="passwordAgain" class="detail">
                <el-input v-model="addForm.passwordAgain" placeholder="请确认密码"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="bottom">
            <div class="left"></div>
            <div class="right">
              <el-button class="cancel" @click="closeAddPop">取消</el-button>
              <el-button class="submit" type="primary" @click="submitaddform()">确认创建</el-button>
            </div>
          </div>
        </el-form>
      </div>
    </transition>

    <!-- 权限编辑 -->
    <transition name="el-fade-in-linear">
      <div class="allPop jurPop" v-show="isOpenJurisdictionPop">
        <el-form label-position="top" class="popInfo" :hide-required-asterisk="true" :inline-message="true">
          <!-- <el-checkbox-group v-model="checkList"> -->
          <div class="top">
            <span>权限编辑</span>
            <img src="../../assets/images/x_close.png" alt="" @click="closeJurisdictionPop"/>
          </div>
          
          <div class="middle">
            <el-checkbox-group v-model="checkList">
              <el-checkbox :label="item.id" v-for="(item,index) in jurList" :key="index">{{item.title}}</el-checkbox>
            </el-checkbox-group>
          </div>
          
          <div class="bottom">
            <div class="left"></div>
            <div class="right">
              <el-button class="cancel" @click="closeJurisdictionPop">取消</el-button>
              <el-button class="submit" type="primary" @click="submitsubqx()">提交修改</el-button>
            </div>
          </div>
          <!-- </el-checkbox-group> -->
        </el-form>
      </div>
    </transition>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      >
      <span>是否删除子账号？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="subdel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  // name: 'Index',
  components: {},
  data() {
    //修改密码
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.passwordForm.renewPwd !== '') {
          this.$refs.passwordForm.validateField('newPwd');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.passwordForm.newPwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    // 创建子用户
    var validatePass3 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.addForm.passwordAgain !== '') {
          this.$refs.addForm.validateField('passwordAgain');
        }
        callback();
      }
    };
    var validatePass4 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.addForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      delid:'',
      isPhone:true,//手机通知
      isEmail:false,//邮箱通知

      // 企业信息修改
      isOpenComPop: false, //是否打开企业信息修改弹窗
      qyxxForm:{
        enterpriseName:"",//企业名称
        userName:"",//联系人
        deptName:"",//部门
        position:"",//职位
        phone:"",//联系电话
        companyNature:"",//公司性质
        businessAddress:"",//经营地址
        mainIndustry:"",//主营行业
        businessLicense:"",//营业执照
        id: localStorage.getItem('customerid')
      },
      qyxxRules:{
        name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        contact: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
        ],
        department: [
          { required: true, message: '请输入部门', trigger: 'blur' },
        ],
        position: [
          { required: true, message: '请输入职位', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'number' },
        ],
        nature: [
          { required: true, message: '请输入公司性质', trigger: 'blur' },
        ],
        address: [
          { required: true, message: '请输入经营地址', trigger: 'blur' },
        ],
        industry: [
          { required: true, message: '请输入主营行业', trigger: 'blur' },
        ],
        yyzzImg: [
          { required: true, message: '请上传营业执照', trigger: 'blur' },
        ],
      },

      //手机号修改
      isOpenPhonePop:false,//是否打开手机号修改弹窗
      phoneForm:{
        code:"",//旧手机验证码
        newPhone:"",//新手机
        newCode:"",//新手机验证码
        phone:"",
        id:''
        // // customerNumber:localStorage.getItem('customerNumber')
      },
      phoneRules:{
        phone: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' },
        ],
        newPhone: [
          { required: true, message: '请输入要新绑定的手机号', trigger: 'blur' },
        ],
        newCode: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' },
        ],
      },
      oldPhoneCode_text:"发送验证码",//旧手机验证码文字
      oldPhoneTime:120,//旧手机倒计时
      newPhoneCode_text:"发送验证码",//新手机验证码文字
      newPhoneTime:120,//新手机倒计时

      //邮箱修改
      isOpenEmailPop:false,//是否打开邮箱修改弹窗
      emailForm:{
        email:"",//旧邮箱验证码
        id:'',
        code:'',
        newEmail:"",//新邮箱
        newCode:"",//新邮箱验证码
      },
      emailRules:{
        oldEmailCode: [
          { required: true, message: '请输入邮箱验证码', trigger: 'blur' },
        ],
        newEmail: [
          { required: true, message: '请输入要新绑定的邮箱', trigger: 'blur' },
        ],
        newEmailCode: [
          { required: true, message: '请输入邮箱验证码', trigger: 'blur' },
        ],
      },
      oldEmailCode_text:"发送验证码",//旧邮箱验证码文字
      oldEmailTime:120,//旧邮箱倒计时
      newEmailCode_text:"发送验证码",//新邮箱验证码文字
      newEmailTime:120,//新邮箱倒计时

      userEmailCode_text:"发送验证码",//完善邮箱验证码文字
      userEmailTime:120,//完善邮箱倒计时

      // 账号信息修改
      isOpenUserPop: false, //是否打开账号信息修改弹窗
      userForm:{
        headPortrait:"",//头像
        fullName:"",//姓名
        deptName:"",//部门
        position:"",//职位
        email:'',
        emailCode:'',
        id:"",
        phone:'',
      },
      userRules:{
        headPortrait: [
          { required: true, message: '请上传头像', trigger: 'blur' },
        ],
        fullName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        deptName: [
          { required: true, message: '请输入部门', trigger: 'blur' },
        ],
        position: [
          { required: true, message: '请输入职位', trigger: 'blur' },
        ],
      },
      // 密码修改
      isOpenPasswordPop: false, //是否打开账号信息修改弹窗
      passwordForm:{
        code:"",//短信验证码
        newPwd:"",//新密码
        renewPwd:"",//确认新密码
        phone:'',
        id:''
      },
      passwordRules:{
        code: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' },
        ],
        newPwd: [
          { validator: validatePass, trigger: 'blur' },
        ],
        renewPwd: [
          { validator: validatePass2, trigger: 'blur' },
        ],
      },
      passwordCode_text:"发送验证码",//手机验证码文字
      passwordTime:120,//手机倒计时

      oneSelect: [
        {
          id: 1,
          name: "已启用",
        },
        {
          id: 0,
          name: "未启用",
        },
      ],
      oneValue: "1",

      keywords:"",//搜索关键词


      //创建子用户
      isOpenAddPop:false,//是否打开创建子用户弹窗
      addForm:{
        fullName:"",//姓名
        deptName:"",//部门
        position:"",//职位
        phone:"",//手机
        code:"",//手机验证码
        password:"",//密码
        passwordAgain:"",//重复密码
        id:''
      },
      addRules:{
        fullName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        deptName: [
          { required: true, message: '请输入部门', trigger: 'blur' },
        ],
        position: [
          { required: true, message: '请输入职位', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机', trigger: 'blur' },
        ],
        code: [
          { required: true, message: '请输入手机验证码', trigger: 'blur' },
        ],
        password: [
          { validator: validatePass3, trigger: 'blur' },
        ],
        passwordAgain: [
          { validator: validatePass4, trigger: 'blur' },
        ],
      },
      addCode_text:"发送验证码",//手机验证码文字
      addTime:120,//手机倒计时


      isCheck:false,//用户状态

      // 权限编辑
      isOpenJurisdictionPop:false,
      qxid: '',
      checkList:[],
      jurList:[
        {
          id:1,
          name:"业务数据分析",
          isCheck:true,
        },
        {
          id:2,
          name:"合作产品目录查询",
          isCheck:true,
        },
        {
          id:3,
          name:"订单查询",
          isCheck:true,
        },
        {
          id:4,
          name:"备货库存查询",
          isCheck:true,
        },
        {
          id:5,
          name:"试样服务",
          isCheck:false,
        },
        {
          id:6,
          name:"账单服务",
          isCheck:true,
        },
        {
          id:7,
          name:"发票查询",
          isCheck:true,
        },
        {
          id:8,
          name:"供应链管理",
          isCheck:false,
        },
        {
          id:9,
          name:"售后服务",
          isCheck:true,
        },
        {
          id:10,
          name:"研发生态门户",
          isCheck:true,
        },
        {
          id:11,
          name:"下载中心",
          isCheck:false,
        },
        {
          id:12,
          name:"账号管理",
          isCheck:true,
        },
      ],
      upurl:'',
      portalCustomer:'',

      sublistform:{
          pageSize: 10,
          pageNum : 1,
          fullName:'',
          status:1,
          id:''
      },
      sublist:[],
      total:0,
    };
  },
  created() {
    this.upurl = this.apiUrl
    this.Authorization = 'Bearer ' + localStorage.getItem('token')
    let that = this
    this.$post(this.apiUrl + 'customer/customerPage/getCustomerInfo').then((response) => {
      this.portalCustomer = response.data.portalCustomer
      if(response.data.portalCustomer.enterpriseName != null){
        that.qyxxForm.enterpriseName = response.data.portalCustomer.enterpriseName
        that.qyxxForm.contactsName = response.data.portalCustomer.contactsName
        that.qyxxForm.deptName = response.data.portalCustomer.deptName
        that.qyxxForm.position = response.data.portalCustomer.position
        that.qyxxForm.phone = response.data.portalCustomer.phone
        that.qyxxForm.companyNature = response.data.portalCustomer.companyNature
        that.qyxxForm.businessAddress = response.data.portalCustomer.businessAddress
        that.qyxxForm.mainIndustry = response.data.portalCustomer.mainIndustry
        that.qyxxForm.businessLicense = response.data.portalCustomer.businessLicense
        that.userForm.headPortrait = response.data.portalCustomer.headPortrait
        that.userForm.fullName = response.data.portalCustomer.fullName
        that.userForm.deptName = response.data.portalCustomer.deptName
        that.userForm.position = response.data.portalCustomer.position
        that.userForm.email = response.data.portalCustomer.email
        that.userForm.phone = response.data.portalCustomer.phone
        that.emailForm.email = response.data.portalCustomer.email

        if(response.data.portalCustomer.emailRemind == '1'){
          this.isEmail = true
        }else{
          this.isEmail = false
        }

        if(response.data.portalCustomer.mobileRemind == '1'){
          this.isPhone = true
        }else{
          this.isPhone = false
        }

      }

      this.getsublist()
    })

    this.$get(this.apiUrl + 'home/productPage/overallSearch',{keywords:'boway',pageNum:1,pageSize:10}).then((response) => {

    })

    
  },
  methods: {
    

    getsublist(){
      this.sublistform.id = this.portalCustomer.id
      this.$post(this.apiUrl + 'customer/customerPage/getSubAccount',this.sublistform).then((response) => {
        this.sublist = response.data.records
        this.total  = response.data.total
      })
    },

    //子账号修改状态
    subchange(status,id){
      this.$post(this.apiUrl + 'customer/customerPage/updateSubAccount',{status,id}).then((response) => {
        if(response.code == 200){
          this.$message({
              message: '修改成功！',
              type: 'success'
          });
          this.getsublist()
        }else{
          this.$message({
              message: response.msg,
              type: 'error'
          });
        }
      })
    },


    //修改子账号权限
    // shoumenu(id){
    //   this.$post(this.apiUrl + 'customer/customerPage/getCustomerMenu',{id}).then((response) => {
    //     // if(response.code == 200){
          
    //     // }else{
          
    //     // }
    //   })
    // },

    //删除子账号
    subdelshow(id){
      this.dialogVisible = true
      this.delid = id
    },

    subdel(){
      this.$post(this.apiUrl + 'customer/customerPage/deleteSubAccount',{id:this.delid}).then((response) => {
        if(response.code == 200){
          this.$message({
              message: '删除成功！',
              type: 'success'
          });
          this.dialogVisible = false
          this.getsublist()
        }else{
          this.$message({
              message: response.msg,
              type: 'error'
          });
        }
        
      })
    },


    //选择手机通知
    changePhone(){
      let remind= this.isPhone ? '1' : '0'
      this.$post(this.apiUrl + 'customer/customerPage/updateRemind',{type:'1',remind:remind}).then((response) => {
        if(response.code == 200){
          this.$message({
              message: '操作成功！',
              type: 'success'
          });
        }else{
          this.$message({
              message: response.msg,
              type: 'error'
          });
        }
      })
      
      //customer/customerPage/updateRemind
      //this.isEmail = !this.isPhone;
    },
    //选择邮箱通知
    changeEmail(){
      let remind= this.isEmail ? '1' : '0'
      this.$post(this.apiUrl + 'customer/customerPage/updateRemind',{type:'2',remind:remind}).then((response) => {
        if(response.code == 200){
          this.$message({
              message: '操作成功！',
              type: 'success'
          });
        }else{
          this.$message({
              message: response.msg,
              type: 'error'
          });
        }
      })
      //this.isPhone = !this.isEmail;
    },

    //打开企业信息修改弹窗
    openComPop() {
      this.isOpenComPop = true;
    },
    //关闭企业信息修改弹窗
    closeComPop() {
      this.isOpenComPop = false;
    },
    //营业执照上传
    yyzzUpload(res, file) {
      // console.log(res)
      this.qyxxForm.businessLicense = res.url;
    },

    //信息修改
    submit(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    //企业信息修改
    submitqyrz(){
      this.$post(this.apiUrl + 'customer/customerPage/infoAuthentication',this.qyxxForm).then((response) => {
        if(response.code == 200){
          this.$message({
              message: '提交成功！',
              type: 'success'
          });
          this.isOpenComPop = false
        }else{
          this.$message({
              message: response.msg,
              type: 'error'
          });
        }
      })
    },

    //手机号修改
    submitnewform(){
      this.phoneForm.phone = this.portalCustomer.phone
      this.phoneForm.id = this.portalCustomer.id
      this.$post(this.apiUrl + 'customer/customerPage/updatePhone',this.phoneForm).then((response) => {
        if(response.code == 200){
          this.$message({
              message: '修改成功！',
              type: 'success'
          });
          this.$post(this.apiUrl + 'customer/customerPage/getCustomerInfo').then((response) => {
            this.portalCustomer = response.data.portalCustomer
          })
          this.isOpenPhonePop = false;
        }else{
          this.$message({
              message: response.msg,
              type: 'error'
          });
        }
      })
    },

    //信息修改
    submitinfoform(){
      // this.userForm.phone = this.portalCustomer.phone
      this.userForm.id = this.portalCustomer.id
      this.userForm.phone = this.portalCustomer.phone
      this.$post(this.apiUrl + 'customer/customerPage/perfectAccount',this.userForm).then((response) => {
        if(response.code == 200){
          this.$message({
              message: '提交成功！',
              type: 'success'
          });
          localStorage.setItem('token',response.data)
          this.$post(this.apiUrl + 'customer/customerPage/getCustomerInfo').then((response) => {
            this.portalCustomer = response.data.portalCustomer
            
          })
          this.isOpenUserPop = false;
        }else{
          this.$message({
              message: response.msg,
              type: 'error'
          });
        }
      })
    },

    //新增子账户
    submitaddform(){
      this.addForm.id = this.portalCustomer.id
      if(this.addForm.passwordAgain != this.addForm.password){
        this.$message({
            message: '密码不一致',
            type: 'error'
        });
        return
      }
      this.$post(this.apiUrl + 'customer/customerPage/createSubAccount',this.addForm).then((response) => {
        if(response.code == 200){
          this.$message({
              message: '提交成功！',
              type: 'success'
          });
          this.getsublist()
          this.isOpenAddPop = false
          
        }else{
          this.$message({
              message: response.msg,
              type: 'error'
          });
        }
      })
    },

    //打开手机号修改弹窗
    openPhonePop() {
      this.isOpenPhonePop = true;
    },
    //关闭手机号修改弹窗
    closePhonePop() {
      this.isOpenPhonePop = false;
    },
    //发送旧手机验证码
    sendOldCode(){
      // this.timeDownOld();
      let phone = this.portalCustomer.phone
      this.$post(this.apiUrl + 'customer/customerPage/getPhoneCode',{type:'updatePhone',phone:phone}).then(res=>{
            if(res.code == 200){
              this.timeDownOld();
            }else{
              this.$message.error(res.msg);
            }
      })
    },
    //旧手机倒计时
    timeDownOld(){
      let that = this;
      let oldPhoneTime = that.oldPhoneTime;
      if(oldPhoneTime > 1){
        oldPhoneTime--;
        that.oldPhoneCode_text = "已发送"+oldPhoneTime+"秒";
        that.oldPhoneTime = oldPhoneTime;
        setTimeout(function(){
          that.timeDownOld();
        },1000)
      }else{
        that.oldPhoneTime = 120;
        that.oldPhoneCode_text = "发送验证码";
      }
    },
    //发送新手机验证码
    sendNewCode(){
      let that = this;
      let phoneMobile = that.phoneForm.newPhone;
      //let phoneMobile = this.one_phone_input;
        // let code = this.data.code;
      var myreg = /^1[3456789]\d{9}$/;
      if (!myreg.test(phoneMobile)) {
        this.$message.error('手机号有误！');
        return false;
      }
      if(phoneMobile == ""){
        this.$message.error('请输入要新绑定的手机号');
      }else{
        this.$post(this.apiUrl + 'customer/customerPage/getPhoneCode',{type:'newPhone',phone:phoneMobile}).then(res=>{
            if(res.code == 200){
              this.timeDownNew();
            }else{
              this.$message.error(res.msg);
            }
        })
      }
    },
    //新手机倒计时
    timeDownNew(){
      let that = this;
      let newPhoneTime = that.newPhoneTime;
      if(newPhoneTime > 1){
        newPhoneTime--;
        that.newPhoneCode_text = "已发送"+newPhoneTime+"秒";
        that.newPhoneTime = newPhoneTime;
        setTimeout(function(){
          that.timeDownNew();
        },1000)
      }else{
        that.newPhoneTime = 120;
        that.newPhoneCode_text = "发送验证码";
      }
    },

    //打开邮箱修改弹窗
    openEmailPop() {
      this.isOpenEmailPop = true;
    },
    //关闭邮箱修改弹窗
    closeEmailPop() {
      this.isOpenEmailPop = false;
    },
    //发送旧邮箱验证码
    sendOldCodeEmail(){
      // this.timeDownOldEmail();
      this.$post(this.apiUrl + 'customer/customerPage/getEmailCode',{type:'updateEmail',email:this.portalCustomer.email}).then(res=>{
          if(res.code == 200){
            this.timeDownOldEmail();
          }else{
            this.$message.error(res.msg);
          }
      })
    },
    //旧邮箱倒计时
    timeDownOldEmail(){
      let that = this;
      let oldEmailTime = that.oldEmailTime;
      if(oldEmailTime > 1){
        oldEmailTime--;
        that.oldEmailCode_text = "已发送"+oldEmailTime+"秒";
        that.oldEmailTime = oldEmailTime;
        setTimeout(function(){
          that.timeDownOldEmail();
        },1000)
      }else{
        that.oldEmailTime = 120;
        that.oldEmailCode_text = "发送验证码";
      }
    },
    //发送新邮箱验证码
    sendNewCodeEmail(){
      let that = this;
      let newEmail = that.emailForm.newEmail;
      if(newEmail == ""){
        this.$message.error('请输入要新绑定的邮箱');
      }else{
        //this.timeDownNewEmail();
        this.$post(this.apiUrl + 'customer/customerPage/getEmailCode',{type:'newEmail',email:newEmail}).then(res=>{
          if(res.code == 200){
            this.timeDownNewEmail();
          }else{
            this.$message.error(res.msg);
          }
      })
      }
    },
    //新邮箱倒计时
    timeDownNewEmail(){
      let that = this;
      let newEmailTime = that.newEmailTime;
      if(newEmailTime > 1){
        newEmailTime--;
        that.newEmailCode_text = "已发送"+newEmailTime+"秒";
        that.newEmailTime = newEmailTime;
        setTimeout(function(){
          that.timeDownNewEmail();
        },1000)
      }else{
        that.newEmailTime = 120;
        that.newEmailCode_text = "发送验证码";
      }
    },

    submitemailForm(){
      this.emailForm.id = this.portalCustomer.id
      this.$post(this.apiUrl + 'customer/customerPage/updateEmail',this.emailForm).then((response) => {
        if(response.code == 200){
          this.$message({
              message: '提交成功！',
              type: 'success'
          });
          this.isOpenEmailPop = false
        }else{
          this.$message({
              message: response.msg,
              type: 'error'
          });
        }
      })
    },

    //发送完善邮箱验证码
    senduserCodeEmail(){
      let that = this;
      let newEmail = that.userForm.email;
      if(newEmail == ""){
        this.$message.error('请输入要新绑定的邮箱');
      }else{
        this.$post(this.apiUrl + 'customer/customerPage/getEmailCode',{type:'perfectEmail',email:newEmail}).then(res=>{
            if(res.code == 200){
              // this.timeDownOld();
              this.timeDownuserEmail();
            }else{
              this.$message.error(res.msg);
            }
        })
      }
    },
    //新邮箱倒计时
    timeDownuserEmail(){
      let that = this;
      let userEmailTime = that.userEmailTime;
      if(userEmailTime > 1){
        userEmailTime--;
        that.userEmailCode_text = "已发送"+userEmailTime+"秒";
        that.userEmailTime = userEmailTime;
        setTimeout(function(){
          that.timeDownuserEmail();
        },1000)
      }else{
        that.userEmailTime = 120;
        that.userEmailCode_text = "发送验证码";
      }
    },

    //打开账号信息修改弹窗
    openUserPop() {
      this.isOpenUserPop = true;
    },
    //关闭账号信息修改弹窗
    closeUserPop() {
      this.isOpenUserPop = false;
    },
    //头像上传
    userUpload(res, file) {
      this.userForm.headPortrait = res.url;
    },

    //打开密码修改弹窗
    openPasswordPop() {
      this.isOpenPasswordPop = true;
    },
    //关闭密码修改弹窗
    closePasswordPop() {
      this.isOpenPasswordPop = false;
    },
    //发送验证码
    sendPasswordCode(){
      this.$post(this.apiUrl + 'customer/customerPage/getPhoneCode',{type:'updatePWD',phone:this.portalCustomer.phone}).then(res=>{
            if(res.code == 200){
              this.timeDownPassword();
            }else{
              this.$message.error(res.msg);
            }
        })
    },
    submitpasswordForm(){
      this.passwordForm.phone = this.portalCustomer.phone
      this.passwordForm.id = this.portalCustomer.id
      this.$post(this.apiUrl + 'customer/customerPage/updatePwd',this.passwordForm).then((response) => {
        if(response.code == 200){
          this.$message({
              message: '修改成功！',
              type: 'success'
          });
          this.$post(this.apiUrl + 'customer/customerPage/getCustomerInfo').then((response) => {
            this.portalCustomer = response.data.portalCustomer
          })
          this.isOpenPasswordPop = false;
        }else{
          this.$message({
              message: response.msg,
              type: 'error'
          });
        }
      })
    },
    //旧邮箱倒计时
    timeDownPassword(){
      let that = this;
      let passwordTime = that.passwordTime;
      if(passwordTime > 1){
        passwordTime--;
        that.passwordCode_text = "已发送"+passwordTime+"秒";
        that.passwordTime = passwordTime;
        setTimeout(function(){
          that.timeDownPassword();
        },1000)
      }else{
        that.passwordTime = 120;
        that.passwordCode_text = "发送验证码";
      }
    },
    
    //打开创建子用户弹窗
    openAddPop() {
      this.isOpenAddPop = true;
    },
    //关闭创建子用户弹窗
    closeAddPop() {
      this.isOpenAddPop = false;
    },
    //发送手机验证码
    sendAddCode(){
      let that = this;
      let phone = that.addForm.phone;
      if(phone == ""){
        this.$message.error('请输入子用户要绑定的手机号');
      }else{
        this.$post(this.apiUrl + 'customer/customerPage/getPhoneCode',{type:'createSub',phone:phone}).then(res=>{
            if(res.code == 200){
              this.timeDownAdd();
            }else{
              this.$message.error(res.msg);
            }
        })
      }
    },
    //手机倒计时
    timeDownAdd(){
      let that = this;
      let addTime = that.addTime;
      if(addTime > 1){
        addTime--;
        that.addCode_text = "已发送"+addTime+"秒";
        that.addTime = addTime;
        setTimeout(function(){
          that.timeDownAdd();
        },1000)
      }else{
        that.addTime = 120;
        that.addCode_text = "发送验证码";
      }
    },

    //权限编辑
    //打开权限编辑弹窗
    openJurisdictionPop(id) {
      this.isOpenJurisdictionPop = true;
      this.qxid = id
      this.$post(this.apiUrl + 'customer/customerPage/getCustomerMenu',{id}).then((response) => {
        this.jurList = response.data.menus
        this.checkList = response.data.checkedKeys
      })
    },
    //关闭权限编辑弹窗
    closeJurisdictionPop() {
      this.isOpenJurisdictionPop = false;
    },

    submitsubqx(){
      this.$post(this.apiUrl + 'customer/customerPage/updateCustomerMenu',{id:this.qxid,menuids:this.checkList}).then((response) => {
        // this.jurList = response.data.menus
        // this.checkList = response.data.checkedKeys
        if(response.code == 200){
          this.$message({
              message: '提交成功！',
              type: 'success'
          });
          this.isOpenJurisdictionPop = false
        }else{
          this.$message({
              message: response.msg,
              type: 'error'
          });
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.contain {
  display: flex;
  background: #f2f2f7;
  min-height: calc(100vh - 110px);
  // height: 100%;
}
.contain .conLeft {
  flex: 1;
  width: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px 30px 50px;
}
.contain .conLeft .top{
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}
.contain .conLeft .top .detail{
  width: 24%;
	height: 145px;
	background-color: #ffffff;
	box-shadow: 0px 3px 7px 0px	rgba(21, 21, 41, 0.1);
	border-radius: 4px;
  padding: 17px 15px;
  padding-top: 12px;
}
.contain .conLeft .top .detail .one{
  display: flex;
  align-items: center;
  height: 36px;
}
.contain .conLeft .top .detail .one .left{
  font-size: 14px;
  color: #828593;
}
.contain .conLeft .top .detail .one .middle{
  font-size: 12px;
  color: #828593;
  text-align: right;
  flex: 1;
  margin-right: 7px;
}
.contain .conLeft .top .detail .one .right{
  display: flex;
  align-items: center;
}
.contain .conLeft .top .detail .two{
  flex: 1;
  font-size: 18px;
  color: #495057;
  font-weight: 800;
  margin-top: 10px;
  height: 27px;
}
.contain .conLeft .top .detail .three{
  display: flex;
  justify-content: space-between;
}
.contain .conLeft .top .detail .three .left{
  font-size: 12px;
  color: #ED6C00;
  text-decoration: underline;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
}
.contain .conLeft .bottom{
  background: #fff;
  margin-top: 20px;
  box-shadow: 0px 3px 7px 0px	rgba(21, 21, 41, 0.1);
	border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.contain .conLeft .bottom .one{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 0 17px;
  border-bottom: 1px solid #eee;
}
.contain .conLeft .bottom .one .left{
  width: 127px;
	height: 38px;
	background-color: #ED6C00;
	box-shadow: 0px 3px 5px 0px	rgba(237, 109, 0, 0.1);
	border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
}
.contain .conLeft .bottom .one .left img{
  margin-right: 5px;
}
.contain .conLeft .bottom .one .right{
  display: flex;
}
/deep/.el-select{
  margin-right: 10px;
}
/deep/.el-input-group__prepend{
  background: none;
  padding: 0 13px;
  cursor: pointer;
}
.x_thead {
  display: flex;
  align-items: center;
  height: 45px;
  background: #f3f6f9;
}
.x_thead .detail {
  font-size: 14px;
  color: #878a99;
  padding-left: 26px;
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: bold;
}
.x_thead .line {
  width: 8px;
  height: 100%;
}
.f1 {
  flex: 1;
}
.f2 {
  flex: 2;
}
.f3 {
  flex: 3;
}
.f4 {
  flex: 4;
}
.x_tbody {
  flex: 1;
  overflow-y: auto;
}
.x_tbody .x_td {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
}
.x_tbody .x_td:last-of-type{
  border-bottom: 0;
}
.x_tbody .x_td .detail {
  font-size: 14px;
  color: #212529;
  display: flex;
  padding: 20px 0 20px 26px;
  align-items: center;
  min-height: 53px;
  text-align: justify;
}
.x_tbody .x_td .detail img{
  margin-right: 10px;
}
.x_tbody .x_td .detail .edit{
  color: #ED6C00;
  cursor: pointer;
}
.x_tbody .x_td .detail .del{
  cursor: pointer;
}
.x_bottom {
  height: 74px;
  display: flex;
  align-items: center;
  padding: 0 21px 14px;
  border-top: 1px solid #e6e6e6;
  justify-content: space-between;
}
.x_bottom .left {
  font-size: 14px;
  color: #828593;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ED6C00;
}
/deep/.el-pagination.is-background .el-pager li:hover {
  color: #ED6C00!important;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #fff!important;
}

.contain .conRight {
  width: 300px;
  background: #fff;
  padding: 0 20px;
}
.contain .conRight .one {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px dashed #eee;
}
.contain .conRight .one .name {
  font-size: 18px;
  color: #3A3A3C;
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
}
.contain .conRight .one .address {
  font-size: 14px;
  color: #999;
  list-style: 24px;
  text-align: center;
  padding: 0 46px;
  margin-top: 10px;
}
.contain .conRight .two {
  border-bottom: 1px dashed #eee;
}
.contain .conRight .two .detail {
  padding: 0 10px;
  overflow: hidden;
}
.contain .conRight .two .detail .title {
  font-size: 12px;
  color: #9ea1b1;
  margin-top: 28px;
}
.contain .conRight .two .detail .content {
  font-size: 16px;
  color: #3A3A3C;
  font-weight: bold;
}
.contain .conRight .three {
  width: 260px;
  height: 50px;
  background-color: #ED6C00;
  border-radius: 4px;
  margin: 34px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}


// 弹窗信息
.allPop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 120;
}
.popInfo {
  width: 1100px;
  max-height: 640px;
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.popInfo .top {
  height: 56px;
  background-color: #fae5d9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  flex-shrink: 0;
}
.popInfo .top span {
  font-size: 14px;
  color: #495057;
  font-weight: bold;
}
.popInfo .top img {
  cursor: pointer;
}
.popInfo .middle{
  padding: 5px 20px 10px;
  flex: 1;
  overflow-y: auto;
}
.popInfo .middle .one{
  display: flex;
}
/deep/.el-form-item{
  margin-bottom: 0;
}
/deep/.el-form--label-top .el-form-item__label{
  padding: 0;
  margin: 15px 0 10px;
  font-size: 14px;
  color: #495057;
  font-weight: bold;
  line-height: 1.5;
}
/deep/.el-form-item__content{
  line-height: normal;
}

.popInfo .middle .one .detail{
  flex: 1;
  margin-right: 10px;
}
.popInfo .middle .one .detail:last-of-type{
  margin-right: 0;
}

.popInfo .middle .three .imgUp{
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popInfo .middle .three .imgUp img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.popInfo .middle .three .headPic{
  width: 100px;
  height: 100px;
  position: relative;
}
.popInfo .middle .three .headPic .cover{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 30px;
  background: rgba(0,0,0,.5);
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  color: #fff;
}
.popInfo .middle .three .headPic img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
/deep/.el-upload{
  width: 100px;
  height: 100px;
}

.popInfo .middle .four{
  margin-top: 20px;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
}
.popInfo .middle .four span{
  color: #ED6C00;
  margin-left: 18px;
  cursor: pointer;
}


.popInfo .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px 35px;
}
.popInfo .bottom .left {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #3A3A3C;
}
.popInfo .bottom .left span {
  color: #ED6C00;
}
.popInfo .bottom .left img {
  margin: 0 5px 0 15px;
}
.popInfo .bottom .right {
  display: flex;
  align-items: center;
}
.popInfo .bottom .right .cancel {
  width: 60px;
  height: 36px;
  background-color: #f3f6f9;
  box-shadow: 0px 3px 5px 0px rgba(237, 109, 0, 0.1);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #353637;
  cursor: pointer;
  border: 0;
}
.popInfo .bottom .right .submit {
  width: 100px;
  height: 36px;
  background-color: #ED6C00;
  box-shadow: 0px 3px 5px 0px rgba(237, 109, 0, 0.1);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
  border: 0;
}
//通用滚动条样式
::-webkit-scrollbar {
  width: 8px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: rgba(237, 109, 0, 0.3);
}


// 权限编辑
.jurPop .popInfo{
  width: 400px;
}
/deep/.el-checkbox{
  width: 100%;
  margin-right: 0;
  margin-top: 25px;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner{
  background-color: #ED6C00;
  border-color: #ED6C00;
}
/deep/.el-checkbox__input.is-checked+.el-checkbox__label{
  color: #495057;
}
/deep/.el-checkbox__input.is-focus .el-checkbox__inner{
  border-color: #ED6C00;
}

// 高度为整屏
// .contain .conLeft .bottom{
//   flex: 1;
//   height: 10px;
// }
</style>
